//angular
import { Injectable } from '@angular/core';

//amplify
import { API } from 'aws-amplify';
import { FileUploadHelper } from 'src/app/components/file-uploader/file-uploader-helper';
import { CurrentFileUploadStatus } from 'src/app/interfaces/file-uploader-process-status';
import { ProcessStatusRow } from 'src/app/interfaces/process-status-row';

//services
import { CognitoApiService } from '../cognito-api/cognito-api.service';
import { DateGeneratorService } from '../date-generator/date-generator.service';

@Injectable({
  providedIn: 'root'
})
export class EtlLoggingService {

  uuid!: string;

  logResultsFound: number = 0;

  showRefreshIcon: boolean = false;

  constructor(
    private cognitoApiService: CognitoApiService
    , private dateGeneratorService: DateGeneratorService) {
    this.RetrieveUuid();
  }

  async RetrieveUuid() {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/uuid';
    const myInit = {};

    var response = await API.get(apiName, path, myInit)
    this.uuid = response['body'][0][0];
  }

  async PushMessageToLoggingTable(process: string, procedureName: string, statusMessage: string, rowCount: string | null, ErrorNumber: string | null,
    ErrorState: string | null, ErrorSeverity: string | null, ErrorProcedure: string | null, ErrorLine: string | null, ErrorMessage: string | null) {

    const apiName = 'fdaguiapi';
    const path = '/snowflake/logtable';
    const myInit = {
      body: {
        process: process,
        process_id: this.uuid,
        procedure_name: procedureName,
        status_message: statusMessage,
        row_count: rowCount,
        error_number: ErrorNumber,
        error_state: ErrorState,
        error_severity: ErrorSeverity,
        error_procedure: ErrorProcedure,
        error_line: ErrorLine,
        error_message: ErrorMessage,
        triggered_by: await this.cognitoApiService.retrieveUserEmail()
      }
    };
    return API.post(apiName, path, myInit)
  }

  async WaitForEtlLogRow(object: string) {
    /*
      This process will set an interval to query the etl logging table
      for progress. If the complete log is found before the timeout,
      it will return true, else it will return false.
    */
    console.log('Waiting on snowflake result')
    var date = this.dateGeneratorService.GetDateString();
    var interval = 5000;
    var timeout = 120
    console.log(`date - ${date}`);
    console.log(`object - ${object}`);
    console.log('Starting interval');
    for (var i = 0; i < timeout; i++) {
      await new Promise(r => setTimeout(r, interval));
      var response = await this.CheckOnSnowflakeProgress(date, object);
      console.log(response);
      if (response) {
        i = timeout + 1;
        return true;
      }
    }
    return false;
  }

  async CheckOnSnowflakeProgress(date: string, object: string) {
    /*
      This process will query the etl logging table based on an object and a date.
      If it finds a result with End of Load from Silver to Snowflake then it will
      return true, else it will return false.
    */
    this.showRefreshIcon = true;
    console.log('Checking for snowflake progress');
    const apiName = 'fdaguiapi';
    const path = '/snowflake/logtable';
    const myInit = {
      queryStringParameters: {
        date: date,
        procedureName: object,
      },
    };
    var response = await API.get(apiName, path, myInit)
    this.showRefreshIcon = false;
    if (response['body'].length > 0) {
      console.log('results found');
      if (this.logResultsFound < response['body'].length) {
        console.log(this.logResultsFound);
        console.log(response['body'].length)
        for (var i = 0; i < response['body'].length; i++) {
          var processingMessage = response['body'][i][1];

          console.log(processingMessage);
          if (processingMessage.includes('End of Load from Silver to Snowflake')) {
            console.log('found complete');
            return true;
          }
        }
        this.logResultsFound = response['body'].length;
      }
      console.log(response);
    }
    else {
      console.log('results not found');
      console.log(response);
    }
    return false;
  }

  async pollForFillUploadCompleteInSnowflake(processStatusRow: ProcessStatusRow, uploadStatus: CurrentFileUploadStatus) {
    let getLatestLogsForProcessId = async () => {
      const apiName = 'fdaguiapi';
      const path = '/snowflake/processidlogs';
      const myInit = {
        queryStringParameters: {
          processId: processStatusRow.processId
        }
      };
      var response = await API.get(apiName, path, myInit)
      return FileUploadHelper.formatProcessStatusRows(eval(response['body']));
    }

    const maxPollingCount = 20;
    let pollingCount = 0;
    while (pollingCount < maxPollingCount) {
      let logRows = await getLatestLogsForProcessId();
      if (logRows.length == 0) {
        uploadStatus.inProcess = false;
        uploadStatus.inSnowflake = false;
        uploadStatus.statusMessage = 'No logs found for process'
      }
      else if (logRows.length == 1 && logRows[0].statusMessage.trim().toLowerCase() == 'in process') {
        pollingCount += 1;
        await this.delay(30000);
      }
      else if (logRows.length == 2 && logRows.filter(l => l.statusMessage.trim().toLowerCase() == 'success').length == 1) {
        uploadStatus.inProcess = false;
        uploadStatus.inSnowflake = true;
        uploadStatus.statusMessage = undefined;
        break;
      }
      else {
        uploadStatus.inProcess = false;
        uploadStatus.inSnowflake = false;
        uploadStatus.statusMessage = logRows.filter(l => l.statusMessage.trim().toLowerCase() == 'failed')[0]?.errorMessage ?? 'An unknown error occurred while uploading file. Please retry file upload.';
        break;
      }
    }

    if (uploadStatus.inProcess == true) {
      uploadStatus.inProcess = false;
      uploadStatus.inSnowflake = false;
      uploadStatus.statusMessage = `Unable to get file upload confirmation from snowflake. Please check the Process Status page for successful upload confirmation for ProcessId.`;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
