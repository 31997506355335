import { Injectable } from '@angular/core';

//Amplify
import { Storage } from 'aws-amplify';
import { CognitoApiService } from '../cognito-api/cognito-api.service';

@Injectable({
  providedIn: 'root'
})
export class BlacklineStorageHandlerService {

  constructor(private cognitoApiService: CognitoApiService) { }

  async uploadBlacklineFileToS3(fileName: string, fileData: string) {
    var fileKey = null;
    var date = this.GetDateString();
    var user = null;
    var user = await this.cognitoApiService.retriveUser();
    var username = user.username;

    if (fileName == 'fxRates') {
      fileKey = 'blackline/fxRates/' + username + '/' + date + '/BLOutput-FXRATES' + '.csv';
    }
    else if (fileName == 'bankBalance') {
      fileKey = 'blackline/bankBalance/' + username + '/' + date + '/BLOutput-BANKBAL' + '.csv';
    }
    else if (fileName == 'slBal') {
      fileKey = 'blackline/slBal/' + username + '/' + date + '/BLOutput-SLBAL' + '.csv';
    }
    else if (fileName == 'glBal') {
      fileKey = 'blackline/glBal/' + username + '/' + date + '/BLOutput-GLBAL' + '.csv';
    }
    
    if (fileKey != null) {
      Storage.put(fileKey, fileData);
    }
    else {
      console.log('no file selected to put in storage');
    }
    return fileKey
  }

  GetDateString() {
    let todayDate = new Date();
    var year = todayDate.getFullYear().toString();
    var month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
    var day = todayDate.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day
  }

  GetDateTimeString() {
    let todayDate = new Date();
    var year = todayDate.getFullYear().toString();
    var month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
    var day = todayDate.getDate().toString().padStart(2, '0');
    var hour = (todayDate.getHours() - 2).toString().padStart(2, '0');
    var minute = todayDate.getMinutes().toString().padStart(2, '0');
    var second = todayDate.getSeconds().toString().padStart(2, '0');
    var millisecond = todayDate.getMilliseconds().toString();
    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + millisecond;
  }

}
