//angular
import { Component, OnInit } from '@angular/core';

//amplify
import { API } from 'aws-amplify';

//services
import { MappingRulesService } from 'src/app/services/mapping-rules/mapping-rules.service';

@Component({
  selector: 'app-mapping-rules-load',
  templateUrl: './mapping-rules-load.component.html',
  styleUrls: ['./mapping-rules-load.component.scss']
})
export class MappingRulesLoadComponent implements OnInit {

  mappingRuleToReprocess: string = '- Select- ';

  loadingMappingrule: boolean = false;
  mappingRuleComplete: boolean = false;
  errorDuringMappingRuleLoad: boolean = false;

  mappingRulesList = [
    { name: '- Select- ' },
    { name: 'Elimination Rollup' },
    { name: 'GMC GL Account Grouping' },
    { name: 'GMC Primary Attribute Assignment' },
    { name: 'Natural Account Additional Attributes' },
    { name: 'All' }
  ]

  constructor(public mappingRulesService: MappingRulesService) { }

  ngOnInit(): void {
  }

  async RefreshMappingRule() {

    this.ResetView();

    this.loadingMappingrule = true;
    console.log('refreshing mapping rule - ');
    console.log(this.mappingRuleToReprocess['name']);

    var func = '';
    if (this.mappingRuleToReprocess['name'] == 'Elimination Rollup') { func = 'eliminationRollup'; }
    else if (this.mappingRuleToReprocess['name'] == 'GMC GL Account Grouping') { func = 'gmcGLAccountGrouping'; }
    else if (this.mappingRuleToReprocess['name'] == 'GMC Primary Attribute Assignment') { func = 'gmcPrimaryAttributeAssignment'; }
    else if (this.mappingRuleToReprocess['name'] == 'Natural Account Additional Attributes') { func = 'naturalAccount'; }
    else if (this.mappingRuleToReprocess['name'] == 'All') { func = 'all'; }

    const apiName = 'fdaguiapi';
    const path = '/mappingrules';
    const myInit = {
      body: {
        mapping_rule: func
      }
    };
    const response = await API.post(apiName, path, myInit)
    console.log(response);
    console.log('waiting for snowflake response');

    var date = this.GetDateString();

    if (func != 'all') {
      const response = await this.mappingRulesService.WaitForSnowflakeResult(func, date);
      this.loadingMappingrule = false;
      if (response) {
        this.mappingRuleComplete = true;
      }
      else {
        this.errorDuringMappingRuleLoad = true;
      }
    }
    else {
      const response1 = await this.mappingRulesService.WaitForSnowflakeResult('naturalAccount', date);
      const response2 = await this.mappingRulesService.WaitForSnowflakeResult('eliminationRollup', date);
      const response3 = await this.mappingRulesService.WaitForSnowflakeResult('gmcGLAccountGrouping', date);
      const response4 = await this.mappingRulesService.WaitForSnowflakeResult('gmcPrimaryAttributeAssignment', date);
      this.loadingMappingrule = false;
      if (response1 && response2 && response3 && response4) {
        this.mappingRuleComplete = true;
      }
      else {
        this.errorDuringMappingRuleLoad = true;
      }
    }
  }



  GetDateString() {
    let todayDate = new Date();
    var year = todayDate.getFullYear().toString();
    var month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
    var day = todayDate.getDate().toString().padStart(2, '0');
    var hour = (todayDate.getHours() - 2).toString().padStart(2, '0');
    var minute = todayDate.getMinutes().toString().padStart(2, '0');
    var second = todayDate.getSeconds().toString().padStart(2, '0');
    var millisecond = todayDate.getMilliseconds().toString();
    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + millisecond;
  }

  ResetView() {
    this.loadingMappingrule = false;
    this.mappingRuleComplete = false;
    this.errorDuringMappingRuleLoad = false;
  }

}
