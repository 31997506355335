import { Injectable } from '@angular/core';

//Amplify
import { API } from 'aws-amplify';

//Services
import { StorageHandlerService } from '../storage-handler/storage-handler.service';

//Interfaces
import { FileUploaderValidationIssues } from 'src/app/interfaces/file-uploader-validation-issues';
import { EtlLoggingService } from '../etl-logging/etl-logging.service';

@Injectable({
  providedIn: 'root'
})
export class FileManagementService {

  storageHandlerService: StorageHandlerService;

  finishedSnowflakeProcessing: boolean = false;
  intervalVariable: any;

  public process: string = '';
  public fileName: string = '';

  constructor(storageHandlerService: StorageHandlerService, private etlLoggingService: EtlLoggingService) {
    this.storageHandlerService = storageHandlerService;
  }

  async ValidateBlacklineFile(file: File) {
    this.etlLoggingService.PushMessageToLoggingTable(
      this.process,
      `ValidateFile`,
      'Uploading file for validation',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )

    this.storageHandlerService.UploadFileToGuiS3(this.fileName, file);

    await this.etlLoggingService.PushMessageToLoggingTable(
      this.process,
      'ValidateFile',
      'File successfully uploaded to S3',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.etlLoggingService.PushMessageToLoggingTable(
      this.process,
      'ValidateFile',
      'Verifying file columns and rows',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    const apiName = 'fdaguiapi';
    const path = '/files/validate';
    const myInit = {
      body: {
        file: this.fileName,
        file_key: file.name
      }
    };
    return await API.post(apiName, path, myInit)
  }

  async UploadFileDataToDatalake() {
    var success = false;
    this.etlLoggingService.PushMessageToLoggingTable(
      this.process,
      'UploadFileDataToDatalake',
      'Gzipping file(s) and uploading to raw datalake bucket',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    const apiName = 'fdaguiapi';
    const path = '/files/movetodatalake';
    const myInit = {
      body: {
        file: this.fileName
      }
    };

    let response = await API.post(apiName, path, myInit)
    console.log(response);

    if (response['statusCode'] == 200) {
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'UploadFileDataToDatalake',
        'File successfully uploaded to datalake raw bucket, archiving file in web app S3',
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      await this.storageHandlerService.ArchiveFileInStorage(this.fileName);
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'UploadFileDataToDatalake',
        'File successfully archived in web app file storage',
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      success = true;
    }
    else {
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'UploadFileDataToDatalake',
        'Issue while uploading file to the datalake, please retry',
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'UploadFileDataToDatalake',
        'removing file from web app storage',
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      await this.storageHandlerService.RemoveFileFromStorage(this.fileName);
    }
    return success
  }

  async ValidateAndReturnResultsOfFile(file: File) {
    this.etlLoggingService.PushMessageToLoggingTable(
      this.process,
      'ValidateAndReturnResultsOfFile',
      'Veryfying file uploaded by user',
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    var resultData: FileUploaderValidationIssues[];
    let validation = await this.ValidateBlacklineFile(file);
    console.log(validation);

    if (validation['statusCode'] == 500) {
      return null;
    }
    var row_count: string | null = null;
    if (validation["row_count"] != null) {
      row_count = validation["row_count"]
    }
    console.log(row_count);
    console.log(validation);

    if (validation["issues"] != null && (<Array<any>>validation["issues"]).length > 0) {
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'ValidateAndReturnResultsOfFile',
        'Issues found while validating file',
        row_count,
        null,
        null,
        null,
        null,
        null,
        null
      );
      resultData = this.FormatValidationIssues(validation['issues']);
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'ValidateAndReturnResultsOfFile',
        'Removing file from Web App Storage',
        row_count,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.storageHandlerService.RemoveFileFromStorage(this.fileName);
      return resultData;
    }

    else {
      this.etlLoggingService.PushMessageToLoggingTable(
        this.process,
        'ValidateAndReturnResultsOfFile',
        'Successfully validated file. No issues found',
        row_count,
        null,
        null,
        null,
        null,
        null,
        null
      );
      return [];
    }
  }

  FormatValidationIssues(data: []): FileUploaderValidationIssues[] {
    console.log(data);
    var validationIssues = [];
    for (var i = 0; i < data.length; i++) {
      var validationIssue: FileUploaderValidationIssues = {
        Row: data[i]["Row"],
        ColumnName: data[i]["ColumnName"],
        ColumnValue: data[i]["ColumnValue"],
        ExpectedDataType: data[i]["ExpectedDataType"],
        ErrorDescription: data[i]["ErrorDescription"]
      }
      validationIssues.push(validationIssue);
    }

    return validationIssues;
  }

}
