import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Amplify, graphqlOperation } from 'aws-amplify';
import aws_exports from './aws-exports';

aws_exports['oauth'] = {
  domain: 'fdaguidevauth-dev.auth.us-east-1.amazoncognito.com',
  scope: ['openid', 'email'],
  redirectSignIn: 'http://localhost:4200/',
  redirectSignOut: 'http://localhost:4200/',
  responseType: 'code'
}

if (environment.production) {
  enableProdMode();
  aws_exports['oauth'].domain = 'fdaguidevauth-prod.auth.us-east-1.amazoncognito.com';
  aws_exports['oauth'].redirectSignIn = 'https://prod.fda.kmt.services/';
  aws_exports['oauth'].redirectSignOut = 'https://prod.fda.kmt.services/';
}

if (environment.development) {
  aws_exports['oauth'].domain = 'fdaguidevauth-dev.auth.us-east-1.amazoncognito.com';
  aws_exports['oauth'].redirectSignIn = 'https://dev.fda.kmt.services/';
  aws_exports['oauth'].redirectSignOut = 'https://dev.fda.kmt.services/';
}

if (environment.test) {
  aws_exports['oauth'].domain = 'fdaguidevauth-test.auth.us-east-1.amazoncognito.com';
  aws_exports['oauth'].redirectSignIn = 'https://test.fda.kmt.services/';
  aws_exports['oauth'].redirectSignOut = 'https://test.fda.kmt.services/';
}

if (environment.local) {
  aws_exports['oauth'].domain = 'fdaguidevauth-dev.auth.us-east-1.amazoncognito.com';
  aws_exports['oauth'].redirectSignIn = 'http://localhost:4200/';
  aws_exports['oauth'].redirectSignOut = 'http://localhost:4200/';
  console.log('local');
}

if (environment.testonlocal) {
  aws_exports['oauth'].domain = 'fdaguidevauth-test.auth.us-east-1.amazoncognito.com';
  aws_exports['oauth'].redirectSignIn = 'http://localhost:4200/';
  aws_exports['oauth'].redirectSignOut = 'http://localhost:4200/';
  console.log('testonlocal');
}



Amplify.configure(aws_exports);
// ==========================
// ag-Grid enterprise license
// ==========================
import { LicenseManager } from 'ag-grid-enterprise';
// LicenseManager.setLicenseKey('Evaluation_License_Valid_Until__6_October_2018__MTUzODc4MDQwMDAwMA==fbfd3132d1260b787461480cd37837c0');
LicenseManager.setLicenseKey('CompanyName=Koch Minerals and Trading,LicensedGroup=Jonathan Lewin,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-027115,ExpiryDate=7_April_2023_[v2]_MTY4MDgyMjAwMDAwMA==0aaab551c1b88cd3463eaaf97abb81d6');


Amplify.configure(aws_exports);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));