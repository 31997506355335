import { Component, OnInit } from '@angular/core';

//PrimeNG
import { ConfirmationService } from 'primeng/api';
import { DropdownOptionInterface } from 'src/app/interfaces/dropdown-option-interface';
import { DropdownOptionInterfaceKeyValue } from 'src/app/interfaces/dropdown-option-interface-key-value';

//Services
import { AccountingPeriodService } from 'src/app/services/accounting-period/accounting-period.service';
import { ActiveProcessesService } from 'src/app/services/active-processes/active-processes.service';
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';
import { TrialBalanceService } from 'src/app/services/trial-balance/trial-balance.service';
import { UiSelectionOptionsService } from 'src/app/services/ui-selection-options/ui-selection-options.service';

@Component({
  selector: 'app-kii-trial-balance',
  templateUrl: './kii-trial-balance.component.html',
  styleUrls: ['./kii-trial-balance.component.scss'],
  providers: [ConfirmationService],
})
export class KiiTrialBalanceComponent implements OnInit {

  showAccountingPeriodWaitMessage: boolean = false;
  showTriggerOptions: boolean = true;
  showCheckingActiveProcessesMessage: boolean = false;
  showRunningStoredProcedureMessage: boolean = false;
  showStoredProcedureCompleteMessage: boolean = false;
  showActiveProcessesBlockingTimeoutMessage: boolean = false;

  trialBalanceSubmissionGroup: DropdownOptionInterface[] = [];
  selectedTrialBalanceSubmissionGroup: DropdownOptionInterface;

  trialBalancePeriodDate: DropdownOptionInterface[] = [];
  selectedtrialBalancePeriodDate: DropdownOptionInterface;

  processStatus: DropdownOptionInterface[];
  selectedprocessStatus: DropdownOptionInterface;

  trialBalanceDataLayer: DropdownOptionInterfaceKeyValue[] = [];
  selectedtrialBalanceDataLayer: DropdownOptionInterfaceKeyValue = { key: 'All', value: '' };

  constructor(
    private accountingPeriodService: AccountingPeriodService,
    private errorHandlerService: ErrorHandlerService,
    private trialBalanceService: TrialBalanceService,
    private activeProcessesService: ActiveProcessesService,
    private confirmationService: ConfirmationService,
    private uiSelectionOptionsService: UiSelectionOptionsService
  ) {
    this.selectedTrialBalanceSubmissionGroup = { 'name': 'AcctOrgKMT' };
    this.trialBalanceSubmissionGroup.push(this.selectedTrialBalanceSubmissionGroup);

    this.showAccountingPeriodWaitMessage = true;
    this.showTriggerOptions = false;

    console.log('getting accounting periods');
    this.accountingPeriodService.get_accounting_period_dates('TRIALBALANCEPERIODENDDATE', 'trial_balance').then(response => {
      console.log(response);
      let results = eval(response['body']);
      console.log(results);
      if (response['statusCode'] == 200) {
        for (var i = 0; i < results.length; i++) {
          this.trialBalancePeriodDate.push({ name: results[i][0] });
        }
      }
      else {
        this.errorHandlerService.UpdateAndDisplayError('Issue while getting trial balance period dates. Please refresh this page, or contact IT support');
      }
    })
    console.log('Getting trial balance data layers');
    this.uiSelectionOptionsService.PullSelectionOptions('trialBalanceDataLayer').then(response => {
      let results = eval(response['body']);
      if (response['statusCode'] == 200) {
        for (var i = 0; i < results.length; i++) {
          if (i == 0) {
            this.selectedtrialBalanceDataLayer = { key: results[i][0], value: results[i][1] }
          }
          else if (results[i][2] == true) {
            this.selectedtrialBalanceDataLayer = { key: results[i][0], value: results[i][1] }
          }
          this.trialBalanceDataLayer.push({ key: results[i][0], value: results[i][1] })
        }
      }
      this.showAccountingPeriodWaitMessage = false;
      this.showTriggerOptions = true;
    });
    console.log(this.trialBalanceDataLayer);

    this.selectedtrialBalancePeriodDate = { name: '-Select-' };
    this.trialBalancePeriodDate.unshift(this.selectedtrialBalancePeriodDate);

    this.processStatus = [
      {
        name: 'Hold'
      },
      {
        name: 'Approved'
      },
      {
        name: 'Closed'
      },
      {
        name: 'Reset'
      }]
    this.selectedprocessStatus = { name: 'Automate' };
    /*
    this.trialBalanceDataLayer = [
    {
      name: 'ACT-USGAAP-ConCurr-TB-B4Elim'
    },
    {
      name: 'ACT-USGAAP-ConCurr-Econ-B4Elim'
    },
    {
      name: 'ACT-LCLGAAP-LCLCurr-TB-B4Elim'
    }]
    this.selectedtrialBalanceDataLayer = {name: 'ACT-USGAAP-ConCurr-TB-B4Elim'};*/
  }

  ngOnInit(): void {
  }

  async TriggerTrialBalance() {
    this.confirmationService.confirm({
      message: `Process Status will be updated to ${this.selectedprocessStatus.name}. Do you want to proceed?`,
      accept: async () => {
        this.showRunningStoredProcedureMessage = false;
        this.showStoredProcedureCompleteMessage = false;
        this.showActiveProcessesBlockingTimeoutMessage = false;
        let trialBalanceDataLayer = this.selectedtrialBalanceDataLayer.key == '-Select-' ? '' : this.selectedtrialBalanceDataLayer.value;
        let processStatus = this.selectedprocessStatus.name = this.selectedprocessStatus.name;
        let trialBalancePeriodDate = this.selectedtrialBalancePeriodDate.name == '-Select-' ? '' : this.selectedtrialBalancePeriodDate.name;
        let trialBalanceSubmissionGroup = this.selectedTrialBalanceSubmissionGroup.name;
        this.showCheckingActiveProcessesMessage = true;
        let ActiveCheck = await this.activeProcessesService.WaitForActiveProcessCleared('kiiLoad');
        if (ActiveCheck == true) {
          //Show running Message
          this.showCheckingActiveProcessesMessage = false;
          this.showRunningStoredProcedureMessage = true;
        }
        else {
          //Show timeout
          this.showCheckingActiveProcessesMessage = false;
          this.showActiveProcessesBlockingTimeoutMessage = true;
        }
        this.showRunningStoredProcedureMessage = true;
        await this.trialBalanceService.update_trial_balance_status(trialBalanceDataLayer, processStatus, trialBalancePeriodDate, trialBalanceSubmissionGroup);
        let response = await this.activeProcessesService.WaitForActiveProcessCleared('kiiLoad');
        if (response == true) {
          //Show complete Message
          this.showRunningStoredProcedureMessage = false;
          this.showStoredProcedureCompleteMessage = true;
        }
        else {
          //Show timeout
          this.showRunningStoredProcedureMessage = false;
          this.showActiveProcessesBlockingTimeoutMessage = true;
        }
      }
    })
  }

}