//Angular
import { Component } from '@angular/core';

//Amplify
import { CognitoApiService } from './services/cognito-api/cognito-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private cognitoApiService: CognitoApiService) { }

  user: any;
  userAuthenticated: boolean = false;

  async ngOnInit() {
    this.userAuthenticated = await this.cognitoApiService.checkUserIsAuthenticated()

    if (!this.userAuthenticated) {
      this.cognitoApiService.kochIdLogin().then(response => {
        if (response) {
          this.userAuthenticated = true;
        }
      });
    }
  }
}
