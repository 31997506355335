import { Component, OnInit } from '@angular/core';

//Services
import { FileUploadTableHandlerService } from 'src/app/services/file-upload-table-handler/file-upload-table-handler.service';

@Component({
  selector: 'app-file-upload-table-selecter',
  templateUrl: './file-upload-table-selecter.component.html',
  styleUrls: ['./file-upload-table-selecter.component.scss']
})
export class FileUploadTableSelecterComponent implements OnInit {

  constructor(private fileUploadTableHandlerService: FileUploadTableHandlerService) { }

  ngOnInit(): void {
    this.GetFileList();
  }

  GetFileList() {
    this.fileUploadTableHandlerService.GetFileUploadTableListForDropdown('').then(response => {
      console.log(response);
    })
  }

}
