import { Injectable } from '@angular/core';

//Amplify

import { Storage } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class StorageHandlerService {

  constructor() { }

  async uploadFileToS3Bucket(bucket: string, fileKey: string, file: File, metadata?: {}) {
    if (!bucket || !fileKey || !file) { console.error('Missing some file upload parameters') }
    await Storage.put(fileKey, file, { bucket: bucket, contentType: 'text/csv', metadata: metadata });
  }

  UploadFileToGuiS3(fileName: string, file: File) {
    var fileKey = null;

    if (fileName == 'blackline') {
      fileKey = 'kyriba/kyriba_bankbalance/kyriba_bankbalance.csv';
    }
    else if (fileName == 'dpsr') {
      fileKey = `dpsr/dpsr_files/${file.name}`;
    }

    if (fileKey != null) {
      Storage.put(fileKey, file);
    }
    else {
      console.error('no file selected to put in storage');
    }
  }

  async RemoveFileFromStorage(fileName: string) {
    var fileKey = [];
    if (fileName == 'blackline') {
      fileKey.push('kyriba/kyriba_bankbalance/kyriba_bankbalance.csv');
    }

    else if (fileName == 'dpsr') {
      var keys = await Storage.list('dpsr/dpsr_files/')
      for (var i = 0; i < keys.results.length; i++) {
        if (!keys[i].key?.includes('archive')) {
          fileKey.push('' + keys[i].key);
        }
      }
    }

    if (fileKey != null) {
      console.log('deleting file');
      console.log(fileKey);
      for (var i = 0; i < fileKey.length; i++) {
        await Storage.remove(fileKey[i]);
      }
    }
    else {
      console.error('no file selected to delete');
    }

  }

  async ArchiveFileInStorage(fileName: string) {
    var fileKey = null;
    if (fileName == 'blackline') {
      fileKey = 'kyriba/kyriba_bankbalance/kyriba_bankbalance.csv';
    }

    if (fileKey != null) {
      var fileKeyList = fileKey.split('/')
      var destinationKey = fileKeyList[0]
      for (var i = 1; i < fileKeyList.length; i++) {
        if (i == fileKeyList.length - 1) {
          destinationKey = destinationKey + '/archive/' + fileKeyList[i];
        }
        else {
          destinationKey = destinationKey + '/' + fileKeyList[i];
        }

      }

      var todayDate = new Date;
      var year = todayDate.getFullYear().toString();
      var month = todayDate.getMonth().toString().padStart(2, '0');
      var day = todayDate.getDay().toString().padStart(2, '0');
      var hour = todayDate.getHours().toString().padStart(2, '0');
      var minute = todayDate.getMinutes().toString().padStart(2, '0');
      var second = todayDate.getSeconds().toString().padStart(2, '0');
      var millisecond = todayDate.getMilliseconds().toString();
      var todayDateString = '_' + year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + ':' + millisecond;

      destinationKey = destinationKey + todayDateString;
      console.log('copying file');
      await Storage.copy({ key: fileKey }, { key: destinationKey });
      await this.RemoveFileFromStorage('blackline');
    }
    else {
      console.log('no file selected to put in storage');
    }
  }


}
