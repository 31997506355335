//Angular
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

//Services
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveProcessesService {

  constructor(
    private errorHandlerService: ErrorHandlerService
  ) { }

  async CheckActiveProcesses(process: string) {
    /*
    Takes in process name to check for, and returns true
    if there is an entry in the actively running table
    and false if there is not. 
    If it finds an error, it will return null.
    */
    const apiName = 'fdaguiapi';
    const path = '/snowflake/activelyrunning'; 
    const myInit = { 
      queryStringParameters: { 
        actively_running_process: process,
      },
    };
    console.log('Checking on processes')
    var response = await API.get(apiName, path, myInit);
    if (response['statusCode'] != 200) {
      const message = `Exception while trying to pull actively running data - ${response['body']}`
      this.errorHandlerService.UpdateAndDisplayError(`error while trying to pull actively running - Please refresh the page, and contact IT if the problem persists`);
      console.error(message);
      return null;
    }
    const results = eval(response['body']);

    for (var i = 0; i < results.length; i++) {
      if (results[i][1].includes('error')) {
        this.errorHandlerService.UpdateAndDisplayError(`Error in actively running table, contact IT.`);
        return null;
      }
    }

    if (response['body'] != '[]') {
      return true
    }
    return false
  }


  async WaitForActiveProcessCleared(process: string, interval: number=5000, timeout: number=180) {
    console.log('Starting interval waiting for actively running');
    console.log(`interval - ${interval}`);
    console.log(`timeout - ${timeout}`);
    console.log(`process - ${process}`);

    for (var i = 0; i < timeout; i++) {
      await new Promise(r => setTimeout(r, interval));
      var response = await this.CheckActiveProcesses(process);
      console.log(response);
      if (response == null) {
        return null;
      } 
      else if (response == false) {
        i = timeout + 1;
        return true;
      }
    }
    return false;
  }


  async CheckAndWaitForActivelyRunning(file: string) {
    const activeProcessSearch = this.GetActiveProcessSearch(file);
    if (activeProcessSearch != '') {
      const processFinished = await this.WaitForActiveProcessCleared(activeProcessSearch);
      if (processFinished == false) {
        console.error('Error data could not be retrieved');
        return false;
      }
      else if (processFinished == null) {
        return null;
      }
      return true;
    }
    return false;
  }

  async CheckForActivelyRunning(file: string) {
    const activeProcessSearch = this.GetActiveProcessSearch(file);
    if (activeProcessSearch != '') {
      const processInActivelyRunning = await this.CheckActiveProcesses(activeProcessSearch);
      if (processInActivelyRunning == false) {
        return true;
      }
      else if (processInActivelyRunning == null) {
        return null;
      }
    }
    return false;
  }

  GetActiveProcessSearch(file: string) {
    var activeProcessSearch = '';
    if (file == 'bankBalance') {
      activeProcessSearch = 'bankBalanceDependancies';
    }
    else if (file == 'fxRates') {
      activeProcessSearch = 'fxRatesDependancies';
    }
    else if (file == 'slBal') {
      activeProcessSearch = 'slBalDependancies';
    }
    else if (file == 'glBal') {
      activeProcessSearch = 'glBalDependancies';
    }
    else if (file == 'slBalPrime') {
      activeProcessSearch = 'slBal';
    }
    else if (file == 'glBalPrime') {
      activeProcessSearch = 'glBal';
    }
    else if (file == 'fxratesPrime') {
      activeProcessSearch = 'fxRates';
    }
    else {
      console.error(`User error on CheckAndWaitForActivelyRunning file not found, file: ${file}`)
    }
    return activeProcessSearch;

  }

}
