<p-toolbar>
  <!-- ================= -->
  <!-- Application title -->
  <!-- ================= -->
  <div style="height:60px" class="p-toolbar-group-left"><br />
      <span id="title"><img id="KochLogo" src="../assets/images/medKochLogo.png" /> KM&T Financial Data Aquifer
      </span>
      <span class="toolbar-spacer"></span>
  </div>

  <!-- ============== -->
  <!-- Logged in user -->
  <!-- ============== -->
  <div class="p-toolbar-group-right"><br />
      <span id="user"> {{this.user}}
        <!--p-button class="p-button-icon" icon="pi pi-cog">
        </p-button>
        <span class="margin-left-10px"></span>
        <p-button icon="pi pi-question-circle">
        </p-button-->
      </span>
    </div>

</p-toolbar>


<!-- ============== -->
<!--     Menu       -->
<!-- ============== -->
<p-menubar [model]="items"></p-menubar>

<!-- ============== -->
<!--   Components   -->
<!-- ============== -->
<router-outlet></router-outlet>

<p-dialog header="Financial Data Aquifer Web Interface Error" [(visible)]="errorHandlerService.displayError" [modal]="true">
{{errorHandlerService.errorContent}}
</p-dialog>
