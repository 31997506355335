import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class UiSelectionOptionsService {

  constructor() { }


  async  PullSelectionOptions(filterType: string) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/selection/options'; 
    const myInit = {
      queryStringParameters: {
        filter_type: filterType
      }
    };
    const response = await API.get(apiName, path, myInit);
    console.log(response);
    return response
  }
}
