import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

//Interface
import { DropdownOptionInterface } from 'src/app/interfaces/dropdown-option-interface';

@Injectable({
  providedIn: 'root'
})
export class FileUploadTableHandlerService {

  constructor() { }

  async GetFileUploadTableRecords(parentDataType: string) {
    const apiName = 'fdaguiapi';
    const path = '/fileupload/table/children';
    const myInit = {
      queryStringParameters: {'parent_data_type': parentDataType},
    };
    console.log('Retrieving file upload table records');
    var response = await API.get(apiName, path, myInit);
    return response['body']
  }

  async GetFileUploadTableListForDropdown(parentDataType: string) {
    var tableList: DropdownOptionInterface[] = [];
    const results = await this.GetFileUploadTableRecords(parentDataType);
    for (var i = 0; i < results.length; i++) {
      tableList.push({ "name": results[i]['table_name']['S'] });
    }
    return tableList
  }

  async GetTableInformation(tableName: string) {
    const apiName = 'fdaguiapi';
    const path = '/fileupload/table';
    const myInit = {
      queryStringParameters: {
        "table_name": tableName
      },
    };
    var response = await API.get(apiName, path, myInit);
    return response['body']
  }
}
