import { Component, OnInit } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IToolPanelParams, RowNode } from 'ag-grid-community';

@Component({
    selector: 'status-component',
    template: `
      <div class="ag-status-name-value">
        <span>Total MTD Variance: </span>
        <span class="ag-status-name-value-value">{{ numMtdVariance }} </span>
        <span>Total YTD Variance: </span>
        <span class="ag-status-name-value-value">{{ numYtdVariance }}</span>
      </div>`
})
export class GptoSummaryBarComponent implements IStatusPanelAngularComp {
  private params!: IToolPanelParams;

  public numMtdVariance: string = "0";
  public numYtdVariance: string = "0";

  agInit(params: IToolPanelParams): void {
    this.params = params;

    // calculate stats when new rows are selected
    this.params.api.addEventListener('selectionChanged', this.updateTotals.bind(this));
    }

  updateTotals(): void {
    let numMtdVariance = 0, numYtdVariance = 0;
    let selectedNodes = this.params.api.getSelectedNodes();
    for (var i = 0; i < selectedNodes.length; i++) {
        const data = selectedNodes[i].data;
        if (data.mtdVariance) numMtdVariance += Number(data.mtdVariance);
        if (data.ytdVariance) numYtdVariance += Number(data.ytdVariance);
    };
    this.numMtdVariance = (Math.round(numMtdVariance * 100) / 100).toLocaleString();
    this.numYtdVariance = (Math.round(numYtdVariance * 100) / 100).toLocaleString();
  }
}