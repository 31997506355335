import { Injectable } from '@angular/core';

//Amplify
import { API } from 'aws-amplify';

//Interfaces
import { GptoSummmaryData } from 'src/app/interfaces/gpto-summary-data';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class GptoLoadDataService {

  econData!: GptoSummmaryData[];
  gaapData!: GptoSummmaryData[];
  econIcpData!: GptoSummmaryData[];
  gaapIcpData!: GptoSummmaryData[];

  loadingGaap: boolean = false;
  loadingEcon: boolean = false;
  loadingGaapIcpData: boolean = false;
  loadingEconIcpData: boolean = false;

  constructor(
    private errorHandlerService: ErrorHandlerService
  ) { }

  async retrieveGptoSummaryData(data_type: string, period: string) {
    console.log('Loading data');
    this.loadingGaap = true;
    this.loadingEcon = true;
    this.loadingGaapIcpData = true;
    this.loadingEconIcpData = true;

    if (data_type == 'GAAP') {
      var response1 = await this.retrieveData('GAAP', period);
      if (response1['statusCode'] != 200) {
        console.error('Non 200 status code from lambda for GAAP');
        console.error(response1);
        this.errorHandlerService.UpdateAndDisplayError('Error whille pulling GAAP data please try again or contact IT');
      }
      else {
        this.gaapData = this.formatData(eval(response1['body']));
      }
      this.loadingGaap = false;
      //ICP
      this.retrieveData('GAAP', period, true).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for ECON');
          }
        else {
          this.gaapIcpData = this.formatData(eval(response['body']));
        }
        this.loadingGaapIcpData = false;
      });

      this.retrieveData('ECON', period).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for ECON');
          }
        else {
          this.econData = this.formatData(eval(response['body']));
        }
        this.loadingEcon = false;
      });
      //ICP
      this.retrieveData('ECON', period, true).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for ECON');
          }
        else {
          this.econIcpData = this.formatData(eval(response['body']));
        }
        this.loadingEconIcpData = false;
      });
    }

    else {
      var response1 = await this.retrieveData('ECON', period);
      if (response1['statusCode'] != 200) {
        console.error('Non 200 status code from lambda for ECON');
        this.errorHandlerService.UpdateAndDisplayError('Error whille pulling GAAP data please try again or contact IT');
      }
      else {
        this.econData = this.formatData(eval(response1['body']));
      }
      this.loadingEcon = false;

      //ICP
      this.retrieveData('ECON', period, true).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for ECON');
          }
        else {
          this.econIcpData = this.formatData(eval(response['body']));
        }
        this.loadingEconIcpData = false;
      });

      this.retrieveData('GAAP', period).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for GAAP');
          }
        else {
          this.gaapData = this.formatData(eval(response['body']));
        }
        this.loadingGaap = false;
      });
      //ICP
      this.retrieveData('GAAP', period, true).then(response => {
        if (response['statusCode'] != 200) {
          console.error('Non 200 status code from lambda for ECON');
          }
        else {
          this.gaapIcpData = this.formatData(eval(response['body']));
        }
        this.loadingGaapIcpData = false;
      });
    }
  }

  async retrieveData(dataType: string, period: string, icp: boolean = false) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/gpto'; 
    const myInit = { 
      queryStringParameters: { 
        data_type: dataType,
        period: period,
        icp: icp
      },
    };
    return await API.get(apiName, path, myInit)
  }

  formatData(data: any): GptoSummmaryData[] {
    var dataArray: GptoSummmaryData[] = []; 
    var dataBody = data
    for (let i = 0; i < dataBody.length; i++) {
      var dataRow: GptoSummmaryData = {
        accountingStatus: dataBody[i][0], 
        dataType: dataBody[i][1],
        derivativeInstrument: dataBody[i][2], 
        explainYtdVariances: dataBody[i][3], 
        intercompanyPartner: dataBody[i][4], 
        kstRingfence: dataBody[i][5], 
        ktb: dataBody[i][6], 
        legalEntity: dataBody[i][7], 
        mele: dataBody[i][8], 
        mtdGl: Number(dataBody[i][9]), 
        mtdSourceDetail: Number(dataBody[i][10]),
        mtdVariance: Number(dataBody[i][11]), 
        naturalAccount: dataBody[i][12], 
        naturalAccountDescription: dataBody[i][13], 
        notes: dataBody[i][14],
        period: dataBody[i][15], 
        re_curr: dataBody[i][16],
        sourceSystem: dataBody[i][17], 
        ytdGl: Number(dataBody[i][18]), 
        ytdSourceDetail: Number(dataBody[i][19]), 
        ytdVariance: Number(dataBody[i][20]), 
        updated: dataBody[i][21], 
        updatedBy: dataBody[i][22], 
        summaryLevelHash: dataBody[i][23]
      }
      dataArray.push(dataRow);
    }
    return dataArray;
  }

  async LoadGPTOData(datatype: string, period: string) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/loadgpto'; 
    const myInit = { 
        body: {
          data_type: datatype,
          period: period
        }
    };
    return await API.post(apiName, path, myInit)
  }
}
