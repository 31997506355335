//angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Primeng
import { ToolbarModule } from 'primeng/toolbar';
import { MenubarModule } from 'primeng/menubar';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';

//Amplify
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';

//AGGrid
import 'ag-grid-community';
import 'ag-grid-angular';
import 'ag-grid-enterprise';
import { AgGridModule } from 'ag-grid-angular';


//components
import { AppComponent } from './app.component';
import { BannerComponent } from './components/banner/banner.component';
import { BlacklineLoadReprocessComponent } from './components/blackline-load-reprocess/blackline-load-reprocess.component';
import { RefreshSpinComponent } from './components/refresh-spin/refresh-spin.component';
import { MappingRulesLoadComponent } from './components/mapping-rules-load/mapping-rules-load.component';
import { BlacklineLoadGridComponent } from './components/blackline-load-grid/blackline-load-grid.component';
import { BlacklineLoadGridSummaryBarCurrencyAmountComponent } from './components/blackline-load-grid-summary-bar-currency-amount/blackline-load-grid-summary-bar-currency-amount.component';
import { BlacklineLoadGridSummaryBarCurrencyQuantityAmountComponent } from './components/blackline-load-grid-summary-bar-currency-quantity-amount/blackline-load-grid-summary-bar-currency-quantity-amount.component';
import { ProcessStatusComponent } from './components/process-status/process-status.component';
import { KiiTrialBalanceComponent } from './components/kii-trial-balance/kii-trial-balance.component';
import { GptoEditCellRenderer } from './components/gpto-edit-cell-renderer/gpto-edit-cell-renderer';
import { GptoSummmaryComponent } from './components/gpto-summmary/gpto-summmary.component';
import { GptoSummaryBarComponent } from './components/gpto-summary-bar/gpto-summary-bar.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { PowerBiRefreshComponent } from './components/power-bi-refresh/power-bi-refresh.component';
import { AppRoutingModule } from './app-routing.module';
import { FileUploadTableSelecterComponent } from './components/file-upload-table-selecter/file-upload-table-selecter.component';
import { ConvertFileSize } from './components/file-uploader/file-uploader-helper';
import { StormComponent } from './components/storm/storm.component';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    FileUploaderComponent,
    BlacklineLoadReprocessComponent,
    RefreshSpinComponent,
    MappingRulesLoadComponent,
    BlacklineLoadGridComponent,
    BlacklineLoadGridSummaryBarCurrencyAmountComponent,
    BlacklineLoadGridSummaryBarCurrencyQuantityAmountComponent,
    ProcessStatusComponent,
    KiiTrialBalanceComponent,
    GptoEditCellRenderer,
    GptoSummmaryComponent,
    GptoSummaryBarComponent,
    CustomCheckboxComponent,
    PowerBiRefreshComponent,
    FileUploadTableSelecterComponent,
    ConvertFileSize,
    StormComponent
  ],
  imports: [
    AutoCompleteModule,
    BrowserModule,
    ButtonModule,
    FontAwesomeModule,
    AmplifyAuthenticatorModule,
    ToolbarModule,
    MenubarModule,
    FileUploadModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    InputSwitchModule,
    FormsModule,
    DropdownModule,
    BrowserAnimationsModule,
    DialogModule,
    SelectButtonModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    AppRoutingModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
