import { Injectable } from '@angular/core';

//Amplfiy
import { API } from 'aws-amplify';

//Interfaces
import { BlacklineDataBankBalance } from 'src/app/interfaces/blackline-data-bank-balance';
import { BlacklineDataFxRates } from 'src/app/interfaces/blackline-data-fx-rates';
import { BlacklineDataGlBal } from 'src/app/interfaces/blackline-data-gl-bal';
import { BlacklineDataSlBal } from 'src/app/interfaces/blackline-data-sl-bal';
import { ActiveProcessesService } from '../active-processes/active-processes.service';



@Injectable({
  providedIn: 'root'
})
export class BlacklineLoadDataService {

  constructor(private activeProcessesService: ActiveProcessesService) {
   }
  waitingOnOtherProcesses: boolean = false;
  displayBlockingActivelyProcessingMessage: boolean = true;

  formatBankBalanceData(data: any): BlacklineDataBankBalance[] {
    var dataArray: BlacklineDataBankBalance[] = []; 
    var dataBody = data
    for (let i = 0; i < dataBody.length; i++) {
      var dataRow: BlacklineDataBankBalance = {
        mele: dataBody[i][0], 
        account: dataBody[i][1],
        ktb: dataBody[i][2], 
        legalEntity: dataBody[i][3], 
        datatype: dataBody[i][4], 
        location: dataBody[i][5], 
        commodity: dataBody[i][6], 
        locationType: dataBody[i][7],
        motIcp: dataBody[i][8],
        currency: dataBody[i][9],
        date: dataBody[i][10],
        usdAmount: dataBody[i][11],
        field1: dataBody[i][12],
        currencyAmount: dataBody[i][13],
        bankAccountNumber: dataBody[i][14]
      }
      dataArray.push(dataRow);
    }
    return dataArray;
  }

  formatSlBalData(data: any): BlacklineDataSlBal[] {
    var dataArray: BlacklineDataSlBal[] = []; 
    var dataBody = data
    for (let i = 0; i < dataBody.length; i++) {
      var dataRow: BlacklineDataSlBal = {
        mele: dataBody[i][0], 
        account: dataBody[i][1],
        ktb: dataBody[i][2], 
        legalEntity: dataBody[i][3], 
        datatype: dataBody[i][4], 
        location: dataBody[i][5], 
        commodity: dataBody[i][6], 
        locationType: dataBody[i][7],
        motIcp: dataBody[i][8],
        currency: dataBody[i][9],
        date: dataBody[i][10],
        usdAmount: dataBody[i][11],
        field_1: dataBody[i][12],
        currencyAmount: dataBody[i][13],
        sourceSystem: dataBody[i][14],
        rate: dataBody[i][15],
      }
      dataArray.push(dataRow);
    }
    return dataArray;
  }

  formatGlBalData(data: any): BlacklineDataGlBal[] {
    var dataArray: BlacklineDataGlBal[] = []; 
    var dataBody = data
    for (let i = 0; i < dataBody.length; i++) {
      var dataRow: BlacklineDataGlBal = {
        mele: dataBody[i][0], 
        account: dataBody[i][1],
        ktb: dataBody[i][2], 
        legalEntity: dataBody[i][3], 
        datatype: dataBody[i][4], 
        location: dataBody[i][5], 
        commodity: dataBody[i][6], 
        locationType: dataBody[i][7],
        motIcp: dataBody[i][8],
        currency1: dataBody[i][9],
        accountDescription: dataBody[i][10],
        reconciliationType: dataBody[i][11],
        financialStatement: dataBody[i][12],
        type: dataBody[i][13],
        active: dataBody[i][14],
        activity: dataBody[i][15],
        field6: dataBody[i][16],
        currency2: dataBody[i][17],
        date: dataBody[i][18],
        usdAmount: dataBody[i][19],
        field7: dataBody[i][20],
        currencyQuantityAmount: dataBody[i][21],
        sourceSystem: dataBody[i][22]
      }
      dataArray.push(dataRow);
    }
    return dataArray;
  }

  formatFxRatesData(data: any): BlacklineDataFxRates[] {
    var dataArray: BlacklineDataFxRates[] = []; 
    var dataBody = data
    for (let i = 0; i < dataBody.length; i++) {
      var dataRow: BlacklineDataFxRates = {
        currency: dataBody[i][0], 
        conversionMethod: dataBody[i][1],
        rate: dataBody[i][2], 
        convertTo: dataBody[i][3], 
        date: dataBody[i][4], 
        period: dataBody[i][5], 
      }
      dataArray.push(dataRow);
    }
    return dataArray;
  }

  async retrieveData(file: string, accountingPeriod: string) {
    const apiName = 'fdaguiapi';
    const path = '/blackline/data'; 
    const myInit = { 
      queryStringParameters: { 
        required_data: file,
        accountingPeriod: accountingPeriod,
      },
    };
    return await API.get(apiName, path, myInit)
  }

  async primeData(file: string, accountingPeriod: string) {
    const apiName = 'fdaguiapi';
    const path = '/blackline/data'; 
    const myInit = { 
      body: { 
        required_data: file,
        accountingPeriod: accountingPeriod,
      },
    };
    let response = await API.post(apiName, path, myInit);
    console.log(response);
    return response
  }

  async retrieveBlacklineBankBalanceData(accountingPeriod: string) {
    var results: BlacklineDataBankBalance[] = []

    const response = await this.retrieveData('bankBalance', accountingPeriod);

    console.log('Formatting data');

    results = this.formatBankBalanceData(eval(response['body']));
    return results;
  }

  async retrieveBlacklineFxRatesData(accountingPeriod: string) {
    var results: BlacklineDataFxRates[] = []

    console.log('Priming data');
    let primeDataResponse = await this.primeData('fxrates', accountingPeriod);
    if (primeDataResponse.statusCode == 200) {

      console.log('Waiting for priming to be completed');
      const response2 = await this.activeProcessesService.CheckAndWaitForActivelyRunning('fxratesPrime');
      if (!response2) {
        return results;
      }
      console.log('Loading data');
      const response3 = await this.retrieveData('fxRates', accountingPeriod);

      if (response3['statusCode'] != 200) {
        console.error('Non 200 status code from lambda');
        return results;
      }

      console.log('Formatting data');

      results = this.formatFxRatesData(eval(response3['body']));
    }
    return results;
  }

  async retrieveBlacklineSlBalData(accountingPeriod: string) {
    var results: BlacklineDataSlBal[] = []

    console.log('Priming data');
    let primeDataResponse = await this.primeData('slBal', accountingPeriod);
    if (primeDataResponse.statusCode == 200) {
      console.log('Waiting for priming to be completed');
      const response2 = await this.activeProcessesService.CheckAndWaitForActivelyRunning('slBalPrime');
      if (!response2) {
        return null;
      }
      console.log('Loading data');
      const response3 = await this.retrieveData('slBal', accountingPeriod);
      console.log(response3);

      console.log('Formatting data');

      results = this.formatSlBalData(eval(response3['body']));
    }
    return results;
  }

  async retrieveBlacklineGlBalData(accountingPeriod: string) {
    var results: BlacklineDataGlBal[] = []

    console.log('Priming data');
    let primeDataResponse = await this.primeData('glBal', accountingPeriod);
    if (primeDataResponse.statusCode == 200) {
      const response2 = await this.activeProcessesService.CheckAndWaitForActivelyRunning('glBalPrime');
      if (!response2) {
        return results;
      }
      console.log('Loading data');
      const response3 = await this.retrieveData('glBal', accountingPeriod);
      console.log(response3);

      console.log('Formatting data');

      results = this.formatGlBalData(eval(response3['body']));
    }
    return results;
  }  

  UpdateActiveProcessMessage(blocking: boolean) {
    if (blocking) {
      this.displayBlockingActivelyProcessingMessage = true;
    }
    else {
      this.displayBlockingActivelyProcessingMessage = false;
    }
  }
}
