//Angular
import { Component, OnInit } from '@angular/core';

//Interfaces
import { BlacklineLoadGridList } from 'src/app/interfaces/blackline-load-grid-list';
import { DropdownOptionInterface } from 'src/app/interfaces/dropdown-option-interface';

//Services
import { AccountingPeriodService } from 'src/app/services/accounting-period/accounting-period.service';
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';


@Component({
  selector: 'app-blackline-load-reprocess',
  templateUrl: './blackline-load-reprocess.component.html',
  styleUrls: ['./blackline-load-reprocess.component.scss']
})
export class BlacklineLoadReprocessComponent implements OnInit {
  
  showReprocessFiles: boolean = false;
  showAccountingPeriodWaitMessage: boolean = false;

  doAll: boolean = false;
  doFxRates: boolean = false;
  doGLBalance: boolean = false;
  doBankBalance: boolean = false;
  doSLBalance: boolean = false;
  
  blacklineReprocessDates: DropdownOptionInterface[];
  selectedReprocessDate: DropdownOptionInterface;

  showBlacklineGridWizard = false;

  gridList: BlacklineLoadGridList = {
    doFxRates: this.doFxRates,
    doGLBalance: this.doGLBalance,
    doBankBalance: this.doBankBalance,
    doSLBalance: this.doSLBalance
  };

  constructor(
    private accountingPeriodService: AccountingPeriodService
    ,private errorHandlerService: ErrorHandlerService
    ) {
      this.showAccountingPeriodWaitMessage = true;
      this.showReprocessFiles = false;
      this.blacklineReprocessDates = [];
      this.selectedReprocessDate = {name: '-Select-'}
      this.blacklineReprocessDates.push({name: '-Select-'});
      this.accountingPeriodService.get_accounting_period_dates('ACCOUNTINGPERIODID').then(response => {
        let results = eval(response['body']);
        console.log(results);
        if (response['statusCode'] == 200) {
          for (var i = 0; i < results.length; i++) {
            this.blacklineReprocessDates.push({name: results[i][0]});
          }
          this.showAccountingPeriodWaitMessage = false;
          this.showReprocessFiles = true;
        }
        else {
          this.errorHandlerService.UpdateAndDisplayError('Issue while getting accounting period dates. Please refresh this page, or contact IT support');
        }
      })
    }

  ngOnInit(): void {
  }

  handleInputAllSwitchChange(e: any) {
    if (e.checked) {
      this.doFxRates = true;
      this.doGLBalance = true;
      this.doBankBalance = true;
      this.doSLBalance = true;
    }
    if (!e.checked) {
      this.SetAllSwitchesToFalse()
    }
  }

  showGridWizard() {
    this.showBlacklineGridWizard = true;
    this.gridList = {
      doBankBalance: this.doBankBalance,
      doFxRates: this.doFxRates,
      doGLBalance: this.doGLBalance,
      doSLBalance: this.doSLBalance,
    }
    console.log(`grid list for blackling load - ${this.gridList}`);
  }

  CloseDialog() {
    this.showBlacklineGridWizard = false;
    this.SetAllSwitchesToFalse();
    this.ResetReprocessingList();
  }

  SetAllSwitchesToFalse() {
    this.doFxRates = false;
    this.doGLBalance = false;
    this.doBankBalance = false;
    this.doSLBalance = false;
    this.doAll = false;
  }

  ResetReprocessingList() {
    this.selectedReprocessDate = {name: '-Select'}
  }
  
}
