import {Component} from "@angular/core";
import { IToolPanelParams } from "ag-grid-community";
import { IStatusPanelAngularComp } from "ag-grid-angular";

@Component({
    selector: 'status-component',
    template: `
      <div class="ag-status-name-value">
        <span>Total USD Amount: </span>
        <span class="ag-status-name-value-value">{{ numUsdAmount }} </span>
        <span>Total Currency Amount: </span>
        <span class="ag-status-name-value-value">{{ numCurrencyAmount }}</span>
      </div>`
})
export class BlacklineLoadGridSummaryBarCurrencyAmountComponent implements IStatusPanelAngularComp {
  private params!: IToolPanelParams;

  public numUsdAmount: string = "0";
  public numCurrencyAmount: string = "0";

  agInit(params: IToolPanelParams): void {
      this.params = params;

      // calculate stats when new rows are selected
      this.params.api.addEventListener('selectionChanged', this.updateTotals.bind(this));
  }

  updateTotals(): void {
      let numUsdAmount = 0, numCurrencyAmount = 0;
      let selectedNodes = this.params.api.getSelectedNodes();
      for (var i = 0; i < selectedNodes.length; i++) {
          const data = selectedNodes[i].data;
          if (data.usdAmount) numUsdAmount += Number(data.usdAmount);
          if (data.currencyAmount) numCurrencyAmount += Number(data.currencyAmount);
      };
      this.numUsdAmount = (Math.round(numUsdAmount * 100) / 100).toLocaleString();
      this.numCurrencyAmount = (Math.round(numCurrencyAmount * 100) / 100).toLocaleString();
  }
}