import { FileUploaderValidationIssues } from "src/app/interfaces/file-uploader-validation-issues";
import { Pipe, PipeTransform } from '@angular/core';
import { ProcessStatusRow } from "src/app/interfaces/process-status-row";

@Pipe({ name: 'convertFileSize' })
export class ConvertFileSize implements PipeTransform {
    transform(value: number): string {
        if (value == 0) { return '0 B'; }
        var k = 1000,
            dm = 3,
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(value) / Math.log(k));
        return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}


export class FileUploadHelper {

    static hasValue(value: any) {
        return value === undefined || value === null || value === "" ? false : true;
    }

    static sortEntities(a: Object, b: Object, field: string) {
        var nameA = a[field];
        var nameB = b[field];
        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
    }

    static async validateFileUsingConfigSchema(file: File, fileTypeConfig: any, dpsr_validate?: any, fileValidator?: any): Promise<FileUploaderValidationIssues[]> {
        let validationIssues: FileUploaderValidationIssues[] = [];
        const schema: object[] = fileTypeConfig.schema;
        schema.sort((a, b) => this.sortEntities(a, b, 'column_order'));

        function addValidationError(row: number, column: string, value: string, type: string, desc?: string) {
            validationIssues.push({
                Row: row,
                ColumnName: column,
                ColumnValue: value,
                ExpectedDataType: type,
                ErrorDescription: desc ?? `Row value [${value}] does not match data type [${type}]`
            });
        }

        function splitCSVLine(csvLine: string){
            let parts = [];
            let value = [];
            let ignore_comma = false;
            for(let idx = 0; idx < csvLine.length; idx++){
                let char = csvLine[idx];
                if(char == '"'){ ignore_comma = !ignore_comma; }
                if(char == ',' && !ignore_comma) {
                    parts.push(value.join(''));
                    value = [];
                }
                else { value.push(char); }
            }
            return parts;
        }

        async function validateColumns(csvLines: string[], isFirstRead: boolean) {
            for (let i = 0; i < csvLines.length; i++) {
                const lineValues = splitCSVLine(csvLines[i]);
                for (let j = 0; j < schema.length; j++) {
                    const dataType = schema[j]['data_type'];
                    const _dataType = dataType.toLowerCase();
                    const columnName = schema[j]['column_name'];

                    if (!FileUploadHelper.hasValue(lineValues[j])) { continue; }
                    if (isFirstRead && i == 0) {
                        if (lineValues[j].toLocaleLowerCase().trim() != columnName.toLocaleLowerCase()) {
                            addValidationError(i, columnName, lineValues[j], 'string', `File column name [${lineValues[j]}] does not match file type schema column name [${columnName}]`);
                        }
                    }
                    else {
                        if (dpsr_validate && fileTypeConfig['file_load_pk'] == 'dpsr' && ['year', 'period', 'ud3_days'].filter(v => v == columnName.toLowerCase()).length > 0 && lineValues[j].trim() != dpsr_validate[columnName].trim()) {
                            addValidationError(i, columnName, lineValues[j], '', `'${lineValues[j]}', does not match selected ${columnName} - '${dpsr_validate[columnName]}'`);
                            continue;
                        }
                        if (fileValidator && !fileValidator(i, lineValues[j], columnName, addValidationError)) { continue; }

                        if (['varchar', 'text', 'string'].filter(i => _dataType.indexOf(i) > -1).length > 0) {
                            const character_length = parseInt(_dataType.replace('varchar(', '').replace(')', '').trim());
                            if (lineValues[j].trim().length > character_length) {
                                addValidationError(i, columnName, lineValues[j], dataType, `Character length of value [${lineValues[j]}] is greater than schema character length [${dataType}]`);
                            }
                        }
                        else if (['decimal', 'numeric'].filter(i => _dataType.indexOf(i) > -1).length > 0) {
                            const cutoffs = _dataType.replace('decimal(', '').replace('numeric', '').replace(')', '').trim().split(',').map((v: string) => parseInt(v));
                            let pattern = `(^[-]?\\d{0,${cutoffs[0]}}([.]\\d{0,${cutoffs[1]}})?)$`;
                            if (!lineValues[j].trim().match(pattern)) { addValidationError(i, columnName, lineValues[j], dataType); }
                        }
                        else if (_dataType == 'date') {
                            let pattern = `^((0?[1-9]|[12]\\d|30|31)[^\\w\\d\\r\\n:](0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](\\d{4}|\\d{2}))$|^((\\d{4}|\\d{2})[^\\w\\d\\r\\n:](0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](0?[1-9]|[12]\\d|30|31))$|^((0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](0?[1-9]|[12]\\d|30|31)[^\\w\\d\\r\\n:](\\d{4}|\\d{2}))$`;
                            if (!lineValues[j].trim().match(pattern)) { addValidationError(i, columnName, lineValues[j], dataType); }
                        }
                        else if (_dataType == 'datetime') {
                            let pattern = `^((0?[1-9]|[12]\\d|30|31)[^\\w\\d\\r\\n:](0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](\\d{4}|\\d{2}))$|^((\\d{4}|\\d{2})[^\\w\\d\\r\\n:](0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](0?[1-9]|[12]\\d|30|31))$|^((0?[1-9]|1[0-2])[^\\w\\d\\r\\n:](0?[1-9]|[12]\\d|30|31)[^\\w\\d\\r\\n:](\\d{4}|\\d{2}) (00|[0-9]|1[0-9]|2[0-3]):([0-9]|[0-5][0-9])(:([0-9]|[0-5][0-9])))$`;
                            if (!lineValues[j].trim().match(pattern)) { addValidationError(i, columnName, lineValues[j], dataType); }
                        }
                        else if ((_dataType.indexOf('boolean') > -1) && (['true', 'false'].filter(v => v == lineValues[j].toLocaleLowerCase()).length != 1)) {
                            addValidationError(i, columnName, lineValues[j], dataType);
                        }
                        else if ((['int', 'integer', 'bigint', 'smallint', 'tinyint', 'byteint'].filter(i => _dataType.indexOf(i) > -1).length > 0) && !(lineValues[j].trim().match('^([-]?\\d*)$'))) {
                            addValidationError(i, columnName, lineValues[j], dataType);
                        }
                    }
                }
            }
            return await validateFilesInChunks();
        }

        let totalBytesRead = 0;
        let chunkSize = 1000 * 1024;
        let numberOfReads = 0;
        let csvLines: Array<string> = [];
        let remainder: string | undefined = '';
        let decoder = new TextDecoder();

        async function validateFilesInChunks() {
            if (totalBytesRead == file.size) { return; }

            const nextChunkSize = (totalBytesRead + chunkSize) > file.size ? (file.size - totalBytesRead) : chunkSize;
            const chunk = await file.slice(totalBytesRead, (totalBytesRead + nextChunkSize)).arrayBuffer();
            numberOfReads += 1;
            totalBytesRead = totalBytesRead + chunk.byteLength;
            let chunkBytes = new Uint8Array(chunk);
            csvLines = [...(remainder + decoder.decode(chunkBytes)).split('\n')];
            if (numberOfReads == 1) {
                if (csvLines[0].split(',').length != schema.length) {
                    addValidationError(0, 'Headers', csvLines[0], schema.map(s => s['column_name']).join(','), 'File headers and schema headers count does not match');
                    return;
                }
            }
            remainder = csvLines.pop();
            await validateColumns(csvLines, numberOfReads == 1);
        }

        await validateFilesInChunks();
        return validationIssues;
    }

    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static getDateTimeString(date?: Date, date_separator: string = '-', time_separator: string = ':') {
        date ??= new Date();
        var year = date.getFullYear().toString();
        var month = (date.getMonth() + 1).toString().padStart(2, '0');
        var day = date.getDate().toString().padStart(2, '0');
        var hour = (date.getHours() - 2).toString().padStart(2, '0');
        var minute = date.getMinutes().toString().padStart(2, '0');
        var second = date.getSeconds().toString().padStart(2, '0');
        var millisecond = date.getMilliseconds().toString();
        return [year, month, day].join(date_separator) + ' ' + [hour, minute, second, millisecond].join(time_separator);
    }

    static formatProcessStatusRows(data: any): ProcessStatusRow[] {
        var dataArray: ProcessStatusRow[] = [];
        var dataBody = data
        for (let i = 0; i < dataBody.length; i++) {
            var dataRow: ProcessStatusRow = {
                processor: dataBody[i][0],
                process: dataBody[i][1],
                subProcess: dataBody[i][2],
                processStep: dataBody[i][3],
                accountingPeriod: dataBody[i][4],
                errorMessage: dataBody[i][5],
                statusMessage: dataBody[i][6],
                processDateTime: dataBody[i][7],
                processId: dataBody[i][8],
                mostRecent: dataBody[i][9]
            }
            dataArray.push(dataRow);
        }
        return dataArray;
    }

    static sanitizeS3FileKey(key: string){
        let fileKey = key;
        for(let char of [' ', '[(]', '[)]', '[$]', '[%]', '[&]', '[@]', '[!]', '[#]']){ fileKey = fileKey.replace(new RegExp(char, "g"), '_'); }
        return fileKey.replace(/__/, "_");
    }
}