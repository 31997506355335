//angular
import { Injectable } from '@angular/core';

//npm packages
import { v4 as uuidv4 } from 'uuid';

//Amplify
import { API } from 'aws-amplify';
import { CognitoApiService } from '../cognito-api/cognito-api.service';

//Interfaces
import { ProcessStatusRow } from 'src/app/interfaces/process-status-row';
import { FileUploadHelper } from 'src/app/components/file-uploader/file-uploader-helper';

@Injectable({
  providedIn: 'root'
})
export class ProcessStatusService {

  reProcessEnabled: boolean = false;
  latestProcessLogRow: ProcessStatusRow = {} as ProcessStatusRow;
  uuidReference = {};

  constructor(private cognitoApiService: CognitoApiService) { }

  async RetrieveProcessStatusRows() {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/processstatus';
    const myInit = {};
    const response = await API.get(apiName, path, myInit);
    var results = FileUploadHelper.formatProcessStatusRows(eval(response['body']));
    return results;
  }

  async GenerateAndCreateProcessStatusRow(process: string, subProcess: string, processStep: string, status: string, accountingPeriod?: string | null, processId?: string | null, error_message?: string | null) {
    if (processId == null) {
      processId = '';
    }
    if (accountingPeriod == null) {
      accountingPeriod = '';
    }
    let uuid = ''
    if (processId == null) {
      if (subProcess in this.uuidReference) {
        uuid = this.uuidReference[subProcess];
      }
    }
    else {
      uuid = processId;
    }

    this.latestProcessLogRow = {
      processor: await this.cognitoApiService.retrieveUserFirstNameLastName() ?? 'Unknown',
      process: process,
      subProcess: subProcess,
      processStep: processStep,
      accountingPeriod: parseInt(accountingPeriod),
      errorMessage: error_message,
      processId: uuid,
      statusMessage: status,
    } as ProcessStatusRow;

    const apiName = 'fdaguiapi';
    const path = '/snowflake/processstatus';
    const myInit = {
      body: {
        processor: this.latestProcessLogRow.processor,
        process: this.latestProcessLogRow.process,
        sub_process: this.latestProcessLogRow.subProcess,
        process_step: this.latestProcessLogRow.processStep,
        accounting_period: this.latestProcessLogRow.accountingPeriod,
        error_message: this.latestProcessLogRow.errorMessage,
        process_id: this.latestProcessLogRow.processId,
        status_message: this.latestProcessLogRow.statusMessage,
      }
    };
    return await API.post(apiName, path, myInit);
  }

  async LogBlacklineLoad(fileName: string, process_step: string, status: string, accountingPeriod: string, newProcessId: boolean, processId?: string | null, error_message?: string | null) {
    const process = 'Blackline file upload'
    var id = ''
    if (processId != null) {
      id = processId
    }
    else if (newProcessId == true) {
      let uuid = uuidv4()
      this.uuidReference[fileName] = uuid;
      id = uuid;
    }
    else if (this.uuidReference[fileName]) {
      id = this.uuidReference[fileName];
    }
    else {
      return false;
    }

    const response = await this.GenerateAndCreateProcessStatusRow(process, fileName, process_step, status, accountingPeriod, id, error_message);

    if (response['statusCode'] == 200) {
      return true;
    }

    return false;
  }

  async LogFileUpLoad(process_step: string, status: string, newProcessId: boolean, process: string, subProcess: string, fileName: string, error_message?: string | null) {
    console.log('logging file upload')
    var id = ''

    if (newProcessId == true) {
      let uuid = uuidv4()
      this.uuidReference[fileName] = uuid;
      id = uuid;
    }
    else if (this.uuidReference[fileName]) {
      id = this.uuidReference[fileName];
    }
    else {
      return false;
    }

    const response = await this.GenerateAndCreateProcessStatusRow(process, subProcess, process_step, status, null, id);

    if (response['statusCode'] == 200) {
      return true;
    }

    return false;
  }

  async LogFileUpLoadAndReturnLogData(process_step: string, status: string, newProcessId: boolean, process: string, fileName: string, subProcess: string, error_message?: string | null) {
    console.log('logging file upload')
    var id = ''

    if (newProcessId == true) {
      let uuid = uuidv4()
      this.uuidReference[fileName] = uuid;
      id = uuid;
    }
    else if (this.uuidReference[fileName]) {
      id = this.uuidReference[fileName];
    }
    else {
      return null;
    }

    const response = await this.GenerateAndCreateProcessStatusRow(process, subProcess, process_step, status, null, id);
    if (response['statusCode'] == 200) {
      return this.latestProcessLogRow;
    }
    return null;
  }

}
