import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class PowerBiDatasetService {

  current_month!: string | null;

  async ExecuteRefresh() {
    try {
      console.log('Checking for currently running');
      let result = await this.CheckRunningPowerBIRefresh();
      if (result == null) {
        return {
          statusCode: 500
        }
      }
      else if (result == true) {
        return {
          statusCode: 409
        }
      }
      else {
        var retry_count = 0;
        while (retry_count < 2) {
          try {
            const response = await this.KickOffPreRefresh();
            console.log('pre refresh complete');
            console.log(response);
            if (response['statusCode'] != 200) {
              console.log('Error during pre refresh');
              return {
                statusCode: 500
              }
            }
            retry_count = 2;
          }
          catch(ex) {
            console.log('Error while running pre refresh, trying again.');
            var interval = 10000;
            await new Promise(r => setTimeout(r, interval));
          }
          retry_count = retry_count + 1;
        }
      }

      console.log('Kicking off powerbi refresh');
      const apiName = 'fdaguiapi';
      const path = '/gpto/refreshpowerbi'; 
      const myInit = {
        body: {

        }
      };
      const response = await API.post(apiName, path, myInit)
      console.log(response);
      const statusCode = response['statusCode']
      if (statusCode == 200 || statusCode == 409) {
        this.current_month = response['currentMonth']
      }
      return response
    }
    catch (ex) {
      console.log('Error');
      console.log(ex);
      return {
        statusCode: 500
      }
    }
  }

  async KickOffPreRefresh() {
    console.log('Kicking off pre refresh');
    const apiName = 'fdaguiapi';
    const path = '/gpto/prerefresh'; 
    const myInit = {
      body: {

      }
    };
    return await API.post(apiName, path, myInit);
  }

  async CheckRunningPowerBIRefresh() {
    try {
      console.log('Checking on Power BI Refresh');
      const apiName = 'fdaguiapi';
      const path = '/gpto/refreshpowerbi'; 
      const myInit = { };
      const response = await API.get(apiName, path, myInit)
      console.log(response);
      if (response['statusCode'] != 200 || response['body']['status'] == 'Failed') {
        return null;
      }
      else if (response['body']['status'] == 'Completed') {
        this.current_month = null;
        return false;
      }
      else {
        this.current_month = response['currentMonth']
        return true;
      }
    }
    catch(ex) {
      console.log('Error');
      console.log(ex);
      return null;
    }
  }

  async CheckAndWaitForRunningPowerBIRefresh() {
    try {
      const interval = 30000;
      const timeout = 360;
      console.log('waiting for powerbi refresh to finish');
      console.log('Starting interval waiting for actively running');
      console.log(`interval - ${interval}`);
      console.log(`timeout - ${timeout}`);

      for (var i = 0; i < timeout; i++) {
        await new Promise(r => setTimeout(r, interval));
        var response = await this.CheckRunningPowerBIRefresh();
        console.log(response);
        //Error response
        if (response == null) {
          return null;
        } 
        //Complete found return true
        else if (response == false) {
          i = timeout + 1;
          return true;
        }
      }
      //Timeout return false
      return false;
    }
    catch(ex) {
      console.log('Error');
      console.log(ex);
      return null;
    }
  }

  async CallPreRefresh() {
    console.log('Kicking off powerbi pre refresh');
    const apiName = 'fdaguiapi';
    const path = '/gpto/prerefresh'; 
    const myInit = {
      body: {

      }
    };
    const response = await API.post(apiName, path, myInit)
    console.log(response);
    return response
  }

}
