//Angular
import { Component, OnInit } from '@angular/core';

//Amplify
import { CognitoApiService } from 'src/app/services/cognito-api/cognito-api.service';

//PrimeNG
import { MenuItem } from 'primeng/api';

//Services
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  //Menu items
  items: MenuItem[] = [];

  //Current user
  user: any;

  constructor(
    private cognitoApiService: CognitoApiService
    , public errorHandlerService: ErrorHandlerService
  ) {
  }


  ngOnInit(): void {
    this.cognitoApiService.checkUserAdminAccess().then(hasAdminAccess => {

      //Get user first and last name for banner
      this.cognitoApiService.retrieveUserFirstNameLastName().then(response => {
        this.user = response;
      });

      //Set menu options
      this.items = [
        {
          label: 'FDA Manual Load',
          items: [
            {
              label: 'Bank Balance',
              icon: 'pi pi-fw pi-upload',
              routerLink: 'fda-manual-load/manual-file-upload/bank-balance'
            },
            {
              label: 'Reprocess Mapping Rules',
              icon: 'pi pi-fw pi-align-justify',
              routerLink: 'fda-manual-load/reprocess-mapping-rules'
            }
          ]
        },
        {
          label: 'Blackline',
          items: [
            {
              label: 'Load',
              icon: 'pi pi-fw pi-forward',
              routerLink: 'blackline/load'
            }
          ]
        },
        {
          label: 'DPSR',
          items: [
            {
              label: 'DPSR - Manual File Upload',
              icon: 'pi pi-fw pi-upload',
              routerLink: 'dpsr/manual-file-upload/dpsr'
            }
          ]
        },
        {
          label: 'FACTRE',
          items: [
            {
              label: 'Movement Details - Manual File Upload',
              icon: 'pi pi-fw pi-upload',
              routerLink: 'factre/manual-file-upload/gmcmovementdetails'
            }
          ]
        },
        {
          label: 'GPTO',
          items: [
            {
              label: 'Summary',
              routerLink: 'gpto/summary'
            },
            {
              label: 'Power BI Refresh',
              routerLink: 'gpto/powerbi-refresh'
            }
          ]
        },
        {
          label: 'KII Data Platform',
          items: [
            {
              label: 'KII Trial Balance',
              icon: 'pi pi-fw pi-list',
              routerLink: 'kii-data-platform/kii-trial-balance'
            },
            {
              label: 'KII Trial Balance - Manual File Upload',
              icon: 'pi pi-fw pi-upload',
              routerLink: 'kii-data-platform/manual-file-upload/trial-balance'
            }
          ]
        },
        {
          label: 'STORM',
          items: [
            {
              label: 'STORM - Manual File Upload',
              icon: 'pi pi-fw pi-upload',
              routerLink: 'storm/manual-file-upload/storm'
            }
          ]
        },
        {
          label: 'Process Status',
          routerLink: 'process-status'
        }
      ];
    })
  }
}
