//Angular
import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

//Interfaces
import { BlacklineLoadGridList } from 'src/app/interfaces/blackline-load-grid-list';
import { BlacklineDataBankBalance } from 'src/app/interfaces/blackline-data-bank-balance';
import { BlacklineDataFxRates } from 'src/app/interfaces/blackline-data-fx-rates';
import { BlacklineDataSlBal } from 'src/app/interfaces/blackline-data-sl-bal';
import { BlacklineDataGlBal } from 'src/app/interfaces/blackline-data-gl-bal';

//Services
import { BlacklineLoadDataService } from 'src/app/services/blackline-load-data/blackline-load-data.service';
import { BlacklineLoadFileService } from 'src/app/services/blackline-load-file/blackline-load-file.service';
import { ActiveProcessesService } from 'src/app/services/active-processes/active-processes.service';
import { ProcessStatusService } from 'src/app/services/process-status/process-status.service';
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';

//AG Grid
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode, StatusPanelDef } from 'ag-grid-community';


//PrimeNg
import { ConfirmationService } from 'primeng/api';

//Components
import { BlacklineLoadGridSummaryBarCurrencyQuantityAmountComponent } from '../blackline-load-grid-summary-bar-currency-quantity-amount/blackline-load-grid-summary-bar-currency-quantity-amount.component';
import { BlacklineLoadGridSummaryBarCurrencyAmountComponent } from '../blackline-load-grid-summary-bar-currency-amount/blackline-load-grid-summary-bar-currency-amount.component';

@Component({
  selector: 'app-blackline-load-grid',
  templateUrl: './blackline-load-grid.component.html',
  styleUrls: ['./blackline-load-grid.component.scss'],
  providers: [ConfirmationService],
})
export class BlacklineLoadGridComponent implements OnInit {

  activelyRunningMessage: string = '';
  showBlockingActivelyProcessingMessage: boolean = false;

  tooltipPosition = "top";

  //Pagination
  currentPage: number = 1;
  lowestPage: number = 1;
  highestPage: number = 1;
  pageSize: number = 10;
  pageSizeOptions = [
    '1000',
    '100',
    '10'
  ]

  public rowSelection = 'multiple';

  //USD amount, currency amount and currency quantity amount for summary information
  numUsdAmount = "0";
  numCurrencyAmount = "0";
  numCurrencyQuantityAmount = "0";

  showCurrencyAmountSumValues = false;

  //File label for header
  fileLabel: string = '';

  //List of files to load, and number for the index of the current file
  currentFile: number = 0;
  fileList: string[] = [];

  //Booleans for showing components
  showBankBalance: boolean = false;
  showFxRates: boolean = false;
  showSlBal: boolean = false;
  showGlBal: boolean = false;
  showfinishScreen: boolean = false;
  
  //Input list for which files to load
  @Input() inputList: BlacklineLoadGridList = {
    doBankBalance: false,
    doFxRates: false,
    doGLBalance: false,
    doSLBalance: false
  };

  //Reprocessing date to load
  @Input() selectedReprocessDate: string = '';


  //Output function to close the dialog window
  @Output() closeWindow = new EventEmitter();
  
  //Grid default settings
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    headerCheckboxSelectionFilteredOnly: true,
  };

  /*
  =================
    Bank balance
  =================
  */

  bankBalanceData!: BlacklineDataBankBalance[];
  bankBalanceGridOptions: GridOptions = {};
  bankBalanceGridApi!: GridApi;
  bankBalanceColumnApi!: ColumnApi;

  blacklineBankBalanceColDef: ColDef[]= [
    { field: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    floatingFilter: false},
    { field: 'mele',
    tooltipField: 'mele'},
    { field: 'account',
    tooltipField: 'account' },
    { field: 'ktb',
    tooltipField: 'ktb',
    headerName: 'KTB' },
    { field: 'legalEntity',
    tooltipField: 'legalEntity' },
    { field: 'dataType',
    tooltipField: 'dataType' },
    { field: 'location',
    tooltipField: 'location' },
    { field: 'commodity',
    tooltipField: 'commodity' },
    { field: 'locationType',
    tooltipField: 'locationType' },
    { field: 'motIcp',
    tooltipField: 'motIcp',
    headerName: 'MOT ICP' },
    { field: 'currency',
    tooltipField: 'currency' },
    { field: 'date',
    tooltipField: 'date' },
    { field: 'usdAmount',
    tooltipField: 'usdAmount',
    filter: 'agNumberColumnFilter',
    headerName: 'USD Amount'},
    { field: 'field1',
    tooltipField: 'field1' },
    { field: 'currencyAmount',
    tooltipField: 'currencyAmount',
    filter: 'agNumberColumnFilter', },
    { field: 'bankAccountNumber',
    tooltipField: 'bankAccountNumber' }
  ];

  /*
  =============
    FX Rates
  =============
  */

  fxRatesData!: BlacklineDataFxRates[];
  fxRatesGridOptions: GridOptions = {};
  fxRatesGridApi!: GridApi;
  fxRatesColumnApi!: ColumnApi;

  fxRatesColDef: ColDef[] = [
    { field: 'currency',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    tooltipField: 'currency'},
    { field: 'conversionMethod',
    tooltipField: 'conversionMethod' },
    { field: 'rate',
    tooltipField: 'rate' },
    { field: 'convertTo',
    tooltipField: 'convertTo' },
    { field: 'date',
    tooltipField: 'date' },
    { field: 'period',
    tooltipField: 'period' },
  ];


  /*
  ==============
    SL Balance
  ==============
  */

  slBalData!: BlacklineDataSlBal[];
  slBalGridOptions: GridOptions = {};
  slBalGridApi!: GridApi;
  slBalColumnApi!: ColumnApi;

  slBalColDef: ColDef[] = [
    { field: 'mele',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    tooltipField: 'mele'},
    { field: 'account',
    tooltipField: 'account' },
    { field: 'ktb',
    tooltipField: 'ktb' },
    { field: 'legalEntity',
    tooltipField: 'legalEntity' },
    { field: 'dataType',
    tooltipField: 'dataType' },
    { field: 'location',
    tooltipField: 'location' },
    { field: 'commodity',
    tooltipField: 'commodity' },
    { field: 'locationType',
    tooltipField: 'locationType' },
    { field: 'motIcp',
    tooltipField: 'motIcp' },
    { field: 'currency',
    tooltipField: 'currency' },
    { field: 'date',
    tooltipField: 'date' },
    { field: 'usdAmount',
    tooltipField: 'usdAmount'},
    { field: 'field_1',
    tooltipField: 'field_1' },
    { field: 'currencyAmount',
    tooltipField: 'currencyAmount' },
    { field: 'sourceSystem',
    tooltipField: 'sourceSystem' },
    { field: 'rate',
    tooltipField: 'rate' },
  ];

  /*
  ==============
    GL Balance
  ==============
  */

  glBalData!: BlacklineDataGlBal[];
  glBalGridOptions: GridOptions = {};
  glBalGridApi!: GridApi;
  glBalColumnApi!: ColumnApi;

  glBalColDef: ColDef[] = [
    { field: 'mele',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    tooltipField: 'mele'},
    { field: 'account',
    tooltipField: 'account' },
    { field: 'ktb',
    tooltipField: 'ktb' },
    { field: 'legalEntity',
    tooltipField: 'legalEntity' },
    { field: 'datatype',
    tooltipField: 'datatype' },
    { field: 'location',
    tooltipField: 'location' },
    { field: 'commodity',
    tooltipField: 'commodity' },
    { field: 'locationType',
    tooltipField: 'locationType' },
    { field: 'motIcp',
    tooltipField: 'motIcp' },
    { field: 'currency1',
    tooltipField: 'currency1' },
    { field: 'accountDescription',
    tooltipField: 'accountDescription' },
    { field: 'reconciliationType',
    tooltipField: 'reconciliationType' },
    { field: 'financialStatement',
    tooltipField: 'financialStatement' },
    { field: 'type',
    tooltipField: 'type' },
    { field: 'active',
    tooltipField: 'active' },
    { field: 'activity',
    tooltipField: 'activity' },
    { field: 'field6',
    tooltipField: 'field6' },
    { field: 'currency2',
    tooltipField: 'currency2' },
    { field: 'date',
    tooltipField: 'date' },
    { field: 'usdAmount',
    tooltipField: 'usdAmount' },
    { field: 'field7',
    tooltipField: 'field7' },
    { field: 'currencyQuantityAmount',
    tooltipField: 'currencyQuantityAmount' },
    { field: 'sourceSystem',
    tooltipField: 'sourceSystem' },
  ]

  //Loading template to pull up while data is loading.
  public overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Loading file data</span>';

  //Status bar for currency amount sum
  public statusBarCurrencyAmount: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'center' },
        { statusPanel: BlacklineLoadGridSummaryBarCurrencyAmountComponent }
    ],
  };

  //Status bar with no sum
  public statusBarNoSum: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'center' }
    ],
  };

  //Status bar for currency quantity amount sum
  public sideBarCurrencyQuantityAmount: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
      { statusPanel: 'agSelectedRowCountComponent', align: 'center' },
      {statusPanel: BlacklineLoadGridSummaryBarCurrencyQuantityAmountComponent },
    ],
  };

  constructor(
    public blacklineLoadDataService: BlacklineLoadDataService, 
    private blacklineLoadFileService: BlacklineLoadFileService,
    public activeProcessesService: ActiveProcessesService,
    private confirmationService: ConfirmationService,
    private processStatusService: ProcessStatusService,
    private errorHandlerService: ErrorHandlerService
    ) { }

  ngOnInit(): void {
    console.log(this.selectedReprocessDate);

    this.fileList = this.CreateFileList(this.inputList);

    this.DisplayPassedInFile(this.get_current_file());

    this.RetrieveData();
  }

  /*
  ===============
  BANK BALANCE
  ===============
  */

  RetrieveBankBalanceData() {
    this.blacklineLoadDataService.retrieveBlacklineBankBalanceData(this.selectedReprocessDate).then(response => {
      this.bankBalanceData = response;
      this.bankBalanceGridOptions.api?.setRowData(this.bankBalanceData);
      if (this.bankBalanceGridApi != null) {
        this.bankBalanceGridApi.hideOverlay();
        this.bankBalanceColumnApi.autoSizeAllColumns();
        this.GetPaginationValues();
      }
    })
  }

  OnGridReadyBankBalance(params: GridReadyEvent) {
    this.bankBalanceGridApi = params.api;
    this.bankBalanceColumnApi = params.columnApi;
    if (this.bankBalanceData == null) {
      this.bankBalanceGridApi.showLoadingOverlay();
    }
    this.bankBalanceGridApi.addEventListener('modelUpdated', this.updateTotalsBankBalance.bind(this));
    this.bankBalanceGridApi.addEventListener('filterModified', this.updateTotalsBankBalance.bind(this));
    this.bankBalanceGridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues()
    this.SortData();
  }

  SortData() {
    var file = this.get_current_file();
    if (file == 'fxRates') {
      this.fxRatesColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
      this.fxRatesColumnApi.applyColumnState({
        state: [
          { colId: 'mele', sort: 'asc', sortIndex: 0 },
          { colId: 'account', sort: 'asc', sortIndex: 0 }
        ]
      })
    }
    else if (file == 'bankBalance') {
      this.bankBalanceColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
      this.bankBalanceColumnApi.applyColumnState({
        state: [
          { colId: 'mele', sort: 'asc', sortIndex: 0 },
          { colId: 'account', sort: 'asc', sortIndex: 0 }
        ]
      })
    }
    else if (file == 'slBal') {
      this.slBalColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
      this.slBalColumnApi.applyColumnState({
        state: [
          { colId: 'mele', sort: 'asc', sortIndex: 0 },
          { colId: 'account', sort: 'asc', sortIndex: 0 }
        ]
      })
    }
    else if (file == 'glBal') {
      this.glBalColumnApi.applyColumnState({
        defaultState: { sort: null },
      });
      this.glBalColumnApi.applyColumnState({
        state: [
          { colId: 'mele', sort: 'asc', sortIndex: 0 },
          { colId: 'account', sort: 'asc', sortIndex: 0 }
        ]
      })
    }
  }

  updateTotalsBankBalance() {
    let numUsdAmount = 0, numCurrencyAmount = 0;

    this.bankBalanceGridApi.forEachNodeAfterFilter((rowNode:RowNode) => {
        const data = rowNode.data;
        if (data.usdAmount) numUsdAmount += Number(data.usdAmount);
        if (data.currencyAmount) numCurrencyAmount += Number(data.currencyAmount);
    }); 
    this.numUsdAmount = (Math.round(numUsdAmount * 100) / 100).toLocaleString();
    this.numCurrencyAmount = (Math.round(numCurrencyAmount * 100) / 100).toLocaleString();
  }

  /*
  ============
  FX RATES
  ============
  */

  async RetrieveFxRatesData() {
    const response = await this.blacklineLoadDataService.retrieveBlacklineFxRatesData(this.selectedReprocessDate)
    if (response != null) {
      this.fxRatesData = response;
      console.log(response);
      this.fxRatesGridOptions.api?.setRowData(this.fxRatesData);
      if (this.fxRatesGridApi != null) {
      this.fxRatesGridApi.hideOverlay();
      this.fxRatesColumnApi.autoSizeAllColumns();
      this.GetPaginationValues()
      }
    }
  }

  OnGridReadyFxRates(params: GridReadyEvent) {
    this.fxRatesGridApi = params.api;
    this.fxRatesColumnApi = params.columnApi;
    if (this.fxRatesData == null) {
      this.fxRatesGridOptions.overlayLoadingTemplate = this.overlayLoadingTemplate;
      this.fxRatesGridApi.showLoadingOverlay();
    }
    this.fxRatesGridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues();
  }

  /*
  ============
  SL BALANCE
  ============
  */

  async RetrieveSlBalData() {
    const response = await this.blacklineLoadDataService.retrieveBlacklineSlBalData(this.selectedReprocessDate)
    if (response != null) {
      console.log(response);
      this.slBalData = response;
      this.slBalGridOptions.api?.setRowData(this.slBalData);
      if (this.slBalGridApi != null) {
        this.slBalGridApi.hideOverlay();
        this.slBalColumnApi.autoSizeAllColumns();
      }
      this.GetPaginationValues()
    }
  }
  

  OnGridReadySlBal(params: GridReadyEvent) {
    this.slBalGridApi = params.api;
    this.slBalColumnApi = params.columnApi;
    if (this.slBalData == null) {
      this.slBalGridApi.showLoadingOverlay();
    }
    this.slBalGridApi.addEventListener('modelUpdated', this.updateTotalsSlBalance.bind(this));
    this.slBalGridApi.addEventListener('filterModified', this.updateTotalsSlBalance.bind(this));
    this.slBalGridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues();
    this.SortData();
  }
  
  updateTotalsSlBalance() {
    let numUsdAmount = 0, numCurrencyAmount = 0;

    this.slBalGridApi.forEachNodeAfterFilter((rowNode:RowNode) => {
        const data = rowNode.data;
        if (data.usdAmount) numUsdAmount += Number(data.usdAmount);
        if (data.currencyAmount) numCurrencyAmount += Number(data.currencyAmount);
    }); 
    this.numUsdAmount = (Math.round(numUsdAmount * 100) / 100).toLocaleString();
    this.numCurrencyAmount = (Math.round(numCurrencyAmount * 100) / 100).toLocaleString();
  }

  /*
  ============
  GL BALANCE
  ============
  */
  async RetrieveGlBalData() {
    const response = await this.blacklineLoadDataService.retrieveBlacklineGlBalData(this.selectedReprocessDate);
      console.log(response);
      if (response != null) {
        this.glBalData = response;
        this.glBalGridOptions.api?.setRowData(this.glBalData);
        if (this.glBalGridApi != null) {
          this.glBalGridApi.hideOverlay();
          this.glBalColumnApi.autoSizeAllColumns();
          this.GetPaginationValues();
        }
    }
  }

  OnGridReadyGlBal(params: GridReadyEvent) {
    this.glBalGridApi = params.api;
    this.glBalColumnApi = params.columnApi;
    if (this.glBalData == null) {
      this.glBalGridApi.showLoadingOverlay();
    }
    this.glBalGridApi.addEventListener('modelUpdated', this.updateTotalsGLBalance.bind(this));
    this.glBalGridApi.addEventListener('filterModified', this.updateTotalsGLBalance.bind(this));
    this.glBalGridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues()
    this.SortData();
  }

  updateTotalsGLBalance() {
    let numUsdAmount = 0, numCurrencyQuantityAmount = 0;

    this.glBalGridApi.forEachNodeAfterFilter((rowNode:RowNode) => {
        const data = rowNode.data;
        if (data.usdAmount) numUsdAmount += Number(data.usdAmount);
        if (data.currencyQuantityAmount) numCurrencyQuantityAmount += Number(data.currencyQuantityAmount);
    }); 
    this.numUsdAmount = (Math.round(numUsdAmount * 100) / 100).toLocaleString();
    this.numCurrencyQuantityAmount = (Math.round(numCurrencyQuantityAmount * 100) / 100).toLocaleString();
  }

  async RetrieveData() {
    console.log('getting data');
    const fileInput = this.get_current_file();
    const fileCreationState = 'File Creation';
    const fileReviewState = 'Data Review';
    const initialState = 'In Process';
    const finalState = 'Success';
    const failedState = 'Failed';
    await this.processStatusService.LogBlacklineLoad(this.fileLabel, fileCreationState, initialState, this.selectedReprocessDate, true);

    this.UpdateActivelyRunningMessage('checking');
    var processBlockingCheck: boolean | null = false;
    var processBlockingWait: boolean | null = false;
    processBlockingCheck = await this.activeProcessesService.CheckForActivelyRunning(fileInput);

    if (processBlockingCheck == false) {
      this.UpdateActivelyRunningMessage('blocking');
      processBlockingWait = await this.activeProcessesService.CheckAndWaitForActivelyRunning(fileInput);
      if (processBlockingWait == false) {
        this.UpdateActivelyRunningMessage('hidden');
      }
    }
    
    if (processBlockingCheck == true || processBlockingWait == true) {

      this.UpdateActivelyRunningMessage('priming');

      if (fileInput == 'fxRates') {
        await this.RetrieveFxRatesData();
      }
      else if (fileInput == 'bankBalance') {
        this.RetrieveBankBalanceData();
      }
      else if (fileInput == 'slBal') {
        await this.RetrieveSlBalData();
      }
      else if (fileInput == 'glBal') {
        await this.RetrieveGlBalData();
      }
      this.UpdateActivelyRunningMessage('hidden');
      await this.processStatusService.LogBlacklineLoad(this.fileLabel, fileCreationState, finalState, this.selectedReprocessDate, false);
      await this.processStatusService.LogBlacklineLoad(this.fileLabel, fileReviewState, initialState, this.selectedReprocessDate, false);
    }
    else {
      await this.processStatusService.LogBlacklineLoad(this.fileLabel, fileCreationState, failedState, this.selectedReprocessDate, false, 'Error while checking actively running');
      this.MoveToNextScreen();
    }
  }

  MoveToNextScreen() {
    this.currentFile += 1;
    this.DisplayPassedInFile(this.get_current_file());
    if (this.fileList[this.currentFile] != 'finish') {
      this.RetrieveData();
    }
  }

  ShowFile(showBankBalance: boolean, showFxRates: boolean, showSlBal: boolean, showGlBal: boolean, showfinishScreen: boolean) {
    this.showBankBalance = showBankBalance;
    this.showFxRates = showFxRates;
    this.showSlBal = showSlBal;
    this.showGlBal = showGlBal;
    this.showfinishScreen = showfinishScreen;
    this.UpdateFileLabel();
  }

  DisplayPassedInFile(file: string) {
    if (file == 'bankBalance') {
      this.ShowFile(true, false, false, false, false);
    }
    else if (file == 'fxRates') {
      this.ShowFile(false, true, false, false, false);
    }
    else if (file == 'slBal') {
      this.ShowFile(false, false, true, false, false);
    }
    else if (file == 'glBal') {
      this.ShowFile(false, false, false, true, false);
    }
    else if (file == 'finish') {
      this.ShowFile(false, false, false, false, true);
    }
  }

  CreateFileList(inputList: BlacklineLoadGridList): string[] {
    var list: string[] = [];
    if (inputList.doFxRates) {
      list.push('fxRates');
    }
    if (inputList.doBankBalance) {
      list.push('bankBalance');
    }
    if (inputList.doSLBalance) {
      list.push('slBal');
    }
    if (inputList.doGLBalance) {
      list.push('glBal');
    }
    list.push('finish');
    return list;
  }

  GenerateBlacklineFile() {
    this.confirmationService.confirm({
      message: `Are you sure you would like to generate and upload the ${this.fileLabel} file`,
      accept: async () => {
        var file = this.get_current_file();
        var fileName = this.fileLabel
        this.MoveToNextScreen();
        await this.processStatusService.LogBlacklineLoad(fileName, 'Data Review', 'Success', this.selectedReprocessDate, false);
        await this.processStatusService.LogBlacklineLoad(fileName, 'Data Load', 'In Process', this.selectedReprocessDate, false);
    
        if (file == 'fxRates') {
          this.blacklineLoadFileService.GenerateAndUploadFxRatesFile(this.fxRatesData, this.selectedReprocessDate);
        }
        else if (file == 'bankBalance') {
          this.blacklineLoadFileService.GenerateAndUploadBankBalanceFile(this.bankBalanceData, this.selectedReprocessDate);
        }
        else if (file == 'slBal') {
          this.blacklineLoadFileService.GenerateAndUploadSlBalFile(this.slBalData, this.selectedReprocessDate);
        }
        else if (file == 'glBal') {
          this.blacklineLoadFileService.GenerateAndUploadGlBalFile(this.glBalData, this.selectedReprocessDate);
        }
        await this.processStatusService.LogBlacklineLoad(fileName, 'Data Load', 'Success', this.selectedReprocessDate, false);
      }
    })
  }

  RejectFile() {
    this.confirmationService.confirm({
      message: `Are you sure you would like to move on to the next file`,
      accept: () => {
        this.LogFileReject(this.fileLabel);
        this.MoveToNextScreen();
      }
    })
  }

  CloseGrid() {
    this.closeWindow.emit();
  }

  RefreshData() {
    var file = this.get_current_file();
    if (file == 'fxRates') {
      this.fxRatesGridApi.showLoadingOverlay();
    }
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.showLoadingOverlay();
    }
    if (file == 'slBal') {
      this.slBalGridApi.showLoadingOverlay();
    }
    if (file == 'glBal') {
      this.glBalGridApi.showLoadingOverlay();
    }
    this.RetrieveData();
  }

  get_current_file() {
    return this.fileList[this.currentFile];
  }

  UpdateFileLabel() {
    var file = this.get_current_file();
    if (file == 'fxRates') {
      this.fileLabel = 'FX Rates';
    }
    else if (file == 'bankBalance') {
      this.fileLabel = 'Bank Balance';
    }
    else if (file == 'slBal') {
      this.fileLabel = 'Subledger Balances';
    }
    else if (file == 'glBal') {
      this.fileLabel = 'GL Balances';
    }
  }

  clearFilters() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.setFilterModel(null);
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.setFilterModel(null);
    }
    else if (file == 'slBal') {
      this.slBalGridApi.setFilterModel(null);
    }
    else if (file == 'glBal') {
      this.glBalGridApi.setFilterModel(null);
    }
  }

  clearSelection() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.deselectAll();
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.deselectAll();
    }
    else if (file == 'slBal') {
      this.slBalGridApi.deselectAll();
    }
    else if (file == 'glBal') {
      this.glBalGridApi.deselectAll();
    }
  }

  FirstPage() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.paginationGoToFirstPage();
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.paginationGoToFirstPage();
    }
    else if (file == 'slBal') {
      this.slBalGridApi.paginationGoToFirstPage();
    }
    else if (file == 'glBal') {
      this.glBalGridApi.paginationGoToFirstPage();
    }
    this.SetCurrentPage()
  }

  NextPage() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.paginationGoToNextPage();
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.paginationGoToNextPage();
    }
    else if (file == 'slBal') {
      this.slBalGridApi.paginationGoToNextPage();
    }
    else if (file == 'glBal') {
      this.glBalGridApi.paginationGoToNextPage();
    }
    this.SetCurrentPage()
  }

  PreviousPage() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.paginationGoToPreviousPage();
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.paginationGoToPreviousPage();
    }
    else if (file == 'slBal') {
      this.slBalGridApi.paginationGoToPreviousPage();
    }
    else if (file == 'glBal') {
      this.glBalGridApi.paginationGoToPreviousPage();
    }
    this.SetCurrentPage()
  }

  GetPaginationValues() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.highestPage = this.bankBalanceGridApi.paginationGetTotalPages();
    }
    else if (file == 'fxRates') {
      this.highestPage = this.fxRatesGridApi.paginationGetTotalPages();
    }
    else if (file == 'slBal') {
      this.highestPage = this.slBalGridApi.paginationGetTotalPages();
    }
    else if (file == 'glBal') {
      this.highestPage = this.glBalGridApi.paginationGetTotalPages();
    }
    this.SetCurrentPage()
  }

  UpdatePageSize() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.bankBalanceGridApi.paginationSetPageSize(this.pageSize);
    }
    else if (file == 'fxRates') {
      this.fxRatesGridApi.paginationSetPageSize(this.pageSize);
    }
    else if (file == 'slBal') {
      this.slBalGridApi.paginationSetPageSize(this.pageSize);
    }
    else if (file == 'glBal') {
      this.glBalGridApi.paginationSetPageSize(this.pageSize);
    }
    this.GetPaginationValues();
  }

  SetCurrentPage() {
    var file = this.get_current_file();
    if (file == 'bankBalance') {
      this.currentPage = this.bankBalanceGridApi.paginationGetCurrentPage() + 1;
    }
    else if (file == 'fxRates') {
      this.currentPage = this.fxRatesGridApi.paginationGetCurrentPage() + 1;
    }
    else if (file == 'slBal') {
      this.currentPage = this.slBalGridApi.paginationGetCurrentPage() + 1;
    }
    else if (file == 'glBal') {
      this.currentPage = this.glBalGridApi.paginationGetCurrentPage() + 1;
    }
  }

  async LogFileReject(fileName: string) {
    const fileState = 'Data Review';
    const State = 'Success, user rejected file';
    await this.processStatusService.LogBlacklineLoad(fileName, fileState, State, this.selectedReprocessDate, false);
  }

  UpdateActivelyRunningMessage(status: string) {
    if (status == 'checking') {
      this.activelyRunningMessage = 'Checking on blocking processes...';
      this.showBlockingActivelyProcessingMessage = true;
    }
    else if (status == 'blocking') {
      this.activelyRunningMessage = 'Blocking processes are running, waiting on them to finish...';
      this.showBlockingActivelyProcessingMessage = true;
    }
    else if (status == 'priming') {
      this.activelyRunningMessage = 'Waiting on data to prime...';
      this.showBlockingActivelyProcessingMessage = true;
    }
    else if (status == 'hidden') {
      this.showBlockingActivelyProcessingMessage = false;
    }
    else {
      console.error(`User error for function UpdateActivelyRunningMessage, status not found. Status - ${status}`);
    }
  }
}