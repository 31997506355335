import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Helper } from 'src/app/helper';
import { FileUploadTableHandlerService } from 'src/app/services/file-upload-table-handler/file-upload-table-handler.service';

@Component({
  selector: 'app-storm',
  templateUrl: './storm.component.html',
  styleUrls: ['./storm.component.scss']
})
export class StormComponent implements OnInit {
  stormSearchFormGroup!: FormGroup;
  stormSearchForm!: FormControl
  stormDataTypes!: Array<string>;
  selectedDataType!: any;
  filteredStormDataTypes!: Array<any>;
  matchFilesBy!: string;
  dropDownOptions!: Array<string>;
  matchByOptionsList!: Array<string>;
  periodList!: Array<string>;
  yearList!: Array<string>;

  @Output() fileTypeConfig = new EventEmitter<any>();
  @Output() fileValidator = new EventEmitter<Function>();
  @Output() seletionValidator = new EventEmitter<Function>();

  constructor(private fileUploadTableHandlerService: FileUploadTableHandlerService) { }

  ngOnInit(): void {
    this.fileValidator.emit(this.isValid);
    this.stormSearchForm = new FormControl();
    this.stormSearchFormGroup = new FormGroup({ stormSearch: this.stormSearchForm });

    let start_date = new Date(2016, 12, 1);
    let current_month = new Date().getMonth();
    let end_date = new Date(new Date().setMonth(current_month + (12 - current_month) + 23));

    this.periodList = [];
    this.yearList = [];
    for(let d = start_date; d < end_date; d = new Date(d.setMonth(d.getMonth() + 1)))
    {
      this.periodList.push(d.toISOString().split('-').slice(0, 2).join(''))
      let year = d.getFullYear().toString();
      if(this.yearList.indexOf(year) == -1) { this.yearList.push(year);}
    }

    this.fileUploadTableHandlerService.GetFileUploadTableRecords('storm').then((response: Array<any>) => {
      response.sort((a, b) => Helper.sortEntities(a, b, 'file_load_pk'))
      this.stormDataTypes = response.map(v => { v['display_name'] = v['file_load_pk'].toUpperCase().replace('_', ' '); return v; });
      this.filteredStormDataTypes = response;
      this.stormSearchForm.valueChanges.subscribe(val => {
        if (typeof (val) == 'string') {
          this.filteredStormDataTypes = this.stormDataTypes.filter(d => d['display_name'].toLowerCase().indexOf(val.toLowerCase()) > -1);
        }
        else if (val == undefined || !Helper.hasValue(val)) {
          this.filteredStormDataTypes = this.stormDataTypes;
          this.matchFilesBy = '';
          this.selectedDataType = null;
          this.onChange(null);
        }
      })
    })
  }

  showManualFileLoadSection(selected: any) {
    this.selectedDataType = selected;
    this.stormSearchForm.setValue(selected['display_name']);
    if (selected['match_files_by'].toUpperCase() == 'PERIOD') { this.dropDownOptions = [...this.periodList]; }
    else if (selected['match_files_by'].toUpperCase() == 'YEAR') { this.dropDownOptions = [...this.yearList]; }
    this.fileUploadTableHandlerService.GetTableInformation(selected['file_load_pk']).then(response => { this.fileTypeConfig.emit(response); });
  }

  clearSearch() { this.stormSearchForm.setValue(null); }

  filterOptions(val: any) {
    if (val && Helper.hasValue(val['query'])) { this.matchByOptionsList = this.dropDownOptions.filter(d => d.toLowerCase().indexOf(val['query']?.toLowerCase()) > -1); }
    else { this.matchByOptionsList = [...this.dropDownOptions]; }
  }

  clearOptions(event: any) { this.onChange(null) }

  onChange(event: any) {
    this.matchFilesBy = event;
    setTimeout(() => {
      this.seletionValidator.emit(() => {
        if (this.selectedDataType == null) { return "Please select a STORM file type to upload"; }
        else if (!Helper.hasValue(this.matchFilesBy)) { return `Please select a ${this.selectedDataType['match_files_by']} to match file by`; }
        return null;
      });
    }, 1000);
  }

  isValid = (index: number, value: string, columnName: string, addValidationError: Function) => {
    if (columnName == this.selectedDataType['match_files_by'] && value.trim() != this.matchFilesBy) {
      addValidationError(index, columnName, value, '', `'${value}', does not match selected ${columnName} - '${this.matchFilesBy}'`);
      return false;
    }
    else { return true; }
  }

}

