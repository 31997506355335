import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  errorContent: string = ''
  displayError: boolean = false;

  constructor() { }

  UpdateAndDisplayError(content: string) {
    this.errorContent = content;
    this.displayError = true;
  }
}
