<p-dialog header="Power BI Refresh" [(visible)]="displayCompleteDialog" [modal]="true">Power BI Refresh has completed.</p-dialog>
<div class="margin-left-right-20px">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">Power BI Refresh</span>
        </div>
        <div class="ui-g-12">
            <div class="margin-bottom-50px ui-tabview-panel-height">
            <span class="left margin-left-25px">
                <p-button label="Refresh Power BI Dataset" (onClick)="refreshPowerBiDataset()" [disabled]="buttonDisabled"></p-button>
                <div *ngIf="showRefreshSymbol">
                    <span class="font-bold-18-px">Waiting on Power BI Refresh...</span>
                    <app-refresh-spin></app-refresh-spin>
                    <div *ngIf="displayCurrentDate">
                        <br>
                        Running for date: {{powerBiDatasetService.current_month}}
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>