import { Component, OnInit } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IToolPanelParams, RowNode } from 'ag-grid-community';

@Component({
    selector: 'status-component',
    template: `
      <div class="ag-status-name-value">
        <span>Total USD Amount: </span>
        <span class="ag-status-name-value-value">{{ numUsdAmount }} </span>
        <span>Total Currency Quantity Amount: </span>
        <span class="ag-status-name-value-value">{{ numcurrencyQuantityAmount }}</span>
      </div>`
})
export class BlacklineLoadGridSummaryBarCurrencyQuantityAmountComponent implements IStatusPanelAngularComp {
  private params!: IToolPanelParams;

  public numUsdAmount: string = "0";
  public numcurrencyQuantityAmount: string = "0";

  agInit(params: IToolPanelParams): void {
    this.params = params;

    // calculate stats when new rows are selected
    this.params.api.addEventListener('selectionChanged', this.updateTotals.bind(this));
    }

  updateTotals(): void {
    let numUsdAmount = 0, numcurrencyQuantityAmount = 0;
    let selectedNodes = this.params.api.getSelectedNodes();
    for (var i = 0; i < selectedNodes.length; i++) {
        const data = selectedNodes[i].data;
        if (data.usdAmount) numUsdAmount += Number(data.usdAmount);
        if (data.currencyQuantityAmount) numcurrencyQuantityAmount += Number(data.currencyQuantityAmount);
    };
    this.numUsdAmount = (Math.round(numUsdAmount * 100) / 100).toLocaleString();
    this.numcurrencyQuantityAmount = (Math.round(numcurrencyQuantityAmount * 100) / 100).toLocaleString();
  }
}