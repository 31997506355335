import {Component} from "@angular/core";
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from "ag-grid-community";

@Component({
   selector: 'total-value-component',
   template: `
         <span *ngIf="cellValue">
            <i class="pi pi-pencil"></i>
         </span>
   `
})
export class GptoEditCellRenderer implements ICellRendererAngularComp {
    public cellValue: boolean = false;

   // gets called once before the renderer is used
   agInit(params: ICellRendererParams): void {
       this.cellValue = this.getValueToDisplay(params);
   }

   // gets called whenever the cell refreshes
   refresh(params: ICellRendererParams): boolean {
       // set value into cell again
       this.cellValue = this.getValueToDisplay(params);
       return true;
   }
   
   getValueToDisplay(params: ICellRendererParams) {
       return params.valueFormatted ? params.valueFormatted : params.value;
   }
}