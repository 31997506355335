<div class="ui-g" style="margin-left:25px">
    <div class="ui-g-12">
        <span class="font-bold-18-px">STORM Manual File Upload</span>
    </div>
    <div class="col-12 search-box">
        <div class="col-3 col-sm-12" [formGroup]="stormSearchFormGroup">
            <div class="p-inputgroup">
                <input pInputText placeholder="Select STORM file type to upload" formControlName="stormSearch" />
                <span class="p-inputgroup-addon" style="background-color: rgb(33, 145, 192);">
                    <button pButton type="button" icon="pi pi-times"
                        style="color: white; background-color: rgb(33, 145, 192); border: none;"
                        (click)="clearSearch()"></button>
                </span>
            </div>
        </div>
        <div *ngIf="selectedDataType" class="col-6 col-sm-12">
            <div class="col-2 col-offset-1"><span>Select
                    {{selectedDataType['match_files_by']}} </span></div>
            <div class="col-3 col-sm-12">
                <p-autoComplete class="match-filter"
                    [ngModel]="matchFilesBy"
                    [dropdown]="true"
                    [suggestions]="matchByOptionsList"
                    (completeMethod)="filterOptions($event)"
                    (ngModelChange)="onChange($event)"
                    [forceSelection]="true"
                    (onClear)="clearOptions($event)">
                </p-autoComplete>
            </div>
        </div>
    </div>
    <div class="col-12">
        <span *ngFor="let fileType of filteredStormDataTypes" style="margin-left:-10px">
            <p-button label="{{fileType['display_name']}}"
                (click)="showManualFileLoadSection(fileType)" class="list-buttons"></p-button>
        </span>
    </div>
</div>