<div class="margin-left-right-20px">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">Mapping Rules Reprocess</span>
        </div>
        <div class="ui-g-12">
            <div class="margin-bottom-50px ui-tabview-panel-height">
                <span class="left margin-left-25px">
                    <div>
                        <span>
                            Select which mapping rule you would like to re-load:<br><br>
                        </span>
                    </div>
                    <br><br>
                    <p-dropdown [options]="mappingRulesList" [(ngModel)]="mappingRuleToReprocess" optionLabel="name" class="p-dropdown-padded"></p-dropdown>
                    <br><br><br>
                    <p-button (onClick)="RefreshMappingRule()" label="Start Mapping Rules Reload" icon="pi pi-check-square" class="button-with-icon"></p-button>
                    <br><br><br>
                    <ng-container *ngIf="loadingMappingrule">
                        <span class="font-bold-18-px">
                            Loading mapping rule(s)...
                        </span>
                        <br>
                        <ng-container *ngIf="mappingRulesService.showRefreshIcon">
                            <app-refresh-spin></app-refresh-spin>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="mappingRuleComplete">
                        <span class="font-bold-18-px">
                            Mapping Rule Successfully refreshed
                        </span>
                    </ng-container>
                    <ng-container *ngIf="errorDuringMappingRuleLoad">
                        <span class="font-bold-18-px">
                            Error during mapping rules load, please reach out to IT.
                        </span>
                    </ng-container>
                </span>
            </div>
        </div>
    </div>
</div>