import { Injectable } from '@angular/core';

//Amplify
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class TrialBalanceService {

  constructor() { }

  async update_trial_balance_status(trialBalanceDataLayer: string, processStatus: string, trialBalancePeriodDate: string, trialBalanceSubmissionGroup: string, ) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/kiitrialbalancestatus'; 
    const myInit = { 
      body: { 
        trialBalanceDataLayer: trialBalanceDataLayer,
        processStatus: processStatus,
        trialBalancePeriodDate: trialBalancePeriodDate,
        trialBalanceSubmissionGroup: trialBalanceSubmissionGroup,
      },
    };
    console.log(myInit)
    return await API.post(apiName, path, myInit)
  }
}