//Amplify
import { API } from 'aws-amplify';

//Angular
import { Injectable } from '@angular/core';

//Interfaces
import { BlacklineDataFxRates } from 'src/app/interfaces/blackline-data-fx-rates';
import { BlacklineDataBankBalance } from 'src/app/interfaces/blackline-data-bank-balance';
import { BlacklineDataSlBal } from 'src/app/interfaces/blackline-data-sl-bal';
import { BlacklineDataGlBal } from 'src/app/interfaces/blackline-data-gl-bal';

//Services
import { BlacklineStorageHandlerService } from '../blackline-storage-handler/blackline-storage-handler.service';
import { ProcessStatusService } from '../process-status/process-status.service';

@Injectable({
  providedIn: 'root'
})
export class BlacklineLoadFileService {

  DELIMETER_CHARACTER: string = '\t';

  constructor(
    private blacklineStorageHandlerService: BlacklineStorageHandlerService
    ,private processStatusService: ProcessStatusService) { }


  GenerateFileStringFxRates(data: BlacklineDataFxRates[]): string {
    var dataString = '';
    for (var i = 0; i < data.length; i++) {
      dataString += data[i].currency + this.DELIMETER_CHARACTER;
      dataString += data[i].rate + this.DELIMETER_CHARACTER;
      dataString += data[i].date + this.DELIMETER_CHARACTER;
      dataString += data[i].conversionMethod + '\n';
    }
    return dataString;
  }

  async GenerateAndUploadFxRatesFile(data: BlacklineDataFxRates[], accountingPeriod: string) {
    console.log('Generating file string');
    var dataString = this.GenerateFileStringFxRates(data)
    console.log('Uploading File');
    const fileKey = await this.blacklineStorageHandlerService.uploadBlacklineFileToS3('fxRates', dataString);
    if (fileKey != null) {
      console.log('Uploading to SFTP');
      await this.UploadBlacklineFile('fxrates', fileKey, accountingPeriod);
    }
    console.log('Generating process status row');
  }

  GenerateFileStringBankBalance(data: BlacklineDataBankBalance[]): string {
    var dataString = '';
    for (var i = 0; i < data.length; i++) {
      dataString += data[i].mele + this.DELIMETER_CHARACTER;
      dataString += data[i].account + this.DELIMETER_CHARACTER;
      dataString += data[i].ktb + this.DELIMETER_CHARACTER;
      dataString += data[i].legalEntity + this.DELIMETER_CHARACTER;
      dataString += data[i].datatype + this.DELIMETER_CHARACTER;
      dataString += data[i].location + this.DELIMETER_CHARACTER;
      dataString += data[i].commodity + this.DELIMETER_CHARACTER;
      dataString += data[i].locationType + this.DELIMETER_CHARACTER;
      dataString += data[i].motIcp + this.DELIMETER_CHARACTER;
      dataString += data[i].currency + this.DELIMETER_CHARACTER;
      dataString += data[i].date + this.DELIMETER_CHARACTER;
      dataString += data[i].usdAmount + this.DELIMETER_CHARACTER;
      dataString += data[i].field1 + this.DELIMETER_CHARACTER;
      dataString += data[i].currencyAmount + '\n';
    }
    return dataString;
  }

  async GenerateAndUploadBankBalanceFile(data: BlacklineDataBankBalance[], accountingPeriod: string) {
    console.log('Generating file string');
    var dataString = this.GenerateFileStringBankBalance(data)
    console.log('Uploading File');
    const fileKey = await this.blacklineStorageHandlerService.uploadBlacklineFileToS3('bankBalance', dataString);
    if (fileKey != null) {
      console.log('Uploading to SFTP');
      await this.UploadBlacklineFile('bankBalance', fileKey, accountingPeriod);
    }
    console.log('Generating process status row');
  }

  GenerateFileStringSlBal(data: BlacklineDataSlBal[]): string {
    var dataString = '';
    for (var i = 0; i < data.length; i++) {
      dataString += data[i].mele + this.DELIMETER_CHARACTER;
      dataString += data[i].account + this.DELIMETER_CHARACTER;
      dataString += data[i].ktb + this.DELIMETER_CHARACTER;
      dataString += data[i].legalEntity + this.DELIMETER_CHARACTER;
      dataString += data[i].datatype + this.DELIMETER_CHARACTER;
      dataString += data[i].location + this.DELIMETER_CHARACTER;
      dataString += data[i].commodity + this.DELIMETER_CHARACTER;
      dataString += data[i].locationType + this.DELIMETER_CHARACTER;
      dataString += data[i].motIcp + this.DELIMETER_CHARACTER;
      dataString += data[i].currency + this.DELIMETER_CHARACTER;
      dataString += data[i].date + this.DELIMETER_CHARACTER;
      dataString += data[i].usdAmount + this.DELIMETER_CHARACTER;
      dataString += data[i].field_1 + this.DELIMETER_CHARACTER;
      dataString += data[i].currencyAmount + '\n';
    }
    return dataString;
  }

  async GenerateAndUploadSlBalFile(data: BlacklineDataSlBal[], accountingPeriod: string) {
    console.log('Generating file string');
    var dataString = this.GenerateFileStringSlBal(data)
    console.log('Uploading File');
    const fileKey = await this.blacklineStorageHandlerService.uploadBlacklineFileToS3('slBal', dataString);
    if (fileKey != null) {
      console.log('Uploading to SFTP');
      await this.UploadBlacklineFile('slBal', fileKey, accountingPeriod);
    }
    console.log('Generating and Creating process status row');
  }

  GenerateFileStringGlBal(data: BlacklineDataGlBal[]): string {
    var dataString = '';
    for (var i = 0; i < data.length; i++) {
      dataString += data[i].mele + this.DELIMETER_CHARACTER;
      dataString += data[i].account + this.DELIMETER_CHARACTER;
      dataString += data[i].ktb + this.DELIMETER_CHARACTER;
      dataString += data[i].legalEntity + this.DELIMETER_CHARACTER;
      dataString += data[i].datatype + this.DELIMETER_CHARACTER;
      dataString += data[i].location + this.DELIMETER_CHARACTER;
      dataString += data[i].commodity + this.DELIMETER_CHARACTER;
      dataString += data[i].locationType + this.DELIMETER_CHARACTER;
      dataString += data[i].motIcp + this.DELIMETER_CHARACTER;
      dataString += data[i].currency1 + this.DELIMETER_CHARACTER;
      dataString += data[i].accountDescription + this.DELIMETER_CHARACTER;
      dataString += data[i].reconciliationType + this.DELIMETER_CHARACTER;
      dataString += data[i].financialStatement + this.DELIMETER_CHARACTER;
      dataString += data[i].type + this.DELIMETER_CHARACTER;
      dataString += data[i].active + this.DELIMETER_CHARACTER;
      dataString += data[i].activity + this.DELIMETER_CHARACTER;
      dataString += data[i].field6 + this.DELIMETER_CHARACTER;
      dataString += data[i].currency2 + this.DELIMETER_CHARACTER;
      dataString += data[i].date + this.DELIMETER_CHARACTER;
      dataString += data[i].usdAmount + this.DELIMETER_CHARACTER;
      dataString += data[i].field7 + this.DELIMETER_CHARACTER;
      dataString += data[i].currencyQuantityAmount + '\n';
    }
    return dataString;
  }

  async GenerateAndUploadGlBalFile(data: BlacklineDataGlBal[], accountingPeriod: string) {
    console.log('Generating file string');
    var dataString = this.GenerateFileStringGlBal(data)
    console.log('Uploading File');
    const fileKey = await this.blacklineStorageHandlerService.uploadBlacklineFileToS3('glBal', dataString);
    if (fileKey != null) {
      console.log('Uploading to SFTP');
      await this.UploadBlacklineFile('glBal', fileKey, accountingPeriod);
    }
    console.log('Generating and Creating process status row');
  }

  GenerateDate() {
    var todayDate = new Date;
    var year = todayDate.getFullYear().toString();
    var month = todayDate.getMonth().toString().padStart(2, '0');
    var day = todayDate.getDay().toString().padStart(2, '0');
    var hour = todayDate.getHours().toString().padStart(2, '0');
    var minute = todayDate.getMinutes().toString().padStart(2, '0');
    var second = todayDate.getSeconds().toString().padStart(2, '0');
    var millisecond = todayDate.getMilliseconds().toString();
    var todayDateString = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + ':' + millisecond;
    return todayDateString;
  }

  async UploadBlacklineFile(fileName: string, bucketKey: string, accountingPeriod: string) {
    const apiName = 'fdaguiapi';
    const path = '/blackline/movefiletosftp'; 
    const myInit = { 
      body: { 
        blackline_file: fileName,
        bucket_key: bucketKey,
        accounting_period: accountingPeriod
      },
    };
    let response = await API.post(apiName, path, myInit);
    console.log(response);
    return response
  }
}
