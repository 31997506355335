<p-messages></p-messages>
<app-storm *ngIf="dataType == 'storm' || parentDataType == 'storm'"
    (fileTypeConfig)="setStormFileConfig($event)"
    (seletionValidator)="validateFileForType($event)"
    (fileValidator)="additionalFileValidator($event)">
</app-storm>
<div *ngIf="fileTypeConfig" class="ui-g margin-left-right-10px">
    <div class="ui-g-12">
        <span class="font-bold-18-px" style="margin-left:25px">Spreadsheet Upload for {{ fileTypeConfig['display_name'] ? fileTypeConfig['display_name'] : dataType.toUpperCase().replace('_', ' ')}}
            Files</span>
    </div>
    <!-- ============ -->
    <!-- Page Content -->
    <!-- ============ -->
    <div class="ui-g-12" style="margin-left:25px">
        <div style=" width: 50vw;">
            <span *ngIf="dataType == 'dpsr'">Please select DPSR year, period, and UD3 days first, then, e</span><span
                *ngIf="dataType != 'dpsr'">E</span>ither select 'choose' or drag spreadsheet file to the control to
            upload.
            <br />The file must match the <a *ngIf="dataType =='bank-balance'" download={{templateFileName}}
                href={{templateFileLocation}}>template
                file</a>
            <a *ngIf="dataType !='bank-balance'" href="javascript:void(0)" (click)="getCsvTemplate()">template
                file</a>
            to parse successfully.<br><br>
            <div *ngIf="dataType == 'dpsr'" style="margin-bottom: 10px">
                <span style="margin-right:10px;">Year</span>
                <span style="margin-right:30px;">
                    <p-calendar #year view="year" dateFormat="yy" placeholder="Select Year"
                        (onSelect)="setDpsrValidationValue(year, 'YEAR')"></p-calendar>
                </span>
                <span style="margin-right:10px;">Period</span>
                <span style="margin-right:30px;">
                    <p-calendar #month view="month" dateFormat="MM" placeholder="Select a Period"
                        (onSelect)="setDpsrValidationValue(month, 'PERIOD')"></p-calendar>
                </span>
                <span style="margin-right:10px;">UD3 Days</span>
                <span style="margin-right:30px;">
                    <p-dropdown [(ngModel)]="dpsr_validate.UD3_DAYS" [options]="ud3List" placeholder="Select UD3"
                        [showClear]="true" class="dpsrud3"
                        (onChange)="setDpsrValidationValue({'inputFieldValue': $event.value}, 'UD3_DAYS')"></p-dropdown>
                </span>
            </div>
            <p-fileUpload #form name=" myfile[]" [multiple]="true" [customUpload]="true" [disabled]="!showFileUploadBar"
                (uploadHandler)="UploadFile($event, form)">
                <ng-template let-file pTemplate="file">
                    <div class="p-fileupload-row ng-star-inserted">
                        <div class="p-fileupload-filename" style="width:50%">{{file.name}}</div>
                        <div>{{file.size | convertFileSize}}</div>
                        <div style="width:10%">
                            <button type="button" icon="pi pi-times" pbutton="" (click)="removeFile(file, form)"
                                class="p-element p-button p-component p-button-icon-only" ng-reflect-icon="pi pi-times">
                                <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                                <span class="p-button-icon pi pi-times" aria-hidden="true"></span>
                                <span aria-hidden="true" class="p-button-label">&nbsp;</span>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </p-fileUpload>
        </div>

        <ng-container *ngIf="dataType == 'bank-balance' && fileUploading">
            <br>
            <span class="font-bold-18-px">Uploading File...</span>
            <p class="file-list">file(s): {{filesUploaded}}</p>
            <ng-container *ngIf="waitingOnPipeline">
                <br>
                <span class="font-bold-18-px">Waiting on data pipeline to complete...</span>
                <app-refresh-spin></app-refresh-spin>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="dataType == 'bank-balance' && fileSuccessfullyUploaded">
            <br>
            <span class="font-bold-18-px" style="color: green;">File Successfully Uploaded</span>
            <p class="file-list">file(s): {{filesUploaded}}</p>
            <br>
        </ng-container>

        <ng-container *ngIf="dataType != 'bank-balance' && fileUploading">
            <br />
            <span *ngIf="fileUploadStatus.length == 0">Uploading ....</span>
            <span *ngIf="fileUploadStatus.length > 0" class="font-bold-18-px">Upload Status Report</span>
            <div class="col-10 md:col-10 sm:col-12">
                <div style="margin-top:20px">
                    <div class="grid" style="font-weight:bold; border-bottom: 1px solid black;">
                        <div class="col-2">File Name</div>
                        <div class="col-3">Process Id</div>
                        <div class="col-1">Upload Status</div>
                        <div class="col-1">File in Snowflake</div>
                        <div class="col-2">Load Timestamp</div>
                        <div class="col-3">Upload Status Message</div>
                    </div>
                    <div *ngFor="let status of fileUploadStatus" class="grid" style="border-bottom: 1px solid black;">
                        <div class="col-2" style="align-self: center;">{{status.fileName}}</div>
                        <div class="col-3" style="align-self: center;">{{status.processId}}</div>
                        <div class="col-1" style="align-self: center;"><p-progressSpinner
                                [style]="{width: '2em', height: '2em', 'margin-top': '.5em'}" *ngIf="status.inProcess"
                                strokeWidth="8"></p-progressSpinner><i *ngIf="!status.inProcess && status.inSnowflake"
                                class="pi pi-check" style="font-size: 2rem;color: green; margin-top: .3em;"></i>
                            <i *ngIf="!status.inProcess && !status.inSnowflake" class="pi pi-times"
                                style="font-size: 2rem;color: red; margin-top: .3em;"></i>
                        </div>
                        <div class="col-1" style="align-self: center;"><span *ngIf="!status.inSnowflake">No</span><span
                                *ngIf="status.inSnowflake">Yes</span>
                        </div>
                        <div class="col-2" style="align-self: center;">{{status.timestamp}}</div>
                        <div class="col-3" style="align-self: center;"><span>{{status.statusMessage}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="showCheckingActiveProcessesMessage">
            <div class="ui-g-12">
                <span class="font-bold-18-px">Checking on blocking processes...</span>
            </div>
        </ng-container>

        <ng-container *ngIf="showActiveProcessesBlockingMessage">
            <div class="ui-g-12">
                <span class="font-bold-18-px">Blocking processes are running, waiting on them to finish.</span>
                <br><br>
                <app-refresh-spin></app-refresh-spin>
            </div>
        </ng-container>

        <ng-container *ngIf="showActiveProcessesBlockingTimeoutMessage">
            <div class="ui-g-12">
                <span class="font-bold-18-px">Timeout while waiting for blocking processes</span>
                <br>
                <span class="font-bold-18-px">Please try again, or contact IT</span>
                <br><br>
                <ng-container *ngIf="showRefreshIcon">
                    <app-refresh-spin></app-refresh-spin>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="displayValidationIssues">
            <div style="width:95vw">
                <br>
                <p-button (click)="ClearValidationIssues()">Clear Validation Issues</p-button>
                <br><br>
                <span class="font-bold-18-px">Validation Errors</span>
                <p>The following errors were encountered in your upload, please fix the following errors, and
                    reupload your file(s).</p>
                <div *ngFor="let fileErrors of filesValidationResult"><br><br>
                    <p class="file-list">File Name: {{fileErrors.key}}</p>
                    <br>
                    <ag-grid-angular style="width: 100%;" domLayout="autoHeight" class="ag-theme-balham val-errors"
                        [rowData]="fileErrors.values" [columnDefs]="ValidationIssuesColDef"
                        [defaultColDef]="defaultColDef" (gridReady)="onValidationIssuesGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </ng-container>
    </div>
</div>