import {Component} from "@angular/core";
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams, RowNode} from "ag-grid-community";
import { GptoChangeHandlerService } from "src/app/services/gpto-change-handler/gpto-change-handler.service";

@Component({
   selector: 'total-value-component',
   template: `
         <span>
            <input type="checkbox" [ngModel]="cellValue" (ngModelChange)="onChange($event)" />
         </span>
   `
})
export class CustomCheckboxComponent implements ICellRendererAngularComp {
   public cellValue!: string;
   public params!: ICellRendererParams
   public node!: RowNode;

   constructor(
    private gptoChangeHandlerService: GptoChangeHandlerService
   ) {}

   // gets called once before the renderer is used
   agInit(params: ICellRendererParams): void {
        this.cellValue = params.value;
        this.params = params;
        this.node = params.node;
   }

   // gets called whenever the cell refreshes
   refresh(params: ICellRendererParams): boolean {
        // set value into cell again
        this.cellValue = params.value;
        return true;
   }

    onChange(event:any) {  this.params.setValue?.call(this, event);}

}