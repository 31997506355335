<div class="margin-left-right-20px">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">Blackline Load</span>
        </div>
        <ng-container *ngIf="showAccountingPeriodWaitMessage">
            <br>
            <div class="ui-g-12">
                <span class="font-bold-18-px">Loading Accounting Periods...</span>
            </div>
        </ng-container>
        <ng-container *ngIf="showReprocessFiles">
            <div class="ui-g-12">
                <div class="margin-bottom-50px ui-tabview-panel-height">
                <span class="left margin-left-25px">
                    <div>
                    <span>
                        Select blackline files to create:<br><br>
                        <div class="ui-g-12">
                        <table style=border:0 nowrap>
                            <tr>
                            <td width="65px"><p-inputSwitch [(ngModel)]="doAll" (onChange)="handleInputAllSwitchChange($event)"></p-inputSwitch></td>
                            <td>All</td>
                            </tr>
                            <tr>
                            <td width="65px"><p-inputSwitch [(ngModel)]="doFxRates"></p-inputSwitch></td>
                            <td>FX Rates</td>
                            </tr>
                            <tr>
                            <td width="65px"><p-inputSwitch [(ngModel)]="doSLBalance"></p-inputSwitch></td>
                            <td>Subledger Balances</td>
                            </tr>
                            <tr>
                            <td width="65px"><p-inputSwitch [(ngModel)]="doGLBalance"></p-inputSwitch></td>
                            <td>GL Balances</td>
                            </tr>
                            <tr>
                            <td width="65px"><p-inputSwitch [(ngModel)]="doBankBalance"></p-inputSwitch></td>
                            <td>Bank Balances</td>
                            </tr>
                        </table>
                        </div>
                        <br><br>
                        <span>Select Period to Load Files</span>
                        <br><br>
                        <table>
                            <tr>
                                <td>
                                <p-dropdown [options]="blacklineReprocessDates" [(ngModel)]="selectedReprocessDate" optionLabel="name" class="p-dropdown-padded"></p-dropdown>
                                </td>
                                <td align="center" width="25px">&nbsp;</td>
                                <td>
                                <p-button (onClick)="showGridWizard()" label="Trigger File Load" icon="pi pi-check-square" class="button-with-icon"></p-button>
                                </td>
                            </tr>
                        </table>
                    </span>
                    <ng-container *ngIf="showBlacklineGridWizard">
                        <p-dialog [(visible)]="showBlacklineGridWizard" [responsive]="true" showEffect="fade"
                        [modal]="true"  [closeOnEscape]=false [closable]=false>
                            <app-blackline-load-grid (closeWindow)="CloseDialog()" [inputList]="gridList" [selectedReprocessDate]="selectedReprocessDate.name"></app-blackline-load-grid>
                        </p-dialog>
                    </ng-container>
                    </div>
                </span>
                </div>
            </div>
        </ng-container>
    </div>
</div>