<div class="margin-left-right-20px">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">Process Status</span>
            <br><br>

            <div class="reprocess-and-refresh">
                <p-button label="ReProcess" [disabled]="!reProcessEnabled" (click)="reProcessFiles()"></p-button>
                <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded" (click)="RefreshData()"></button>
            </div>

            <!-- Clear, filter, and sort buttons -->
            <div class="clear-and-summary-section">
                <div>
                    <button (click)="clearFilters()" class="clear-buttons">Clear Filters</button>
                    <button (click)="clearSelection()" class="clear-buttons">Clear Selection</button>
                </div>
                <div>
                    <button (click)="SortData()" class="default-sort-button">Default Sort</button>
                </div> 
            </div>
        </div>
        <div class="ui-g-12">
            <ag-grid-angular
                style="width: 100%; height: 500px;"
                class="ag-theme-balham"
                [rowData]="processStatusRows"
                [columnDefs]="processStatusColDef"
                [defaultColDef]="defaultColDef"
                [gridOptions]="processStatusGridOptions"
                (gridReady)="OnGridReady($event)"
                [rowSelection]="rowSelection"
                [statusBar]="statusBar"
                [pagination]="true"
                [suppressPaginationPanel]="true"
                [overlayLoadingTemplate]="overlayLoadingTemplate"
            >
            </ag-grid-angular>
        </div>

        <div class="ui-g-12">
            <!-- Page Information and Selection -->
            <div class="page-information">
                <button class="page-information-components" (click)="FirstPage()">First</button>
                <button class="page-information-components" (click)="PreviousPage()">Previous</button>
                <span class="page-information-components">Current Page</span>
                <span class="value">{{currentPage}}</span>
                <span class="page-information-components">{{lowestPage}} to {{highestPage}}</span>
                <button class="page-information-components" (click)="NextPage()">Next</button>
                <span class="page-information-components">Page Size:</span>
                <p-dropdown [options]="pageSizeOptions" [(ngModel)]="pageSize" appendTo="body" (onChange)="UpdatePageSize()"></p-dropdown>
            </div>
        </div>
    </div>
</div>