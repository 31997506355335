<!-- ================================================= -->
<!-- Confirmation dialog for accept and reject buttons -->
<!-- ================================================= -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<!-- ====================== -->
<!-- Header section above grid -->
<!-- ====================== -->
<ng-container *ngIf="!showfinishScreen">

    <!-- Title and refresh button -->
    <div class="title-and-refresh">
        <span class="font-bold-18-px">{{fileLabel}} File</span>
        <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded" (click)="RefreshData()"></button>
    </div>

    <!-- Accept and reject buttons -->
    <div class="accept-reject">
        <p-button label="Reject" (onClick)="RejectFile()" class="accept-reject-buttons"></p-button>
        <p-button label="Accept and Load" (onClick)="GenerateBlacklineFile()" class="accept-reject-buttons"></p-button>
    </div>

    <!-- Clear buttons and summary information -->
    <div class="clear-and-summary-section">
        <div>
            <button (click)="clearFilters()" class="clear-buttons">Clear Filters</button>
            <button (click)="clearSelection()" class="clear-buttons">Clear Selection</button>
            <ng-container *ngIf="showBankBalance || showSlBal">
                Filtered Row(s) Total USD Amount: {{numUsdAmount}} | Total Currency Amount: {{numCurrencyAmount}}
            </ng-container>
            <ng-container *ngIf="showGlBal">
                Filtered Row(s) Total USD Amount: {{numUsdAmount}} | Total Currency Quantity Amount: {{numCurrencyQuantityAmount}}
            </ng-container>
        </div>
        <div>
            <button (click)="SortData()" class="default-sort-button">Default Sort</button>
        </div> 
    </div>

</ng-container>

<!-- ====================== -->
<!-- Grid section           -->
<!-- ====================== -->

<!-- Bank balance -->
<ng-container *ngIf="showBankBalance">
    <ag-grid-angular
        style="width: 1500px; height: 500px;"
        class="ag-theme-balham"
        [rowData]="bankBalanceData"
        [columnDefs]="blacklineBankBalanceColDef"
        [defaultColDef]="defaultColDef"
        [gridOptions]="bankBalanceGridOptions"
        [statusBar]="statusBarCurrencyAmount"
        (gridReady)="OnGridReadyBankBalance($event)"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [rowSelection]="rowSelection"
        [enableRangeSelection]="false"
        [pagination]="true"
        [suppressPaginationPanel]="true"
    >
    </ag-grid-angular>
    <br>
</ng-container>

<!-- FX rates -->
<ng-container *ngIf="showFxRates">
    <ag-grid-angular
        style="width: 1500px; height: 500px;"
        class="ag-theme-balham"
        [rowData]="fxRatesData"
        [columnDefs]="fxRatesColDef"
        [defaultColDef]="defaultColDef"
        [gridOptions]="fxRatesGridOptions"
        (gridReady)="OnGridReadyFxRates($event)"
        [rowSelection]="rowSelection"
        [enableBrowserTooltips]="true"
        [statusBar]="statusBarNoSum"
        [pagination]="true"
        [suppressPaginationPanel]="true"
    >
    </ag-grid-angular>
    <br>
</ng-container>

<!-- SL Balance -->
<ng-container *ngIf="showSlBal">
    <ag-grid-angular
        style="width: 1500px; height: 500px;"
        class="ag-theme-balham"
        [rowData]="slBalData"
        [columnDefs]="slBalColDef"
        [defaultColDef]="defaultColDef"
        [gridOptions]="slBalGridOptions"
        [statusBar]="statusBarCurrencyAmount"
        (gridReady)="OnGridReadySlBal($event)"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [rowSelection]="rowSelection"
        [pagination]="true"
        [suppressPaginationPanel]="true"
    >
    </ag-grid-angular>
    <br>
</ng-container>

<!-- GL Balance -->
<ng-container *ngIf="showGlBal">
    <ag-grid-angular
        style="width: 1500px; height: 500px;"
        class="ag-theme-balham"
        [rowData]="glBalData"
        [columnDefs]="glBalColDef"
        [defaultColDef]="defaultColDef"
        [gridOptions]="glBalGridOptions"
        [statusBar]="sideBarCurrencyQuantityAmount"
        (gridReady)="OnGridReadyGlBal($event)"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [rowSelection]="rowSelection"
        [pagination]="true"
        [suppressPaginationPanel]="true"
    >
    </ag-grid-angular>
    <br>
</ng-container>

<!-- ====================== -->
<!--      Finish screen     -->
<!-- ====================== -->
<ng-container *ngIf="showfinishScreen">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">All steps completed, click below to close window</span>
        </div>
        <br>
        <p-button (onClick)="CloseGrid()" label="Close Window"></p-button>
    </div>
</ng-container>

<!-- ========================= -->
<!-- Footer section below grid -->
<!-- ========================= -->

<ng-container *ngIf="!showfinishScreen">

    <!-- Page Information and Selection -->
    <div class="page-information">
        <button class="page-information-components" (click)="FirstPage()">First</button>
        <button class="page-information-components" (click)="PreviousPage()">Previous</button>
        <span class="page-information-components">Current Page</span>
        <span class="value">{{currentPage}}</span>
        <span class="page-information-components">{{lowestPage}} to {{highestPage}}</span>
        <button class="page-information-components" (click)="NextPage()">Next</button>
        <span class="page-information-components">Page Size:</span>
        <p-dropdown [options]="pageSizeOptions" [(ngModel)]="pageSize" appendTo="body" (onChange)="UpdatePageSize()"></p-dropdown>
    </div>

    <!-- Blocking processes information -->
    <div *ngIf="showBlockingActivelyProcessingMessage; else elseBlockingText" style="justify-self: flex-start;">
        <span class="font-bold-18-px">{{activelyRunningMessage}}</span>
        <app-refresh-spin></app-refresh-spin>
    </div>
    <ng-template #elseBlockingText>
        <span class="font-bold-18-px" style="visibility: hidden;">Blocking processes are running, waiting on them to finish.</span>
    </ng-template>
    <br>

    <!--Allocate space for the refresh component, so that components don't move when it appears -->
    <ng-template #elseRefresh>
        <app-refresh-spin style="visibility: hidden;"></app-refresh-spin>
    </ng-template>


    <!-- Step information -->
    <div style="display: flex; justify-content: flex-end">
        <div class="ui-g">
            <div class="ui-g-12">
                Step {{currentFile + 1}} of {{fileList.length - 1}}
            </div>
        </div>
    </div>
    
</ng-container>