export class Helper {

    static hasValue(value: any) {
        return value === undefined || value === null || Number.isNaN(value) || value === "" ? false : true;
      }

    static sortEntities(a: Object, b: Object, field: string) {
        var nameA = a[field];
        var nameB = b[field];
        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
      }

}