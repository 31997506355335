//Angular
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, StatusPanelDef } from 'ag-grid-community';

//AG Grid
import { GridOptions } from 'ag-grid-community';

//Interfaces
import { ProcessStatusRow } from 'src/app/interfaces/process-status-row';

//Services
import { ActiveProcessesService } from 'src/app/services/active-processes/active-processes.service';
import { BlacklineLoadDataService } from 'src/app/services/blackline-load-data/blackline-load-data.service';
import { BlacklineLoadFileService } from 'src/app/services/blackline-load-file/blackline-load-file.service';
import { ProcessStatusService } from 'src/app/services/process-status/process-status.service';

@Component({
  selector: 'app-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss']
})
export class ProcessStatusComponent implements OnInit {


  reProcessEnabled: boolean = false;
  refreshEnabled: boolean = true;

  //Loading template to pull up while data is loading.
  public overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Loading process status data</span>';

  //Pagination
  currentPage: number = 1;
  lowestPage: number = 1;
  highestPage: number = 1;
  pageSize: number = 10;
  pageSizeOptions = [
    '1000',
    '100',
    '10'
  ]
  processStatusGridApi!: GridApi;
  processStatusColumnApi!: ColumnApi;
  processStatusRows: ProcessStatusRow[] = [];

  public rowSelection = 'single';
  processStatusGridOptions!: GridOptions;

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    headerCheckboxSelectionFilteredOnly: true,
  };

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'center' }
      ],
    };

  processStatusColDef: ColDef[] = [
    {
      field: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50
    },
    {
      field: 'processor',
      tooltipField: 'processor'
    },
    {
      field: 'process',
      tooltipField: 'process'
    },
    {
      field: 'subProcess',
      tooltipField: 'subProcess'
    },
    {
      field: 'processStep',
      tooltipField: 'processStep'
    },
    {
      field: 'errorMessage',
      tooltipField: 'errorMessage'
    },
    {
      field: 'statusMessage',
      tooltipField: 'statusMessage'
    },
    {
      field: 'processDateTime',
      tooltipField: 'processDateTime'
    },
    {
      field: 'processId',
      tooltipField: 'processId'
    },
    {
      field: 'mostRecent',
      tooltipField: 'mostRecent'
    },
    {
      field: 'accountingPeriod',
      tooltipField: 'accountingPeriod'
    }
  ]

  constructor(
    public processStatusService: ProcessStatusService
    , public blacklineLoadDataService: BlacklineLoadDataService
    , public blacklineLoadFileService: BlacklineLoadFileService
    , private activeProcessesService: ActiveProcessesService
  ) {
    this.processStatusService.RetrieveProcessStatusRows();
  }

  ngOnInit(): void {
    this.RetrieveProcessStatusRows();
  }

  async reProcessFiles() {
    let row = this.processStatusGridApi.getSelectedRows()[0]
    const fileName = row['subProcess'];
    const accountingPeriod = row['accountingPeriod']
    const processId = row['processId']
    const response = await this.processStatusService.LogBlacklineLoad(fileName, 'Data Load', 'In Process', accountingPeriod, false, processId);

    var fileNameForActivelyRunning = this.GetFileNameFromLabel(fileName);

    var processBlockingCheck: boolean | null = false;
    var processBlockingWait: boolean | null = false;
    processBlockingCheck = await this.activeProcessesService.CheckForActivelyRunning(fileNameForActivelyRunning);

    if (processBlockingCheck == false) {
      processBlockingWait = await this.activeProcessesService.CheckAndWaitForActivelyRunning(fileNameForActivelyRunning);
      if (processBlockingWait == false) {
      }
    }

    if (processBlockingCheck == true || processBlockingWait == true) {
      if (fileName == 'FX Rates') {
        let response = await this.blacklineLoadDataService.retrieveBlacklineFxRatesData(accountingPeriod)
        this.blacklineLoadFileService.GenerateAndUploadFxRatesFile(response, accountingPeriod);
      }
      else if (fileName == 'Bank Balance') {
        let response = await this.blacklineLoadDataService.retrieveBlacklineBankBalanceData(accountingPeriod)
        this.blacklineLoadFileService.GenerateAndUploadBankBalanceFile(response, accountingPeriod);
      }
      else if (fileName == 'Subledger Balances') {
        let response = await this.blacklineLoadDataService.retrieveBlacklineSlBalData(accountingPeriod);
        if (response != null) {
          this.blacklineLoadFileService.GenerateAndUploadSlBalFile(response, accountingPeriod);
        }
      }
      else if (fileName == 'GL Balances') {
        let response = await this.blacklineLoadDataService.retrieveBlacklineGlBalData(accountingPeriod)
        if (response != null) {
          this.blacklineLoadFileService.GenerateAndUploadGlBalFile(response, accountingPeriod);
        }
      }
      await this.processStatusService.LogBlacklineLoad(fileName, 'Data Load', 'Success', accountingPeriod, false, processId);
    }
    else {
      await this.processStatusService.LogBlacklineLoad(fileName, 'Data Load', 'Failed', accountingPeriod, false, processId, 'Error while checking actively running');
    }
  }

  OnGridReady(params: GridReadyEvent) {
    console.log('grid ready');
    this.processStatusGridApi = params.api;
    this.processStatusColumnApi = params.columnApi;
    this.processStatusGridApi.setRowData(this.processStatusRows);
    console.log('process status rows - ');
    console.log(this.processStatusRows);
    if (this.processStatusRows.length < 1) {
      console.log('showing the loading overlay');
      this.processStatusGridApi.showLoadingOverlay();
    }
    //params.columnApi.autoSizeAllColumns();
    this.SortData();
    this.SetMostRecentFilter();
    this.processStatusGridApi.addEventListener('selectionChanged', this.UpdateReprocessAvailable.bind(this));
  }



  clearFilters() {
    this.processStatusGridApi.setFilterModel(null);
  }

  clearSelection() {
    this.processStatusGridApi.deselectAll();
  }

  FirstPage() {
    this.processStatusGridApi.paginationGoToFirstPage();
    this.SetCurrentPage()
  }

  NextPage() {
    this.processStatusGridApi.paginationGoToNextPage();
    this.SetCurrentPage()
  }

  PreviousPage() {
    this.processStatusGridApi.paginationGoToPreviousPage();
    this.SetCurrentPage()
  }

  GetPaginationValues() {
    this.highestPage = this.processStatusGridApi.paginationGetTotalPages();
    this.SetCurrentPage()
  }

  UpdatePageSize() {
    this.processStatusGridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues();
  }

  SetCurrentPage() {
    this.currentPage = this.processStatusGridApi.paginationGetCurrentPage() + 1;
  }

  SortData() {
    this.processStatusColumnApi.applyColumnState({
      defaultState: { sort: null },
    });
    this.processStatusColumnApi.applyColumnState({
      state: [
        { colId: 'processDateTime', sort: 'desc', sortIndex: 0 },
        { colId: 'process', sort: 'asc', sortIndex: 0 }
      ]
    })
  }

  SetMostRecentFilter() {
    this.processStatusGridApi.setFilterModel({
      mostRecent: {
        filterType: 'text',
        type: 'equals',
        filter: 'yes'
      },
      statusMessage: {
        filterType: 'text',
        type: 'notContains',
        filter: 'Success'
      },
    })
  }

  UpdateReprocessAvailable() {
    const selectedRows = this.processStatusGridApi.getSelectedRows();
    if (selectedRows.length > 0 && (selectedRows[0]['errorMessage'] != 'null' || selectedRows[0]['statusMessage'] == 'Failed')) {
      console.log('updating reprocess to true');
      this.reProcessEnabled = true;
    }
    else {
      console.log('updating reprocess to false');
      this.reProcessEnabled = false;
    }
  }

  async RefreshData() {
    this.processStatusGridApi.showLoadingOverlay();
    await this.RetrieveProcessStatusRows();
    this.processStatusGridApi.hideOverlay();
  }

  async RetrieveProcessStatusRows() {
    this.refreshEnabled = false;

    console.log('getting process status rows');

    const results = await this.processStatusService.RetrieveProcessStatusRows();

    this.processStatusRows = results;
    this.processStatusGridApi.setRowData(this.processStatusRows);

    this.refreshEnabled = true;
  }

  GetFileNameFromLabel(Label: string) {
    var fileName = '';
    if (Label == 'FX Rates') {
      fileName = 'fxRates';
    }
    else if (Label == 'Bank Balance') {
      fileName = 'bankBalance';
    }
    else if (Label == 'Subledger Balances') {
      fileName = 'slBal';
    }
    else if (Label == 'GL Balances') {
      fileName = 'glBal';
    }
    return fileName;
  }

}
