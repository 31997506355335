import { Injectable } from '@angular/core';

//Amplify
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class CognitoApiService {

  user: any;

  constructor() { }

  async kochIdLogin() {
    try {
      console.log('retrieving user');
      var response = await Auth.federatedSignIn({ customProvider: 'KochID' });
      console.log('Response');
      console.log(response);
      if (response != null && response != undefined) {
        console.log('respond true');
        return true;
      }
      else {
        console.log('respond false');
        return false;
      }
    }
    catch (ex) {
      console.log('Error');
      console.log(ex);
      return false;
    }
  }

  async checkUserIsAuthenticated() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      console.log('user signed in');
      return true;
    }
    catch {
      console.log('no user logged in');
      return false;
    }
  }

  async retriveUser() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      return this.user;
    }
    catch (ex) {
      console.log('Error retrieving user');
      console.log(ex);
      return null
    }
  }

  async retrieveUserFirstNameLastName() {
    try {
      var user = await this.retriveUser();
      var username = user.username;
      var removeKochId = username.split("_")[1];
      var firstName = removeKochId.split(".")[0];
      firstName = firstName[0].toUpperCase() + firstName.substring(1, firstName.length);
      var lastname = removeKochId.split(".")[1].split("@")[0];
      lastname = lastname[0].toUpperCase() + lastname.substring(1, lastname.length);;
      return firstName + " " + lastname;
    }
    catch (ex) {
      console.log('Error retrieving user or formatting');
      console.log(ex);
      return null
    }
  }

  async retrieveUserEmail() {
    try {
      var user = await this.retriveUser();
      var username = user.username;
      var removeKochId = username.split("_")[1];
      return removeKochId;
    }
    catch (ex) {
      console.log('Error retrieving user email or formatting');
      console.log(ex);
      return null
    }
  }

  async getCurrentUserGroups() {
    const user = await this.retriveUser();
    return user.signInUserSession.accessToken.payload['cognito:groups'];
  }

  async checkUserAdminAccess() {
    const groups = await this.getCurrentUserGroups();
    return groups.includes('Admins');
  }
}
