import { Injectable } from '@angular/core';
import { CellValueChangedEvent } from 'ag-grid-community';
import { GptoNotesData } from 'src/app/interfaces/gpto-notes-data';
import { CognitoApiService } from '../cognito-api/cognito-api.service';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { GptoNotesHandlerService } from '../gpto-notes-handler/gpto-notes-handler.service';

@Injectable({
  providedIn: 'root'
})
export class GptoChangeHandlerService {

  currentChanges: GptoNotesData[] = [];

  constructor(
    private cognitoApiService: CognitoApiService,
    private errorHandlerService: ErrorHandlerService,
    private gptoNotesHandlerService: GptoNotesHandlerService
  ) { }

  async RecordUpdates(event: any) {
    let userFirstNameLastName = await this.cognitoApiService.retrieveUserFirstNameLastName()
    let name = "";
    if (userFirstNameLastName != null) {
      name = userFirstNameLastName;
    }
    let notesRow: GptoNotesData = {
      accountingStatus: event.data.accountingStatus,
      dataType: event.data.dataType,
      derivativeInstrument: event.data.derivativeInstrument,
      explainYtdVariances: event.data.explainYtdVariances,
      kstRingfence: event.data.kstRingfence,
      ktb: event.data.ktb,
      legalEntity: event.data.legalEntity,
      mele: event.data.mele,
      naturalAccount: event.data.naturalAccount,
      naturalAccountDescription: event.data.naturalAccountDescription,
      notes: event.data.notes,
      re_curr: event.data.re_curr,
      sourceSystem: event.data.sourceSystem,
      period: event.data.period,
      updatedBy: name,
      summaryHashValue: event.data.summaryLevelHash
    }
    for (var i = 0; i < this.currentChanges.length; i++) {
      if (this.currentChanges[i].summaryHashValue == event.data.summaryLevelHash) { this.currentChanges.splice(i, 1); }
    }
    this.currentChanges.push(notesRow);
  }

  async SaveUpdates() {
    for (var i = 0; i < this.currentChanges.length; i++) {
      let response = await this.gptoNotesHandlerService.SaveNotesToSnowflake(this.currentChanges[i].accountingStatus, this.currentChanges[i].dataType, this.currentChanges[i].derivativeInstrument, this.currentChanges[i].explainYtdVariances, this.currentChanges[i].kstRingfence, this.currentChanges[i].legalEntity, this.currentChanges[i].ktb, this.currentChanges[i].mele, this.currentChanges[i].naturalAccount, this.currentChanges[i].naturalAccountDescription, this.currentChanges[i].notes, this.currentChanges[i].re_curr, this.currentChanges[i].sourceSystem, this.currentChanges[i].period, this.currentChanges[i].updatedBy, this.currentChanges[i].summaryHashValue)
      if (response.statusCode != 200) {
        this.errorHandlerService.UpdateAndDisplayError('Error while saving notes, please try again or contact IT');
        break;
      }
    }
    this.currentChanges = [];
  }

  ClearUpdates() {
    this.currentChanges = [];
  }
}
