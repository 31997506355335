export interface fileUploaderProcessStatus {
    Process: string;
    ProcessId: string;
    ProcessDateTime: string;
    ProcedureName: string;
    StatusMessage: string;
    RowCount: string | null;
    ErrorNumber: string | null;
    ErrorState: string | null;
    ErrorSeverity: string | null;
    ErrorProcedure: string | null;
    ErrorLine: string | null;
    ErrorMessage: string | null;
    ModifiedBy: string;
}

export class CurrentFileUploadStatus {
    private _inProcess: boolean = false;
    public fileName: string = '';
    public processId: string = '';
    public inSnowflake: boolean = false;
    public statusMessage?: string;
    public timestamp?: string;
    public ProcessValueChangedCallback?: Function;

    public get inProcess() { return this._inProcess; }
    public set inProcess(value: boolean) {
        this._inProcess = value;
        this.ProcessValueChanged();
    }

    private ProcessValueChanged() {
        if (this.ProcessValueChangedCallback) { this.ProcessValueChangedCallback(); }
    }
}