/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "fdaguiapi",
            "endpoint": "https://v9fhi61u1i.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:815f4128-9f6c-440d-9830-d209d9044f4f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Xn9k2nvlH",
    "aws_user_pools_web_client_id": "5q8ml2mt4khu9pi1s411gqq4al",
    "oauth": {
        "domain": "fdaguidevauth-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/login/",
        "redirectSignOut": "http://localhost:4200/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fdaguifilestorage82447-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
