
<!-- ================================================= -->
<!-- Confirmation dialog for accept and reject buttons -->
<!-- ================================================= -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="margin-left-right-20px">
    <div class="ui-g">
        <div class="ui-g-12">
            <span class="font-bold-18-px">KII Trial Balance</span>
        </div>
        <ng-container *ngIf="showAccountingPeriodWaitMessage">
            <br>
            <div class="ui-g-12">
                <span class="font-bold-18-px">Loading Accounting Periods...</span>
            </div>
        </ng-container>
        <ng-container *ngIf="showTriggerOptions">
            <div class="ui-g-12">
                <div class="margin-bottom-50px ui-tabview-panel-height">
                <span class="left margin-left-25px">
                    <div>
                        <span>
                            Select Dataset to Create:<br><br>
                            <div class="ui-g-12">
                                <span>Trial Balance Submission Group (Required)</span>
                                <br>
                                <p-dropdown [options]="trialBalanceSubmissionGroup" [(ngModel)]="selectedTrialBalanceSubmissionGroup" optionLabel="name" class="p-dropdown-padded"></p-dropdown>
                                <br><br>
                                <span>Trial Balance Period Date</span>
                                <br>
                                <p-dropdown [options]="trialBalancePeriodDate" [(ngModel)]="selectedtrialBalancePeriodDate" optionLabel="name" class="p-dropdown-padded"></p-dropdown>
                                <br><br>
                                <span>Process Status (Required)</span>
                                <br>
                                <p-dropdown [options]="processStatus" [(ngModel)]="selectedprocessStatus" optionLabel="name" class="p-dropdown-padded"></p-dropdown>
                                <br><br>
                                <span>Trial Balance Data Layer (Required)</span>
                                <br>
                                <p-dropdown [options]="trialBalanceDataLayer" [(ngModel)]="selectedtrialBalanceDataLayer" optionLabel="key" class="p-dropdown-padded"></p-dropdown>
                            </div>
                            <br><br>
                            <p-button (onClick)="TriggerTrialBalance()" label="Trigger Process Status Update" icon="pi pi-check-square" class="button-with-icon"></p-button>
                        </span>

                        <ng-container *ngIf="showRunningStoredProcedureMessage">
                            <br><br>
                            <span class="font-bold-18-px">Running KII Trial Balance Status Update...</span>
                            <app-refresh-spin></app-refresh-spin>
                        </ng-container>
                
                        <ng-container *ngIf="showStoredProcedureCompleteMessage">
                            <br><br>
                            <span class="font-bold-18-px" style="color: green;">Status Updated</span>
                        </ng-container>

                        <ng-container *ngIf="showActiveProcessesBlockingTimeoutMessage">
                            <div class="ui-g-12">
                                <span class="font-bold-18-px">Timeout while waiting for blocking processes</span>
                                <br>
                                <span class="font-bold-18-px">Please try again, or contact IT</span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="showCheckingActiveProcessesMessage">
                            <div class="ui-g-12">
                                <span class="font-bold-18-px">Checking and waiting on blocking processes...</span>
                            </div>
                        </ng-container>
                    </div>
                </span>
                </div>
            </div>
        </ng-container>

    </div>
</div>