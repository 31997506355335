import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AccountingPeriodService {

  constructor() { }

  async get_accounting_period_dates(returnColumn: string, view?: string | null) {
    if (view == null) {
      view = 'monthend'
    }
    const apiName = 'fdaguiapi';
    const path = '/snowflake/accountingPeriod'; 
    const myInit = { 
      queryStringParameters: { 
        requested_column: returnColumn,
        view: view
      },
    };
    return await API.get(apiName, path, myInit)
  }

  async get_max_acccounting_period(data_type: string) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/maxaccountingperiod';
    const myInit = {
      queryStringParameters: {
        data_type: data_type
      }
    };
    return await API.get(apiName, path, myInit)
  }

}
