//Amplify
import { API } from 'aws-amplify';

//Angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GptoNotesHandlerService {

  constructor() { }

  async SaveNotesToSnowflake(accountingStatus: string, datatype: string, derivativeInstrument: string, explainYtdVariances: string, kstRingfence: string, legalEntity: string, ktb: string, mele: string, naturalAccount: string, naturalAccountDescription: string, notes: string, reCurr: boolean, sourceSystem: string, period: string, updatedBy: string, summaryHashValue: string) {
    const apiName = 'fdaguiapi';
    const path = '/snowflake/gptonotes'; 
    const myInit = { 
        body: {
          accounting_status: accountingStatus,
          datatype: datatype,
          derivative_instrument: derivativeInstrument,
          explain_ytd_variances: explainYtdVariances,
          kst_ringfence: kstRingfence,
          legal_entity: legalEntity,
          ktb: ktb,
          mele: mele,
          natural_account: naturalAccount,
          natural_account_description: naturalAccountDescription,
          notes: notes,
          re_curr: reCurr,
          source_system: sourceSystem,
          period: period,
          updated_by: updatedBy,
          summary_level_hash: summaryHashValue
        }
    };
    console.log(myInit);
    return await API.post(apiName, path, myInit)
  }


}
