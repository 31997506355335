import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class MappingRulesService {

  showRefreshIcon: boolean = false;
  logResultsFound: number = 0;

  constructor() { }


  async CheckOnSnowflakeProgress(date: string, object: string) {
    var message = '';
    if (object == 'eliminationRollup') {
      message = 'End of Load from Silver to Snowflake for MAPPINGRULES_ELIMINATIONROLLUP';
    }
    else if (object == 'naturalAccount') {
      message = 'End of Load from Silver to Snowflake for MAPPINGRULES_NATURALACCOUNTADDITIONALATTRIBUTES';
    }
    else if (object == 'gmcGLAccountGrouping') {
      message = 'End of Load to Snowflake for MAPPINGRULES_GMC_GLACCOUNTGROUPING';
    }
    else if (object == 'gmcPrimaryAttributeAssignment') {
      message = 'End of Load to Snowflake for MAPPINGRULES_GMC_PRIMARYATTRIBUTEASSIGNMENT';
    }


    this.showRefreshIcon = true;
    console.log('Checking for snowflake progress');
    const apiName = 'fdaguiapi';
    const path = '/snowflake/logtable';
    const myInit = {
      queryStringParameters: {
        date: date,
        procedureName: object,
      },
    };
    var response = await API.get(apiName, path, myInit)
    this.showRefreshIcon = false;
    if (response['body'].length > 0) {
      console.log('results found');
      if (this.logResultsFound < response['body'].length) {
        console.log(this.logResultsFound);
        console.log(response['body'].length)
        for (var i = this.logResultsFound; i < response['body'].length; i++) {
          var processingMessage = response['body'][i][1];
          var rowCount = null;
          if (response['body'][i].length > 2) {
            rowCount = response['body'][i][2];
          }
          console.log(processingMessage);
          if (processingMessage.includes(message)) {
            console.log('found complete');
            return true;
          }
        }
        this.logResultsFound = response['body'].length;
      }
      console.log(response);
    }
    else {
      console.log('results not found');
      console.log(response);
    }
    return false;
  }

  async WaitForSnowflakeResult(mappingRule: string, date: string) {
    this.logResultsFound = 0;
    console.log('Waiting on snowflake result')
    var object = '';
    if (mappingRule == 'eliminationRollup') {
      object = 'SP_LOAD_MAPPINGRULES_ELIMINATIONROLLUP';
    }
    else if (mappingRule == 'gmcGLAccountGrouping') { object = 'SP_LOAD_MAPPINGRULES_GMC_GLACCOUNTGROUPING'; }
    else if (mappingRule == 'gmcPrimaryAttributeAssignment') { object = 'SP_LOAD_MAPPINGRULES_GMC_PRIMARYATTRIBUTEASSIGNMENT'; }
    else if (mappingRule == 'naturalAccount') {
      object = 'SP_LOAD_MAPPINGRULES_NATURALACCOUNTADDITIONALATTRIBUTES';
    }
    else {
      return;
    }
    var interval = 5000;
    var timeout = 120
    console.log(date);
    console.log(object);
    console.log('Starting interval');
    for (var i = 0; i < timeout; i++) {
      await new Promise(r => setTimeout(r, interval));
      var response = await this.CheckOnSnowflakeProgress(date, object);
      console.log(response);
      if (response) {
        i = timeout + 1;
        return true;
      }
    }
    return null;
  }
}
