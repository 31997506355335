import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateGeneratorService {

  constructor() { }

  GetDateString() {
    let todayDate = new Date();
    var year = todayDate.getFullYear().toString();
    var month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
    var day = todayDate.getDate().toString().padStart(2, '0');
    var hour = (todayDate.getHours() - 2).toString().padStart(2, '0');
    var minute = todayDate.getMinutes().toString().padStart(2, '0');
    var second = todayDate.getSeconds().toString().padStart(2, '0');
    var millisecond = todayDate.getMilliseconds().toString();
    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + millisecond;
  }
}
