import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

//Components
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { BlacklineLoadReprocessComponent } from './components/blackline-load-reprocess/blackline-load-reprocess.component';
import { MappingRulesLoadComponent } from './components/mapping-rules-load/mapping-rules-load.component';
import { GptoSummmaryComponent } from './components/gpto-summmary/gpto-summmary.component';
import { PowerBiRefreshComponent } from './components/power-bi-refresh/power-bi-refresh.component';
import { KiiTrialBalanceComponent } from './components/kii-trial-balance/kii-trial-balance.component';
import { ProcessStatusComponent } from './components/process-status/process-status.component';
import { FileUploadTableSelecterComponent } from './components/file-upload-table-selecter/file-upload-table-selecter.component';

const routes: Routes = [
  { path: 'fda-manual-load/manual-file-upload/:datatype', component: FileUploaderComponent },
  { path: 'fda-manual-load/reprocess-mapping-rules', component: MappingRulesLoadComponent },
  { path: 'blackline/load', component: BlacklineLoadReprocessComponent },
  { path: 'dpsr/manual-file-upload/:datatype', component: FileUploaderComponent },
  { path: 'factre/manual-file-upload/:datatype', component: FileUploaderComponent },
  { path: 'gpto/summary', component: GptoSummmaryComponent },
  { path: 'gpto/powerbi-refresh', component: PowerBiRefreshComponent },
  { path: 'kii-data-platform/kii-trial-balance', component: KiiTrialBalanceComponent },
  { path: 'kii-data-platform/manual-file-upload/:datatype', component: FileUploaderComponent },
  { path: 'process-status', component: ProcessStatusComponent },
  { path: 'file-uploader-selecter', component: FileUploadTableSelecterComponent },
  { path: 'storm/manual-file-upload/:datatype', component: FileUploaderComponent }
]

@NgModule({
  declarations: [],
  imports: [
    [RouterModule.forRoot(routes), RouterModule.forChild(routes)],
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
