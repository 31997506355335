<!-- ================================================= -->
<!-- Confirmation dialog for refresh button            -->
<!-- ================================================= -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="center" life="60000"></p-toast>

<div class="margin-left-right-20px">
    <div class="ui-g">
        <span class="font-bold-18-px">GPTO Summary</span>
        <ng-container *ngIf="showAccountingPeriodWaitMessage">
            <br>
            <div class="ui-g-12">
                <span class="font-bold-18-px">Loading Accounting Periods...</span>
            </div>
        </ng-container>
        <ng-container *ngIf="showDataLoadWaitMessage">
            <br>
            <div class="ui-g-12">
                <span class="font-bold-18-px">Loading Data...</span>
            </div>
        </ng-container>
        <ng-container *ngIf="showGptoInformation">
            <div class="ui-g-12">
                <div class="selections">
                    <p-dropdown [options]="periodDates" [(ngModel)]="selectedPeriodDate" optionLabel="name" class="p-dropdown-padded" (onChange)="AccountingPeriodChange()"></p-dropdown>
                    <p-selectButton [options]="viewOptions" [(ngModel)]="selectedViewOption" optionLabel="name" (onChange)="OnViewChange()"></p-selectButton>
                    <div>
                        <span style="vertical-align: top;">Show Intercompany Partner    </span>
                        <p-inputSwitch [(ngModel)]="showICP" (onChange)="handleInputICPSwitchChange($event)"></p-inputSwitch>
                    </div>
                    <div>
                        <button pButton type="button" icon="pi pi-refresh" label="Load Data" class="button-with-icon" [disabled]="showLoadingSymbol" (click)="LoadData()"></button>
                    </div>
                    <div *ngIf="showLoadingSymbol; else elseHiddenSpinner" class="loading">
                        <i class="pi pi-spin pi-spinner"></i>
                        Loading Data...
                    </div>
                    <ng-template #elseHiddenSpinner>
                        <div class="loading">
                            <i class="pi pi-spin pi-spinner" style="visibility: hidden;" hidden="true"></i>
                            <span style="visibility: hidden;">Loading Data...</span>
                        </div>
                    </ng-template>
                    <div class="save-notes">
                        <p-button (onClick)="SaveUpdates()" label="Save Notes" [disabled]="!updateOptionsAvailable"></p-button>
                        <p-button (onClick)="CancelNotes()" label="Cancel Notes" [disabled]="!updateOptionsAvailable"></p-button>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- ========================= -->
        <!-- Header section above grid -->
        <!-- ========================= -->
        <ng-container *ngIf="showGrid">

            <!-- Clear buttons and summary information -->
            <div class="clear-and-summary-section">
                <div>
                    <button (click)="clearFilters()" class="clear-buttons">Clear Filters</button>
                    <button (click)="clearSelection()" class="clear-buttons">Clear Selection</button>
                    Filtered Row(s) Total MTD Variance: {{numMtdVariance}} | Total YTD Variance: {{numYtdVariance}}
                </div>
            </div>

        

        <!-- ====================== -->
        <!-- Grid section           -->
        <!-- ====================== -->
            <ag-grid-angular
                style="width: 99%; height: 600px;"
                class="ag-theme-balham"
                [rowData]="gridData"
                [columnDefs]="gridColDef"
                [defaultColDef]="defaultColDef"
                [gridOptions]="gridOptions"
                (gridReady)="OnGridReady($event)"
                [overlayLoadingTemplate]="overlayLoadingTemplate"
                [rowSelection]="rowSelection"
                [enableRangeSelection]="false"
                [pagination]="true"
                [suppressPaginationPanel]="true"
                [statusBar]="sideBarVarianceSums"
            >
            </ag-grid-angular>
            <br>

        <!-- ========================= -->
        <!-- Footer section below grid -->
        <!-- ========================= -->

        <!-- Page Information and Selection -->
        <div style="display: flex; justify-content: space-between; margin-right: 1%;">
            <div>
            </div>
            <div class="page-information">
                <button class="page-information-components" (click)="FirstPage()">First</button>
                <button class="page-information-components" (click)="PreviousPage()">Previous</button>
                <span class="page-information-components">Current Page</span>
                <span class="value">{{currentPage}}</span>
                <span class="page-information-components">{{lowestPage}} to {{highestPage}}</span>
                <button class="page-information-components" (click)="NextPage()">Next</button>
                <span class="page-information-components">Page Size:</span>
                <p-dropdown [options]="pageSizeOptions" [(ngModel)]="pageSize" appendTo="body" (onChange)="UpdatePageSize()"></p-dropdown>
            </div>
        </div>
            
        </ng-container>

        <ng-container *ngIf="showSaveNotesConfirmWindow">
            <p-dialog [(visible)]="showSaveNotesConfirmWindow" [responsive]="true" showEffect="fade"
            [modal]="true"  [closeOnEscape]=false [closable]=false>
            <span class="font-bold-18-px">Review notes to save</span>
            <br/><br/>
            <ag-grid-angular
                style="width: 1500px; height: 500px;"
                class="ag-theme-balham"
                [rowData]="gridDataSaveNotes"
                [columnDefs]="gridColDefSaveNotes"
                [defaultColDef]="defaultColDef"
                [gridOptions]="gridOptionsSaveNotes"
                (gridReady)="OnGridReadySaveNotes($event)"
                [rowSelection]="rowSelection"
                [enableRangeSelection]="false"
            >
            </ag-grid-angular>
            <br/>
            <div class="modal-confirmation">
                <p-button (onClick)="SaveNotes()" label="Confirm"></p-button>
                <p-button (onClick)="CloseGrid()" label="Cancel"></p-button>
            </div>
            </p-dialog>
        </ng-container>
    </div>
</div>