import { Component, OnInit } from '@angular/core';

//Ag Grid
import { CellValueChangedEvent, ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode, StatusPanelDef } from 'ag-grid-community';

//PrimeNg
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

//Interfaces
import { DropdownOptionInterface } from 'src/app/interfaces/dropdown-option-interface';
import { GptoNotesData } from 'src/app/interfaces/gpto-notes-data';
import { GptoSummmaryData } from 'src/app/interfaces/gpto-summary-data';

//Services
import { AccountingPeriodService } from 'src/app/services/accounting-period/accounting-period.service';
import { ActiveProcessesService } from 'src/app/services/active-processes/active-processes.service';
import { CognitoApiService } from 'src/app/services/cognito-api/cognito-api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';
import { GptoChangeHandlerService } from 'src/app/services/gpto-change-handler/gpto-change-handler.service';
import { GptoLoadDataService } from 'src/app/services/gpto-load-data/gpto-load-data.service';
import { GptoNotesHandlerService } from 'src/app/services/gpto-notes-handler/gpto-notes-handler.service';
import { GptoSummaryBarComponent } from '../gpto-summary-bar/gpto-summary-bar.component';

//Components
import { CustomCheckboxComponent } from '../custom-checkbox/custom-checkbox.component';
import { GptoEditCellRenderer } from '../gpto-edit-cell-renderer/gpto-edit-cell-renderer';

@Component({
  selector: 'app-gpto-summmary',
  templateUrl: './gpto-summmary.component.html',
  styleUrls: ['./gpto-summmary.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class GptoSummmaryComponent implements OnInit {

  showAccountingPeriodWaitMessage: boolean = false;
  showDataLoadWaitMessage: boolean = false;
  showGptoInformation: boolean = false;
  showGrid: boolean = false;
  showLoadingSymbol: boolean = false;
  showSaveNotesConfirmWindow: boolean = false;
  updateOptionsAvailable: boolean = false;

  periodDates: DropdownOptionInterface[];
  selectedPeriodDate: DropdownOptionInterface;

  viewOptions: DropdownOptionInterface[];
  selectedViewOption: DropdownOptionInterface;

  gridApi!: GridApi;
  gridData!: GptoSummmaryData[];
  gridOptions: GridOptions = {};
  columnApi!: ColumnApi;

  public numMtdVariance: string = "0";
  public numYtdVariance: string = "0";

  //Pagination
  currentPage: number = 1;
  lowestPage: number = 1;
  highestPage: number = 1;
  pageSize: number = 10;
  pageSizeOptions = [
    '1000',
    '100',
    '10'
  ]

  public rowSelection = 'multiple';

  //Loading template to pull up while data is loading.
  public overlayLoadingTemplate =
    '<span class="ag-overlay-loading-center">Loading file data</span>';

  gridColDef: ColDef[] = [
    {
      field: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      floatingFilter: false,
      width: 40
    },
    {
      field: 'sourceSystem',
      tooltipField: 'sourceSystem',
      width: 90
    },
    {
      field: 'kstRingfence',
      tooltipField: 'kstRingfence',
      headerName: 'KST Ringfence'
    },
    {
      field: 'legalEntity',
      tooltipField: 'legalEntity',
      width: 90
    },
    {
      field: 'mele',
      tooltipField: 'mele',
      width: 90
    },
    {
      field: 'accountingStatus',
      tooltipField: 'accountingStatus',
      width: 90
    },
    {
      field: 'derivativeInstrument',
      tooltipField: 'derivativeInstrument',
      width: 120
    },
    {
      field: 'naturalAccount',
      tooltipField: 'naturalAccount',
      width: 90
    },
    {
      field: 'naturalAccountDescription',
      tooltipField: 'naturalAccountDescription'
    },
    {
      field: 'ktb',
      tooltipField: 'ktb',
      headerName: 'KTB',
      width: 115
    },
    {
      field: 'intercompanyPartner',
      tooltipField: 'intercompanyPartner',
      headerName: 'ICP',
      width: 115
    },
    {
      field: 'mtdSourceDetail',
      tooltipField: 'mtdSourceDetail',
      headerName: 'MTD Source Detail',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'mtdGl',
      tooltipField: 'mtdGl',
      headerName: 'MTD GL',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'mtdVariance',
      tooltipField: 'mtdVariance',
      headerName: 'MTD Variance',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'ytdSourceDetail',
      tooltipField: 'ytdSourceDetail',
      headerName: 'YTD Source Detail',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'ytdGl',
      tooltipField: 'ytdGl',
      headerName: 'YTD GL',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'ytdVariance',
      tooltipField: 'ytdVariance',
      headerName: 'YTD Variance',
      cellRenderer: this.CurrencyCellRenderer,
      width: 110,
      type: 'rightAligned'
    },
    {
      field: 'notes',
      tooltipField: 'notes',
      editable: true
    },
    {
      field: "re_curr",
      cellRenderer: CustomCheckboxComponent
    },
    {
      field: 'explainYtdVariances',
      tooltipField: 'explainYtdVariances',
      headerName: 'Explain YTD Variances',
      editable: true
    },
    {
      field: 'Edited',
      cellRenderer: GptoEditCellRenderer,
    },
    {
      field: 'updatedBy',
      tooltipField: 'updatedBy',
    },
    {
      field: 'updated',
      tooltipField: 'updated',
      headerName: 'Updated TimeStamp',
    }
  ];

  //Status bar for currency quantity amount sum
  public sideBarVarianceSums: {
    statusPanels: StatusPanelDef[];
  } = {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'center' },
        { statusPanel: GptoSummaryBarComponent },
      ],
    };

  public gridDataSaveNotes!: GptoNotesData[];
  public gridColDefSaveNotes: ColDef[] = [
    {
      field: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      floatingFilter: false,
      width: 40
    },
    {
      field: 'sourceSystem',
      tooltipField: 'sourceSystem',
      width: 60
    },
    {
      field: 'kstRingfence',
      tooltipField: 'kstRingfence',
      headerName: 'KST Ringfence'
    },
    {
      field: 'legalEntity',
      tooltipField: 'legalEntity',
      width: 50
    },
    {
      field: 'mele',
      tooltipField: 'mele',
      width: 50
    },
    {
      field: 'accountingStatus',
      tooltipField: 'accountingStatus',
      width: 70
    },
    {
      field: 'derivativeInstrument',
      tooltipField: 'derivativeInstrument',
      width: 120
    },
    {
      field: 'naturalAccount',
      tooltipField: 'naturalAccount',
      width: 70
    },
    {
      field: 'naturalAccountDescription',
      tooltipField: 'naturalAccountDescription'
    },
    {
      field: 'ktb',
      tooltipField: 'ktb',
      headerName: 'KTB',
      width: 115
    },
    {
      field: 'notes',
      tooltipField: 'notes'
    },
    {
      field: "re_curr",
      cellRenderer: CustomCheckboxComponent
    },
    {
      field: 'explainYtdVariances',
      tooltipField: 'explainYtdVariances',
      headerName: 'Explain YTD Variances'
    },
    {
      field: 'updatedBy',
      tooltipField: 'updatedBy',
    },
    {
      field: 'updated',
      tooltipField: 'updated',
      headerName: 'Updated TimeStamp',
    },
    {
      field: 'intercompanyPartner',
      tooltipField: 'intercompanyPartner'
    }
  ]

  public gridOptionsSaveNotes: GridOptions = {};
  public gridApiSaveNotes!: GridApi;

  private CurrencyCellRenderer(params: any) {

    var usdFormate = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
    return usdFormate.format(params.value);
  }

  public showICP: boolean = false;

  //Grid default settings
  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    headerCheckboxSelectionFilteredOnly: true,
  };

  constructor(
    private accountingPeriodService: AccountingPeriodService
    , private activeProcessesService: ActiveProcessesService
    , private cognitoApiService: CognitoApiService
    , private confirmationService: ConfirmationService
    , private errorHandlerService: ErrorHandlerService
    , private gptoChangeHandlerService: GptoChangeHandlerService
    , private gptoLoadDataService: GptoLoadDataService
    , private gptoNotesHandlerService: GptoNotesHandlerService
    , private messageService: MessageService
  ) {
    this.showAccountingPeriodWaitMessage = true;
    this.showDataLoadWaitMessage = false;
    this.showGptoInformation = false;
    this.showGrid = false;
    this.periodDates = [];
    this.selectedPeriodDate = { name: '-Select-' }
    this.periodDates.push({ name: '-Select-' });
    this.viewOptions = [{ name: 'GAAP' }, { name: 'ECON' }]
    this.selectedViewOption = { name: 'GAAP' }
    this.accountingPeriodService.get_accounting_period_dates('ACCOUNTINGPERIODID', 'pre_monthend').then(response => {
      this.accountingPeriodService.get_max_acccounting_period('GAAP').then(response => {
        let result = eval(response['body']);
        if (response['statusCode'] == 200) {
          this.selectedPeriodDate = { name: result[0][0] }
        }
        this.showAccountingPeriodWaitMessage = false;
        this.showDataLoadWaitMessage = true;
        this.RetrieveData().then(() => {
          this.showDataLoadWaitMessage = false;
          this.showGptoInformation = true;
          this.showGrid = true;
        });
      });
      let results = eval(response['body']);
      if (response['statusCode'] == 200) {
        for (var i = 0; i < results.length; i++) {
          this.periodDates.push({ name: results[i][0] });
        }
      }
      else {
        this.errorHandlerService.UpdateAndDisplayError('Issue while getting accounting period dates. Please refresh this page, or contact IT support');
      }
    });

  }

  ngOnInit(): void {
  }

  async RetrieveData() {
    this.updateOptionsAvailable = false;
    this.gptoChangeHandlerService.ClearUpdates();
    console.log('getting data');
    if (this.gridApi != null) {
      this.gridApi.showLoadingOverlay();
    }
    await this.gptoLoadDataService.retrieveGptoSummaryData(this.selectedViewOption.name, this.selectedPeriodDate.name);
    if (this.selectedViewOption.name == 'GAAP') {
      this.gridData = this.gptoLoadDataService.gaapData;
    }
    else {
      this.gridData = this.gptoLoadDataService.econData;
    }
    this.gridOptions.api?.setRowData(this.gridData);
    if (this.gridApi != null) {
      console.log('grid is ready');
      this.gridApi.hideOverlay();
      //this.columnApi.autoSizeAllColumns(true);
      this.GetPaginationValues()
    }
  }

  OnGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.columnApi.setColumnVisible('intercompanyPartner', false);
    if (this.gridData == null) {
      this.gridApi.showLoadingOverlay();
    }
    this.gridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues()
    //this.columnApi.autoSizeAllColumns(true);
    this.updateTotals();
    this.gridApi.addEventListener('modelUpdated', this.updateTotals.bind(this));
    this.gridApi.addEventListener('filterModified', this.updateTotals.bind(this));
    this.gridApi.addEventListener('cellValueChanged', this.RecordUpdates.bind(this));
  }

  clearFilters() {
    this.gridApi.setFilterModel(null);
  }

  clearSelection() {
    this.gridApi.deselectAll();
  }

  GetPaginationValues() {
    this.highestPage = this.gridApi.paginationGetTotalPages();
    this.SetCurrentPage()
  }

  SetCurrentPage() {
    this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
  }

  FirstPage() {
    this.gridApi.paginationGoToFirstPage();
    this.SetCurrentPage()
  }

  NextPage() {
    this.gridApi.paginationGoToNextPage();
    this.SetCurrentPage()
  }

  PreviousPage() {
    this.gridApi.paginationGoToPreviousPage();
    this.SetCurrentPage()
  }

  UpdatePageSize() {
    this.gridApi.paginationSetPageSize(this.pageSize);
    this.GetPaginationValues();
  }

  async LoadData() {
    this.confirmationService.confirm({
      message: `Are you sure you would like to load the GPTO Summary for GAAP and ECON on period: ${this.selectedPeriodDate.name}`,
      accept: async () => {
        this.showLoadingSymbol = true;
        let res = await this.activeProcessesService.CheckActiveProcesses('gptoLoad');
        if (res == true) {
          this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'GPTO Load currently running, process will wait for current run to finish and then load GPTO' });
          let res2 = await this.activeProcessesService.WaitForActiveProcessCleared('gptoLoad', 5000, 360);
          if (res2 != true) {
            this.errorHandlerService.UpdateAndDisplayError('Error or timeout while waiting for active process to be cleared on GPTO Load. Please contact IT.')
            this.showLoadingSymbol = false;
            return
          }
          else {
            this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Load will begin now' });
          }
        }
        else if (res == null) {
          this.showLoadingSymbol = false;
          return
        }
        let dataType = this.selectedViewOption.name;
        let otherDataType = 'GAAP';
        if (dataType == 'GAAP') {
          otherDataType = 'ECON';
        }
        let period = this.selectedPeriodDate.name;
        let response = await this.gptoLoadDataService.LoadGPTOData(this.selectedViewOption.name, this.selectedPeriodDate.name);
        if (response['statusCode'] == 200) {
          let response2 = await this.activeProcessesService.WaitForActiveProcessCleared('gptoLoad', 5000, 360);
          this.gptoLoadDataService.LoadGPTOData(otherDataType, period).then(() => {
            this.showLoadingSymbol = false;
          });
          if (response2 == true) {
            this.confirmationService.confirm({
              message: `Load complete for datatype: ${dataType} and period: ${period}. Would you like to pull that data now?`,
              accept: async () => {
                this.selectedPeriodDate = { name: period }
                this.selectedViewOption = { name: dataType }
                this.RetrieveData();
              }
            })
          }
          else {
            this.showLoadingSymbol = false;
            this.errorHandlerService.UpdateAndDisplayError('Error or timeout while waiting for active process to be cleared on GPTO Load. Please contact IT.')
          }
        }
        else {
          this.showLoadingSymbol = false;
          this.errorHandlerService.UpdateAndDisplayError('Error while loading gpto data please try reloading page or contact IT');
        }
      }
    })
  }

  async OnViewChange() {
    console.log('changing data');
    console.log(this.showICP);
    this.gridApi.deselectAll();
    if (this.selectedViewOption.name == 'GAAP') {
      if (this.showICP == true) {
        if (this.selectedViewOption.name == 'GAAP') {
          if (this.gptoLoadDataService.loadingGaapIcpData == true) {
            this.gridApi.showLoadingOverlay();
            for (var i = 0; i < 30; i++) {
              await new Promise(r => setTimeout(r, 1000));
              if (!this.gptoLoadDataService.loadingGaapIcpData) {
                this.gridApi.hideOverlay();
                break;
              }
            }
          }
          this.gridData = this.gptoLoadDataService.gaapIcpData;
        }
      }
      else {
        if (this.gptoLoadDataService.loadingGaap == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingGaap) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.gaapData;
      }
    }
    else {
      if (this.showICP == true) {
        if (this.gptoLoadDataService.loadingEconIcpData == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingEconIcpData) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.econIcpData;
      }
      else {
        if (this.gptoLoadDataService.loadingEcon == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingEcon) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.econData;
      }
    }
    this.gridApi.setRowData(this.gridData);
    this.GetPaginationValues();
    this.updateTotals();
  }

  updateTotals() {
    let numMtdVariance = 0, numYtdVariance = 0;

    this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
      const data = rowNode.data;
      if (data.mtdVariance) numMtdVariance += Number(data.mtdVariance);
      if (data.ytdVariance) numYtdVariance += Number(data.ytdVariance);
    });
    this.numMtdVariance = (Math.round(numMtdVariance * 100) / 100).toLocaleString();
    this.numYtdVariance = (Math.round(numYtdVariance * 100) / 100).toLocaleString();
  }

  async RecordUpdates(event: CellValueChangedEvent) {
    if(!event.colDef.editable && event.colDef.field != 're_curr'){ return; }
    this.updateOptionsAvailable = true;
    event.node.setDataValue('Edited', true);
    this.gptoChangeHandlerService.RecordUpdates(event);
  }

  SaveUpdates() {
    this.gridDataSaveNotes = this.gptoChangeHandlerService.currentChanges;
    this.showSaveNotesConfirmWindow = true;
  }

  async SaveNotes() {
    this.updateOptionsAvailable = false;
    this.showSaveNotesConfirmWindow = false;
    this.gridApi.showLoadingOverlay();
    await this.gptoChangeHandlerService.SaveUpdates();
    await this.RetrieveData();
  }

  CloseGrid() {
    this.showSaveNotesConfirmWindow = false;
  }

  AccountingPeriodChange() {
    this.RetrieveData();
  }

  OnGridReadySaveNotes(params: GridReadyEvent) {
    this.gridApiSaveNotes = params.api;
  }

  async handleInputICPSwitchChange(event: any) {
    if (event.checked) {
      this.columnApi.setColumnVisible('intercompanyPartner', true);

      this.gridApi.deselectAll();
      if (this.selectedViewOption.name == 'GAAP') {
        if (this.gptoLoadDataService.loadingGaapIcpData == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingGaapIcpData) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.gaapIcpData;
      }
      else {
        if (this.gptoLoadDataService.loadingEconIcpData == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingEconIcpData) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.econIcpData;
      }
      this.gridApi.setRowData(this.gridData);
      this.GetPaginationValues();
      this.updateTotals();
    }
    else {
      this.columnApi.setColumnVisible('intercompanyPartner', false);

      this.gridApi.deselectAll();
      if (this.selectedViewOption.name == 'GAAP') {
        if (this.gptoLoadDataService.loadingGaap == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingGaap) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.gaapData;
      }
      else {
        if (this.gptoLoadDataService.loadingEcon == true) {
          this.gridApi.showLoadingOverlay();
          for (var i = 0; i < 30; i++) {
            await new Promise(r => setTimeout(r, 1000));
            if (!this.gptoLoadDataService.loadingEcon) {
              this.gridApi.hideOverlay();
              break;
            }
          }
        }
        this.gridData = this.gptoLoadDataService.econData;
      }
      this.gridApi.setRowData(this.gridData);
      this.GetPaginationValues();
      this.updateTotals();
    }
  }

  CancelNotes() {
    this.confirmationService.confirm({
      message: `Are you sure you would like to clear all pending changes?`,
      accept: async () => {
        this.RetrieveData();
      }
    })
  }
}
