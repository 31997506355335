//angular
import { Component, Input, OnInit, ViewChild } from '@angular/core';

//services
import { FileManagementService } from 'src/app/services/file-management/file-management.service';
import { EtlLoggingService } from 'src/app/services/etl-logging/etl-logging.service';
import { ActiveProcessesService } from 'src/app/services/active-processes/active-processes.service';
import { FileUploadTableHandlerService } from 'src/app/services/file-upload-table-handler/file-upload-table-handler.service';

//AGGrid
import { ColDef, GridReadyEvent } from 'ag-grid-community';

//interfaces
import { FileUploaderValidationIssues } from 'src/app/interfaces/file-uploader-validation-issues';
import { ProcessStatusService } from 'src/app/services/process-status/process-status.service';
import { ErrorHandlerService } from 'src/app/services/error-handler/error-handler.service';
import { ActivatedRoute } from '@angular/router';
import { FileUploadHelper } from './file-uploader-helper';
import { StorageHandlerService } from 'src/app/services/storage-handler/storage-handler.service';

//primeng
import { FileUpload } from 'primeng/fileupload';
import { MessageService } from 'primeng/api';
import { ProcessStatusRow } from 'src/app/interfaces/process-status-row';
import { CurrentFileUploadStatus } from 'src/app/interfaces/file-uploader-process-status';
import { Helper } from 'src/app/helper';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [MessageService]
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('form') fileSelector!: FileUpload;
  showRefreshIcon: boolean = false;
  waitingOnPipeline: boolean = false;

  showActiveProcessesBlockingMessage: boolean = false;
  showActiveProcessesTimeoutMessage: boolean = false;
  showCheckingActiveProcessesMessage: boolean = false;
  showActiveProcessesBlockingTimeoutMessage: boolean = false;
  showFileUploadBar: boolean = false;

  fileUploading: boolean = false;

  displayValidationIssues: boolean = false;
  validationIssuesRows: string[] = [];
  ValidationIssuesColDef: ColDef[];

  validReponse: any;
  validFile: boolean = false;

  fileSuccessfullyUploaded: boolean = false;

  parentDataType!: string;
  dataType: string = '';
  title: string = '';
  templateFileName: string = '';
  templateFileLocation: string = '';
  fileLimit: number = 1;
  activelyRunningString: string = '';
  subProcessName: string = 'Manual File Upload';
  processStep: string = 'Load to FDA';
  processName: string = ""
  fileName: string = '';

  defaultColDef = {
    resizable: true,
  };

  filesUploaded: string = '';
  fileTypeConfig: any;
  filesValidationResult!: { key: string, values: FileUploaderValidationIssues[] }[];
  ud3List: Array<string> = Array(31).fill(1).map((v, i) => (i < 9 ? 'D0' : 'D') + (i + 1));
  dpsr_validate = { YEAR: null, PERIOD: null, UD3_DAYS: null };
  fileUploadStatus!: CurrentFileUploadStatus[];

  fileValidator!: Function

  constructor(
    private messageService: MessageService,
    private storageHandlerService: StorageHandlerService,
    private errorHandlerService: ErrorHandlerService,
    private fileManagementService: FileManagementService,
    private processStatusService: ProcessStatusService,
    public etlLoggingService: EtlLoggingService,
    public activeProcessesService: ActiveProcessesService,
    private router: ActivatedRoute,
    private fileUploadTableHandlerService: FileUploadTableHandlerService) {

    this.ValidationIssuesColDef = [
      { field: 'Row' },
      { field: 'ColumnName' },
      { field: 'ColumnValue' },
      { field: 'ExpectedDataType' },
      { field: 'ErrorDescription' },
    ]
  }


  ngOnInit(): void {
    this.router.paramMap.subscribe(params => {
      const dataType = params.get('datatype');
      if (dataType != null) {
        this.fileUploadTableHandlerService.GetTableInformation(dataType).then(response => { this.fileTypeConfig = response; });
        this.fileUploading = false;
        this.displayValidationIssues = false;
        this.showFileUploadBar = ['bank-balance', 'dpsr'].filter(i => i == dataType).length > 0 ? false : true;
        this.dataType = dataType;
        this.SetValuesByDataType(dataType);
        this.fileUploadStatus = [];
        if (this.fileSelector) { this.fileSelector.clear(); }
      }
    })
  }

  SetValuesByDataType(dataType: string) {
    if (dataType == 'bank-balance') {
      this.title = 'Spreadsheet Upload for Bank Balance File';
      this.templateFileLocation = '/assets/kyriba_bankbalance_template.csv';
      this.templateFileName = 'kyriba_bankbalance_template.csv'
      this.fileLimit = 1;
      this.activelyRunningString = 'kyrbiaBankBalance';
      this.fileName = 'Bank Balance Manual';
      this.fileManagementService.process = 'Bank File Load to FDA to Data Resevoir';
      this.fileManagementService.fileName = 'blackline';
      this.CheckActivelyRunningProcesses();
    }
  }

  setDpsrValidationValue(elem: any, field: string) {
    setTimeout(() => {
      this.dpsr_validate[field] = elem['inputFieldValue'];
      this.showFileUploadBar = this.dataType == 'dpsr' && this.dpsr_validate.YEAR != null && this.dpsr_validate.PERIOD != null && this.dpsr_validate.UD3_DAYS != null;
    }, 50);
  }

  async UploadCsvToS3Bucket(event: any, form: any) {
    this.fileSuccessfullyUploaded = false;
    this.displayValidationIssues = false;
    this.fileUploading = true;
    this.filesValidationResult = [];

    let showValidationErrors = false;
    for (var i = 0; i < event.files.length; i++) {
      try {
        let currently_in_process = this.fileUploadStatus.find(s => s.fileName == event.files[i].name);
        if (currently_in_process) {
          if (currently_in_process.inProcess == true) { continue; }
          else if (currently_in_process.inProcess == false && currently_in_process.inSnowflake == false) {
            this.fileUploadStatus.splice(this.fileUploadStatus.indexOf(currently_in_process), 1);
          }
          else if (this.filesValidationResult.filter(v => v.key == currently_in_process?.fileName).length > 0) {
            this.filesValidationResult.splice(this.filesValidationResult.indexOf(this.filesValidationResult.filter(v => v.key == currently_in_process?.fileName)[0]), 1);
          }
        }


        let uploadStatus = new CurrentFileUploadStatus();
        uploadStatus.fileName = event.files[i].name;
        uploadStatus.inProcess = true;
        uploadStatus.inSnowflake = false;
        uploadStatus.statusMessage = "Validating file";
        uploadStatus.ProcessValueChangedCallback = () => {
          if (this.fileUploadStatus.every(s => s.inSnowflake)) { this.messageService.add({ severity: 'success', summary: 'Success', detail: 'All files have been successfully uploaded to snowflake' }); }
        };
        this.fileUploadStatus.push(uploadStatus)

        const validationResult = await FileUploadHelper.validateFileUsingConfigSchema(event.files[i], this.fileTypeConfig, this.dataType == 'dpsr' ? this.dpsr_validate : null, this.fileValidator);
        if (validationResult.length > 0) {
          uploadStatus.inProcess = false;
          uploadStatus.statusMessage = "File validation failed";
          this.filesValidationResult.push({ key: event.files[i].name, values: validationResult });
          showValidationErrors = true;
        }
        else {
          uploadStatus.statusMessage = "Passed validation, loading file to snowflake";
          const fileKey = FileUploadHelper.sanitizeS3FileKey(`${this.fileTypeConfig['s3_file_path'] ?? ''}` + `${this.fileTypeConfig['s3_file_path'] ? '/' : ''}` + `${this.fileTypeConfig['file_load_pk']}/` + `${event.files[i].name}_${FileUploadHelper.getDateTimeString(new Date(), '_', '_')}.csv`.trim())
          let logData = await this.processStatusService.LogFileUpLoadAndReturnLogData(this.processStep, 'In Process', true, this.fileTypeConfig['process'], event.files[i].name, this.fileTypeConfig['sub_process']);
          uploadStatus.processId = logData?.processId ?? '';

          try {
            await this.storageHandlerService.uploadFileToS3Bucket(this.fileTypeConfig['s3_bucket_name'], fileKey, event.files[i], { processId: logData?.processId, processor: logData?.processor });
            uploadStatus.timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
          }
          catch (e) {
            await this.processStatusService.LogFileUpLoad(this.processStep, 'Failed', false, this.fileTypeConfig['process'], this.fileTypeConfig['sub_process'], event.files[i].name, 'Error uploading file to s3');
            throw `An error occurred uploading file [${event.files[i].name}] to s3.\nPlease try file upload again \n${e}`;
          }
          this.etlLoggingService.pollForFillUploadCompleteInSnowflake(logData as ProcessStatusRow, uploadStatus);
        }
      }
      catch (e) {
        console.log(e);
        this.messageService.add({ severity: 'error', summary: '', detail: <string>e })
        this.fileUploading = false;
        setTimeout(() => { this.messageService.clear(); }, 10000)
        return;
      }
    }
    form.clear();
    this.displayValidationIssues = showValidationErrors;

    if (this.displayValidationIssues && this.fileUploadStatus.length == 0) {
      this.fileUploading = false;
      return;
    }

    this.fileUploading = true;
  }

  async UploadFile(event: any, form: any) {
    this.filesUploaded = event.files.map((f: File) => f.name).join('\n');
    if (this.dataType == 'bank-balance') { return await this.UploadToDatalake(event, form); }
    else { return await this.UploadCsvToS3Bucket(event, form); }
  }

  getCsvTemplate() {
    const blob = new Blob([this.fileTypeConfig['csv_template']], { type: "text/plain" });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = `${this.dataType}_template.csv`;
    a.click();
    a.parentNode?.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  async UploadToDatalake(event: any, form: any) {
    this.fileSuccessfullyUploaded = false;
    this.displayValidationIssues = false;
    this.filesValidationResult = [];
    await this.CheckActivelyRunningProcesses();
    this.fileUploading = true;
    this.displayValidationIssues = false;
    await this.processStatusService.LogFileUpLoad('File Validation', 'In Process', true, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);

    for (var i = 0; i < event.files.length; i++) {
      var validation = await this.ValidateFile(event.files[i]);
      if (validation == false) {
        form.clear();
        return;
      }
    }
    await this.processStatusService.LogFileUpLoad('File Validation', 'Success', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
    await this.processStatusService.LogFileUpLoad('Uploading File To Datalake', 'In Process', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
    if (await this.fileManagementService.UploadFileDataToDatalake() == true) {
      await this.processStatusService.LogFileUpLoad('Uploading File To Datalake', 'Success', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
      this.waitingOnPipeline = true;
      await this.processStatusService.LogFileUpLoad('Processing Through Data Pipeline', 'In Process', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
      this.etlLoggingService.PushMessageToLoggingTable(
        'Bank File Load to FDA to Data Resevoir',
        'WaitForSnowflakeResult',
        'Waiting for Snowflake progress',
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      let res = await this.etlLoggingService.WaitForEtlLogRow('SP_LOAD_KYRIBA_BANKBALANCE');
      if (res == true) {
        this.fileUploading = false;
        this.fileSuccessfullyUploaded = true;
        await this.processStatusService.LogFileUpLoad('Processing Through Data Pipeline', 'Success', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
      }
      else {
        console.log('Issue while moving file to snowflake');
        await this.processStatusService.LogFileUpLoad('Processing Through Data Pipeline', 'Failed', false, this.dataType.toUpperCase(), this.subProcessName, this.dataType.toUpperCase(), this.fileName);
      }
      this.waitingOnPipeline = false;
      this.fileUploading = false;
      form.clear();
    }
    else {
      console.error('Error while uploading file');
    }
  }

  async ValidateFile(file: File) {
    let result = await this.fileManagementService.ValidateAndReturnResultsOfFile(file);

    if (result == null) {
      await this.processStatusService.LogFileUpLoad('File Validation', 'Failed', false, this.dataType.toUpperCase(), this.subProcessName, this.fileName, 'Issue with Lambda');
      this.errorHandlerService.UpdateAndDisplayError(`Error while validating file, Please retry, if the problem persists contact IT.`);
      this.showComponent(false, false, this.showFileUploadBar, false);
      this.fileUploading = false;
      return false;
    }
    else if (result.length > 0) {
      console.log('Verificiation issues');
      this.fileUploading = false;
      this.filesValidationResult.push({ key: file.name, values: result });
      this.displayValidationIssues = true;
      await this.processStatusService.LogFileUpLoad('File Validation', 'Success', false, this.dataType.toUpperCase(), this.subProcessName, this.fileName, 'Invalid File');
      return false;
    }
    else {
      return true;
    }
  }

  ClearValidationIssues() {
    this.displayValidationIssues = false;
    this.filesValidationResult = [];
    this.filesUploaded = '';
  }

  async CheckActivelyRunningProcesses() {
    try {
      //Show blocking message
      this.showComponent(false, true, this.showFileUploadBar, false);
      const initialResponse = await this.activeProcessesService.CheckActiveProcesses(this.activelyRunningString);

      if (initialResponse == null) {
        console.error('Error while checking actively running');
      }
      else if (initialResponse == true) {
        //Show checking on actively running
        this.showComponent(true, false, false, false);
        const response = await this.activeProcessesService.WaitForActiveProcessCleared(this.activelyRunningString, 5000, 60);
        if (response == true) {
          //Show file upload bar
          this.showComponent(false, false, true, false);
        }
        else {
          //Show timeout
          this.showComponent(false, false, this.showFileUploadBar, true);
        }
      }
      else {
        //Show file upload bar
        this.showComponent(false, false, true, false);
      }
    }
    catch (e) {
      console.error(`error while checking processes - ${e}`);
    }
  }

  showComponent(showActiveProcessesBlockingMessage: boolean, showCheckingActiveProcessesMessage: boolean, showFileUploadBar: boolean, showActiveProcessesBlockingTimeoutMessage: boolean) {
    this.showActiveProcessesBlockingMessage = showActiveProcessesBlockingMessage;
    this.showCheckingActiveProcessesMessage = showCheckingActiveProcessesMessage;
    this.showActiveProcessesBlockingTimeoutMessage = showActiveProcessesBlockingTimeoutMessage;
    this.showFileUploadBar = showFileUploadBar;
  }

  onValidationIssuesGridReady(params: GridReadyEvent) {
    params.columnApi.autoSizeAllColumns();
  }

  removeFile(file: File, form: FileUpload) {
    const index = form.files.indexOf(file);
    form.remove(new Event('click'), index);
  }

  setStormFileConfig(config: any) {
    this.parentDataType = config['parent_data_type'];
    this.dataType = config['file_load_pk']
    this.fileTypeConfig = config;
    if (config['match_files_by'].toUpperCase() == "NONE") { this.showFileUploadBar = true; }
    else {
      this.showFileUploadBar = false;
    }
  }

  validateFileForType(validator: Function) {
    if(!validator){ return; }
    let message = validator();
    this.showFileUploadBar = Helper.hasValue(message) ? false : true;
  }

  additionalFileValidator(validator: Function)
  {
    this.fileValidator = validator;
  }

}


