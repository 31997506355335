import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';

import { PowerBiDatasetService } from '../../services/power-bi-dataset/power-bi-dataset.service';

@Component({
  selector: 'app-power-bi-refresh',
  templateUrl: './power-bi-refresh.component.html',
  styleUrls: ['./power-bi-refresh.component.scss']
})
export class PowerBiRefreshComponent implements OnInit {

  buttonDisabled = false;
  showRefreshSymbol = false;

  displayCompleteDialog = false;
  displayCurrentDate: boolean = false;

  constructor(
    public powerBiDatasetService: PowerBiDatasetService,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit(): void {
  }

  async refreshPowerBiDataset() {
    try {
      this.SetLoading(true);
      const response = await this.powerBiDatasetService.ExecuteRefresh();
      console.log(this.powerBiDatasetService.current_month);
      const statusCode = response['statusCode']
      if (statusCode == 200 || statusCode == 409) {
        this.displayCurrentDate = true;
        if (statusCode == 409) {
          this.errorHandlerService.UpdateAndDisplayError('Power BI Refresh currently running. Process will notify here when the current run is complete. Please wait until after this notification to try and kick off another refresh.');
        }
        const response2 = await this.powerBiDatasetService.CheckAndWaitForRunningPowerBIRefresh();
        if (response2 == null) {
          this.errorHandlerService.UpdateAndDisplayError('Error while trying to refresh powerbi dataset. Please contact IT.');
        }
        else if (response2 == false) {
          this.errorHandlerService.UpdateAndDisplayError('Error while trying to refresh powerbi dataset. Please contact IT.');
        }
        else {
          this.ShowDialog();
        }
      }
      else {
        this.errorHandlerService.UpdateAndDisplayError('Error while trying to refresh powerbi dataset. Please retry or contact IT.');
      }
      this.displayCurrentDate = false;
      this.SetLoading(false);
    }
    catch(ex) {
      this.errorHandlerService.UpdateAndDisplayError('Error while trying to refresh powerbi dataset. Please contact IT.');
    }
  }

  SetLoading(isLoading: boolean) {
    this.buttonDisabled = isLoading;
    this.showRefreshSymbol = isLoading;
  }

  ShowDialog() {
    this.displayCompleteDialog = true;
  }

}
